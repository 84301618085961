const { colors } = require('styles/cp');

module.exports.popoverStyles = {
  popoverBorderWidth: '1px',
  popoverArrowWidth: '1rem',
  popoverArrowHeight: '.5rem',
  popoverHeaderBg: `rgba(255, 255, 255, 0.3)`,
  borderRadiusLg: '.3rem',
  popoverArrowOuterColor: colors.gray,
  popoverArrowColor: colors.gray,
  whitePopoverArrowOuterColor: 'rgba(0, 0, 0, 0.2)',
  whitePopoverArrowColor: colors.white,
  menuItemDividerBorderColor: colors.gray06,
  popoverUserMenuBorderColor: colors.gray05,
};
