import React from 'react';
import { FormattedMessage } from 'react-intl';

const NanopayTooltipContent = ({ open }: { open: Function }) => {
  return (
    <div className="ca-bank-tooltip-footer-container">
      <div className="header">
        <div className="title">
          <FormattedMessage
            id="CA_BANK_FOOTER_TOOLTIP_TITLE"
            defaultMessage="Nanopay is a trusted partner of QuickBooks"
          />
        </div>
        <div className="close-button" onClick={() => open(false)}>
          X
        </div>
      </div>

      <div className="line" />

      <div className="info">
        <FormattedMessage
          id="CA_BANK_FOOTER_TOOLTIP_INFO"
          defaultMessage="We partnered with them to provide you with a secure way to send payments directly from your bank. "
        />
        <a
          href="https://community.intuit.com/oicms/L8HPPVSzT_CA_en_CA"
          rel="noopener noreferrer"
          target="_blank"
          className="link-text"
        >
          <FormattedMessage id="CA_BANK_FOOTER_TOOLTIP_INFO_LINK" defaultMessage=" Learn more. " />
        </a>
      </div>

      <style jsx>{`
        .ca-bank-tooltip-footer-container {
          display: flex;
          flex-direction: column;
          color: #fff;
          background: #393a3d;

          padding: 16px;
          width: 350px;
          height: 140px;

          .header {
            display: flex;

            .title {
              max-width: 328px;
              font-family: AvenirNext forINTUIT;
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              color: #ffffff;
            }

            .close-button {
              max-width: 56px;
              color: #8d9096;
              text-align: center;
              cursor: pointer;
              user-select: none;
            }
          }

          .line {
            width: 100px;
            margin: 10px 0;
            border: 3px solid #0097e6;
            border-radius: 5px;
          }

          .info {
            font-family: AvenirNext forINTUIT;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;

            .link-text {
              text-decoration: none;
              color: #fff;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default NanopayTooltipContent;
