import classNames from 'classnames';
import React, { Fragment } from 'react';

import { colors } from 'styles/cp';

export type CheckboxChangeEventHandler = React.EventHandler<
  React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement, MouseEvent>
>;

interface IconProps {
  color?: string;
}

export interface Props {
  checked: boolean;
  onChange: CheckboxChangeEventHandler;
  type?: 'default' | 'primary';
  disabled: boolean;
  name?: string;
  id?: string;
  labelId?: string;
  error?: boolean;
  icon?: React.FC<IconProps>;
  withIcon?: boolean;
  iconSelectedColor: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const {
    checked = false,
    disabled = false,
    id,
    name = 'default',
    onChange,
    type = 'default',
    error,
    labelId,
    icon: Icon = CheckIcon,
    withIcon = false,
    iconSelectedColor,
  } = props;

  let propsToPass = {
    checked,
    disabled,
    id: '',
    name: '',
  };
  if (id) {
    propsToPass.id = id;
  }
  if (name) {
    propsToPass.name = name;
  }

  const iconColor = type === 'default' ? colors.green : colors.white;

  const prefixCls = 'cpCheckbox';

  const rootClassName = classNames(prefixCls, {
    [`${prefixCls}--disabled`]: disabled,
    [`${prefixCls}--checked`]: checked,
    [`${prefixCls}--checked--${type}`]: checked && type,
    [`${prefixCls}--with--icon`]: withIcon,
  });

  const onInputChange: CheckboxChangeEventHandler = (event): void => {
    if (!disabled) {
      if (event) {
        event.preventDefault();
      }
      onChange(event);
    }
  };

  const getIcon = () => {
    if (withIcon && checked) {
      return <Icon color={iconSelectedColor} />;
    }

    if (withIcon && !checked) {
      return <Icon />;
    }

    if (!withIcon && checked) {
      return <Icon color={iconColor} />;
    }

    return null;
  };

  return (
    <Fragment>
      <div
        /* https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/checkbox_role */
        role="checkbox"
        aria-checked={String(Boolean(checked)) as 'true' | 'false'}
        className={rootClassName}
        aria-labelledby={labelId}
        onClick={onInputChange}
      >
        {getIcon()}
      </div>
      <input
        className="cpCheckbox-input"
        type="checkbox"
        onChange={onInputChange}
        {...propsToPass}
      />

      <style jsx>{`
        .cpCheckbox {
          height: 20px;
          width: 20px;
          border: 1px solid ${error ? colors.error : colors.gray03};
          border-radius: 2px;
          box-sizing: border-box;
          text-align: center;
          vertical-align: center;
          line-height: 20px;
          cursor: pointer;
          background-color: ${error ? 'rgba(213, 43, 30, 0.1)' : ''};

          &:hover:enabled {
            border: 1px solid ${colors.green};
          }

          &:focus:enabled {
            border: solid 3px ${colors.intuit_blue};
          }
        }

        .cpCheckbox--checked--primary {
          border: none;
          background-color: ${colors.green};
        }

        .cpCheckbox--disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }

        .cpCheckbox--checked {
          border: 1px solid ${colors.green};
        }

        .cpCheckbox--with--icon {
          border: 0;
        }

        .cpCheckbox-input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 1px;
          width: 1px;
          border: 0;
          margin: -1px;
          overflow: hidden;
          padding: 0;
        }
      `}</style>
    </Fragment>
  );
};

export default Checkbox;

const CheckIcon = ({ width = 12, height = 10, color = colors.green }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="A.Open-invoices---mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Open-invoices---mobile_popup"
        transform="translate(-72.000000, -538.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="Group-21" transform="translate(67.000000, 218.000000)">
          <g id="Group-19">
            <g id="Group-26" transform="translate(1.000000, 304.000000)">
              <g id="Checkbox-with-focus" transform="translate(0.000000, 11.000000)">
                <g id="Rectangle-1-Copy">
                  <path
                    d="M8.93333333,14.4497609 C8.74133333,14.6500797 8.548,14.6500797 8.356,14.4497609 L8.01066667,14.0880742 L4,10.2361107 L5.616,8.37064194 L8.55866667,11.2293581 L14.2693333,5 L16,6.74583394 L8.93333333,14.4497609 Z"
                    id="check-copy"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
