const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'store/invoiceDocument/helpers';
const reportError = (e) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'error', logger },
    event: 'pdf',
    action: 'download',
    activityInfo: {
      status: 'error',
    },
    error: {
      message: e?.message,
      stack: e?.stack,
    },
  });
};
export const downloadPDF = ({ fileName, blobUrl }) => {
  try {
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.target = '_blank';
    anchor.style.display = 'none';
    anchor.href = blobUrl;
    document.body.appendChild(anchor);

    anchor.onclick = function () {
      try {
        requestAnimationFrame(() => {
          setTimeout(() => {
            try {
              return anchor.remove();
            } catch (e) {
              reportError(e);
            }
          }, 300);
        });
      } catch (e) {
        reportError(e);
      }
    };

    anchor.click();
  } catch (e) {
    reportError(e);
  }
};
export const formatStatus = ({ amountPaid, gratuityValue, balanceAmount, intl }) => {
  const { formatMessage } = intl;

  if (amountPaid > 0 && balanceAmount === 0) {
    if (gratuityValue && gratuityValue > 0) {
      return formatMessage({ id: 'INVOICE_SUMMARY_OVER_PAID', defaultValue: 'Over paid' });
    } else {
      return formatMessage({ id: 'PAYNOW_PAYMENT_FULL', defaultValue: 'Paid' });
    }
  } else if (balanceAmount > 0 && amountPaid > 0) {
    return formatMessage({ id: 'INVOICE_SUMMARY_PARTIALLY_PAID', defaultValue: 'Partially paid' });
  }
};

export const getReciptMTLLabels = (intl) => {
  const { formatMessage } = intl;

  return {
    noAdditionalFeesLabel: formatMessage({
      id: 'NO_ADDITIONAL_FEES',
      defaultValue: 'No additional transfer fees or taxes apply.',
    }),
    footerDisclaimer: formatMessage({
      id: 'PDF_RECEIPT_MTL_FOOTER',
      defaultValue:
        "Intuit Payments Inc (IPI) processes payments as an agent of the business. Payments processed by IPI constitutes payment to the business and satisfies your obligation to pay the business, including in connection with any dispute or case, in law or equity. Money movement services are provided by IPI pursuant to IPI's licenses (NMLS #1098819, https://www.intuit.com/legal/licenses/payment-licenses). IPI is located at 2700 Coast Avenue, Mountain View, CA 94043, 1-888-536-4801.",
    }),
  };
};
