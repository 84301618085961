import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import BankTransferCAFlinks from 'components/Core/Nanopay/BankTransfer/BankTransferCAFlinks';
import BankTransferCAFooter from 'components/Core/Nanopay/BankTransfer/BankTransferCAFooter';
import BankTransferCAHeader from 'components/Core/Nanopay/BankTransfer/BankTransferCAHeader';
import { modalActions } from 'store/modal/slice';
import { colors } from 'styles/cp';

const BankTransferCAGuest = () => {
  const {
    nanopay: { hasFlinksFlowCompleted },
    featureFlags,
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const useLegacyNanopayPaymentFlow = featureFlags['use-legacy-nanopay-payment-flow'];

  useEffect(() => {
    if (hasFlinksFlowCompleted) dispatch(modalActions.hide());
  }, [hasFlinksFlowCompleted]);

  return (
    <div className="ca-bank-guest-container">
      <div className="ca-bank-guest-container-box">
        {useLegacyNanopayPaymentFlow ? (
          <BankTransferCAHeader
            title={<FormattedMessage id="CA_BANK_HEADER_TITLE1" defaultMessage="Connect" />}
          />
        ) : null}
        <div className="flink-container">
          <BankTransferCAFlinks />
        </div>
        {useLegacyNanopayPaymentFlow ? <BankTransferCAFooter /> : null}
      </div>

      <style jsx>{`
        .ca-bank-guest-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));
          margin: 0 -15px;

          .ca-bank-guest-container-box {
            display: flex;
            flex-direction: column;

            width: 100vw;
            height: calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));
            background-color: ${colors.gray07};
            text-align: center;
            border: 1px solid #d4d7dc;
            box-sizing: border-box;
            box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.2);

            .flink-container {
              background-color: #fff;
              height: 100%;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default BankTransferCAGuest;
