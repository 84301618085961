import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const FaceSmile: React.FC<IconBasePropsWithColor> = ({
  width = 41,
  height = 40,
  color = colors.gray,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 36C29.3366 36 36.5 28.8366 36.5 20C36.5 11.1634 29.3366 4 20.5 4C11.6634 4 4.5 11.1634 4.5 20C4.5 28.8366 11.6634 36 20.5 36ZM40.5 20C40.5 31.0457 31.5457 40 20.5 40C9.4543 40 0.5 31.0457 0.5 20C0.5 8.9543 9.4543 0 20.5 0C31.5457 0 40.5 8.9543 40.5 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7442 24.7043C13.4598 24.2869 14.3782 24.5286 14.7957 25.2442C15.3266 26.1544 16.9977 27.5 20.5 27.5C24.0023 27.5 25.6734 26.1544 26.2043 25.2442C26.6217 24.5286 27.5402 24.2869 28.2558 24.7043C28.9714 25.1218 29.2131 26.0402 28.7957 26.7558C27.5766 28.8456 24.7327 30.5 20.5 30.5C16.2673 30.5 13.4234 28.8456 12.2043 26.7558C11.7869 26.0402 12.0286 25.1218 12.7442 24.7043Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 13.5C14.8807 13.5 16 14.6193 16 16V16.2C16 17.5807 14.8807 18.7 13.5 18.7C12.1193 18.7 11 17.5807 11 16.2V16C11 14.6193 12.1193 13.5 13.5 13.5ZM27.5 13.5C28.8807 13.5 30 14.6193 30 16V16.2C30 17.5807 28.8807 18.7 27.5 18.7C26.1193 18.7 25 17.5807 25 16.2V16C25 14.6193 26.1193 13.5 27.5 13.5Z"
        fill={color}
      />
    </svg>
  );
};

export default FaceSmile;
