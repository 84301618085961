const { version } = require('./package.json');

let currentGIT_TAG =
  (process.env && process.env['GIT_TAG']) ||
  (process.env && process.env['NEXT_PUBLIC_GIT_TAG']) ||
  Date.now();

module.exports = () => {
  return `${version}-${currentGIT_TAG}`;
};
