import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalActions } from 'store/modal/slice';

class ModalWrapper extends Component {
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.isOpen) {
      document && document.body.classList.add('modal-open');
      document && document.addEventListener('keydown', this.escFunction, false);
    } else {
      document && document.body.classList.remove('modal-open');
      document && document.removeEventListener('keydown', this.escFunction, false);
    }
  }

  escFunction = (event) => {
    const { closeOnEsc } = this.props.componentProps;
    if (closeOnEsc && event.keyCode === 27) {
      this.props.hide();
    }
  };

  render() {
    const { Component, isOpen, componentProps, background = 'rgba(0, 0, 0, 0.8)' } = this.props;
    const closeOnClickOutside = componentProps && componentProps.closeOnClickOutSide;
    const showCloseButton = componentProps && componentProps.showCloseButton;

    if (!isOpen || !Component) {
      return null;
    }

    return (
      <Fragment>
        {/*language=SCSS*/}
        <style jsx>{`
          .m-o {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: fixed;
            z-index: 1001;
            background: ${background};
            padding: 15px;
            overflow: scroll;
          }

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;

            .x {
              font-size: 18px;
              color: white;
              position: absolute;
              top: 15px;
              right: 15px;
              transition: all 0.2s ease;
              padding: 7px 12px;
              z-index: 1099;
              border: transparent solid 2px;

              &:hover {
                color: lightgrey;
              }
              &:focus {
                border: lightgrey solid 2px;
              }
            }
          }
        `}</style>

        <div
          className="m-o"
          onClick={closeOnClickOutside ? this.props.hide : undefined}
          onKeyDown={this.escFunction}
          role="button"
        >
          <Component {...componentProps} hide={this.props.hide} />
        </div>

        {showCloseButton && (
          <button type="button" onClick={this.props.hide}>
            <span className="x" tabIndex="0">
              X
            </span>
          </button>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.modal };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ hide: modalActions.hide }, dispatch);
}

ModalWrapper.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func.isRequired]),
  isOpen: PropTypes.bool,
  componentProps: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
