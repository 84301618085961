const debug = require('debug')('ssr:helpers:HttpClient');
// @ts-ignore
const axiosFactory = require('axios').default;
const axiosRetry = require('axios-retry').default;
const https = require('https');
const {
  isNetworkOrIdempotentRequestError,
  requestInterceptor,
  responseRUMInterceptor,
  responseNetworkErrorInterceptor,
} = require('./httpClientUtils');
/**
 * @typedef {import('server/middleware/httpInterceptors').Interceptor} Interceptor
 */
class HttpClient {
  constructor() {
    const axios = axiosFactory.create();
    const Agent = new https.Agent({
      rejectUnauthorized: false,
    });
    axios.defaults.httpsAgent = Agent;
    axios.defaults.withCredentials = true;
    axios.defaults.timeout = 10000;

    axios.interceptors.request.use(requestInterceptor);
    axios.interceptors.response.use(responseRUMInterceptor, responseNetworkErrorInterceptor);

    /**
     * @param {Interceptor} interceptor
     */
    axios['registerResponseErrorInterceptor'] = (interceptor) => {
      axios.interceptors.response.use(null, interceptor);
    };

    /**
     * @param {Interceptor} interceptor
     */
    axios['registerResponseInterceptor'] = (interceptor) => {
      axios.interceptors.response.use(interceptor);
    };

    /**
     * @param {Interceptor} interceptor
     */
    axios['registerRequestInterceptor'] = (interceptor) => {
      axios.interceptors.request.use(interceptor);
    };

    /**
     * @param {number} timeout
     */
    axios['setReqTimeout'] = (timeout) => {
      axios.defaults.timeout = timeout;
    };

    /**
     * axios plugin that intercepts failed requests
     * and retries them whenever possible.
     */
    axios['setAxiosRetry'] = () => {
      // to make sure axiosRetry is called only once on axios instance
      if (this.retryInterceptorId !== undefined) return;
      const { responseInterceptorId } = axiosRetry(axios, {
        retries: 1,
        shouldResetTimeout: true,
        retryCondition: isNetworkOrIdempotentRequestError,
      });
      this.retryInterceptorId = responseInterceptorId;
    };

    this._axios = axios;

    debug('HttpClient created');
  }

  static getInstance() {
    if (!this._instance) {
      this._instance = new HttpClient();
    }
    return this._instance._axios;
  }
}

module.exports = HttpClient;
