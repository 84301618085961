import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const FatQuestionMark: React.FC<IconBasePropsWithColor> = ({
  width = 20,
  height = 20,
  color = colors.gray04,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-451.000000, -473.000000)">
          <g id="24px" transform="translate(273.000000, 149.000000)">
            <g id="24" transform="translate(0.000000, 234.000000)">
              <g
                id="Icons-/-24-/-Default-/-ic_help+faq_outline"
                transform="translate(176.000000, 88.000000)"
              >
                <path
                  d="M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,13.0001831 L12,12 C12.3093262,11.7015991 14.0652466,11.5165405 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10.1376953,8.94665527 10.0669556,9.53112793"
                  id="icon_stroke"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <circle id="Oval" fill={color} fillRule="nonzero" cx="12" cy="16" r="1"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FatQuestionMark;
