import React, { Fragment } from 'react';

import Modal from 'components/Core/Modal/Modal';
import { FeedbackForm } from 'components/Shared/Feedback/FeedbackForm/FeedbackForm';
import { Close } from 'components/Shared/Icons/Icons';
import { breakpoints, colors } from 'styles/cp';

export interface Props {
  onHide: () => void;
}
export const FeedbackModal: React.FC<Props> = ({ onHide }) => {
  return (
    <Fragment>
      <Modal>
        <div className="feedback-popup-container" data-testid="feedback-popup-container">
          <button className="close-btn" onClick={onHide} aria-label="Close Feedback">
            <Close width={12} height={12} />
          </button>
          <div className="form">
            <FeedbackForm onSubmit={onHide} />
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .close-btn {
          position: absolute;
          right: 10px;
          cursor: pointer;
          background-color: transparent;
          border: none;
          padding: 12px;
        }

        .feedback-popup-container {
          background-color: ${colors.white};
          position: relative;
          min-width: 400px;
          height: auto;
          min-height: 220px;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          border: 1px solid ${colors.gray05};
          z-index: 9999;
          width: 30%;
          padding: 10px;

          @media screen and (max-width: ${breakpoints.md}) {
            width: 90%;
            min-width: 90%;
            height: 80vh;
          }
        }

        .form {
          padding: 10px 30px;
        }
      `}</style>
    </Fragment>
  );
};

export default FeedbackModal;
