import debugCreator from 'debug';
import React, { Component } from 'react';

import { Success, Close } from 'components/Shared/Icons/Icons';
import { colors, breakpoints } from 'styles/cp';

interface ToasterProps {
  hide: Function;
  status?: string;
  text: string;
}
interface ToasterState {
  shouldClose: boolean;
  isClosed: boolean;
}

const debug = debugCreator('Toastr');

export default class Toastr extends Component<ToasterProps, ToasterState> {
  private timerId?: ReturnType<typeof setTimeout>;
  static TOASTR_TTL = 4000;

  constructor(props: ToasterProps) {
    super(props);

    debug('constructor');

    this.state = {
      shouldClose: true,
      isClosed: true,
    };
  }

  shouldComponentUpdate(nextProps: ToasterProps, nextState: ToasterState) {
    if (nextState !== this.state) {
      return true;
    }

    return this.props.text !== nextProps.text || this.props.status !== nextProps.status;
  }

  componentDidMount() {
    this.setState(
      {
        isClosed: false,
      },
      this.restartTimer
    );
  }

  componentDidUpdate(prevProps: ToasterProps) {
    if (this.props.text !== prevProps.text) {
      this.setState(
        {
          shouldClose: false,
          isClosed: false,
        },
        this.restartTimer
      );
    }
  }

  restartTimer = () => {
    if (!this.state.isClosed) {
      this.timerId = setTimeout(() => {
        this.setState(
          {
            shouldClose: true,
          },
          this.triggerHide
        );
      }, Toastr.TOASTR_TTL);
    }
  };

  stopTimer = () => {
    this.setState(
      {
        shouldClose: false,
      },
      this.clearTimer
    );
  };

  clearTimer = () => (this.timerId ? clearTimeout(this.timerId) : null);

  triggerHide = () => {
    const { shouldClose } = this.state;

    if (shouldClose) {
      this.clearTimer();
      this.setState(
        {
          isClosed: true,
        },
        () => {
          setTimeout(() => {
            this.props.hide();
          }, 500);
        }
      );
    }
  };

  onMouseEnter = () => {
    this.stopTimer();
  };

  onMouseLeave = () => {
    this.restartTimer();
  };

  onCloseToastr = () => {
    this.setState({ shouldClose: true }, this.triggerHide);
  };

  render() {
    const { status = 'success', text } = this.props;
    const icon = status === 'success' ? <Success /> : null;

    return (
      <div className={`toast-wrapper ${this.state.isClosed ? 'closed' : 'open'}`}>
        <div className="toast" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <div className="toast-content">
            {icon && <div className="toast-icon">{icon}</div>}
            <span className="toast-text" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <span className="toast-close" onClick={this.onCloseToastr}>
            <Close />
          </span>
        </div>
        <span className={`progress-bar`} />

        <style jsx global>{`
          .toast-wrapper {
            position: fixed;
            transform: translateZ(0) translate(-50%, 0);
            left: 50%;

            &.open {
              top: 0;
              visibility: visible;
              animation: fadein 0.5s;
            }

            &.closed {
              top: -70px;
              animation: fadeout 0.5s;
            }

            @media screen and (max-width: ${breakpoints.md}) {
              transform: translate(0);
              right: 0;
              left: 0;
            }

            @keyframes fadein {
              from {
                top: -70px;
                opacity: 0;
              }
              to {
                top: 0px;
                opacity: 1;
              }
            }

            @keyframes fadeout {
              from {
                top: 0px;
                opacity: 1;
              }
              to {
                top: -70px;
                opacity: 1;
              }
            }

            .toast {
              min-height: 64px;
              background-color: ${colors.white};
              opacity: 1;
              display: flex;
              justify-content: space-between;

              @media screen and (max-width: ${breakpoints.md}) {
                min-height: 32px;
              }

              .toast-content {
                display: flex;
                justify-content: start;

                .toast-icon {
                  margin: auto 0 auto 16px;
                  display: inherit;

                  svg {
                    width: 34px;
                    height: 34px;
                  }

                  @media screen and (max-width: ${breakpoints.md}) {
                  }
                }

                .toast-text {
                  margin: auto 0 auto 16px;
                  font-family: AvenirNextforINTUIT-Regular;
                  color: ${colors.darkGray};

                  @media screen and (max-width: ${breakpoints.md}) {
                  }
                }
              }

              .toast-close {
                margin: auto 0;
                padding: 16px;
                cursor: pointer;
                color: ${colors.ghostGray};

                @media screen and (max-width: ${breakpoints.md}) {
                  padding: 20px;
                }
              }
            }

            .progress-bar {
              display: block;
              margin-bottom: 3px;
              height: 1px;
              width: 100%;
              background-color: ${colors.green04};
            }
          }
        `}</style>
      </div>
    );
  }
}
