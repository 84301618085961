import { PayloadAction } from '@reduxjs/toolkit';

import { sliceFactory } from 'store/utils';
import { Toast } from 'types/Toast';

const initialState: Partial<Toast> = {
  toasts: [],
  currentToast: null,
};

const computeNewToast = (state: Partial<Toast>) => {
  if (state.currentToast === null && state.toasts && state.toasts.length > 0) {
    state.currentToast = state.toasts.shift();
  }
  return state;
};

const { reducer, actions } = sliceFactory({
  name: 'toast',
  initialState,
  reducers: {
    show(state, action: PayloadAction<any>) {
      const { payload } = action;
      if (payload) {
        state.toasts = [...(state.toasts || []), { ...payload }];
        return computeNewToast(state);
      }
      return state;
    },
    hide(state) {
      state.currentToast = null;
      return computeNewToast(state);
    },
  },
});

export const toastReducer = reducer;
export const toastActions = actions;
