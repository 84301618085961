import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import QBOLogo from 'components/Core/Header/GlobalHeader/QBOLogo/QBOLogo';
import UserBar from 'components/Core/Header/UserBar/UserBar';
import { signIn, signOut } from 'store/auth/slice';
import { modalActions } from 'store/modal/slice';
import { toastActions } from 'store/toast/slice';
import { colors, breakpoints, paddingSize } from 'styles/cp';
import { Auth } from 'types/Auth';
import { Config } from 'types/Config';
import { Modal } from 'types/Modal';

export type GlobalHeaderProps = {
  config: Pick<Config, 'cpVersion' | 'dockerTag' | 'env' | 'endpoints'>;
  auth: Pick<Auth, 'isUserSignedIn' | 'username'>;
  ixp?: object;
  hideUserBar?: boolean;
  showModal: (
    payload: Pick<Modal, 'component' | 'componentProps'>,
    state?: Partial<Modal> | undefined
  ) => void;
  showToast: ({ text }: { text: string }) => void;
  signOut: () => void;
  signIn: () => void;
};

export const GlobalHeader: React.FC<GlobalHeaderProps> = (props) => {
  const {
    hideUserBar,
    config: { cpVersion, dockerTag, env },
  } = props;
  const shouldShowCPVersion = env && !env.includes('prod');

  return (
    <Fragment>
      <style jsx>{`
        .h {
          color: ${colors.black};
          height: 60px;
          margin: 0 auto;
          padding: ${paddingSize.xs} ${paddingSize.lg};
          justify-content: space-between;
          align-items: center;

          h1.logo {
            margin-bottom: 10px;
          }

          @media (max-width: ${breakpoints.md}) {
            padding: 15px 10px;
          }
        }
        .version {
          color: ${colors.gray02};

          @media (max-width: ${breakpoints.md}) {
            display: none;
          }
        }
      `}</style>

      <header className="w">
        <div className="h flex flex-any">
          <h1 className="logo" aria-label="QBO Logo">
            <QBOLogo />
          </h1>
          {shouldShowCPVersion && <span className="version">CP Version: {cpVersion}</span>}
          {shouldShowCPVersion && dockerTag && (
            <span className="docker-tag">Docker: {dockerTag}</span>
          )}
          {!hideUserBar && (
            <UserBar
              config={props.config}
              showModal={props.showModal}
              showToast={props.showToast}
              auth={props.auth}
              signOut={props.signOut}
              signIn={props.signIn}
            />
          )}
        </div>
      </header>
    </Fragment>
  );
};
function mapStateToProps({ config, auth, ixp }: GlobalHeaderProps) {
  return {
    config,
    auth,
    ixp,
  };
}
export default connect(mapStateToProps, {
  signOut,
  showModal: modalActions.show,
  showToast: toastActions.show,
  signIn,
})(GlobalHeader);
