import classNames from 'classnames';
import React from 'react';

import { colors, fontSize, buttonHeight, buttonMinWidth } from 'styles/cp';

export type ButtonProps = {
  buttonType?: 'default' | 'dark' | 'primary' | 'invert' | 'paypal' | 'applepay' | 'tertiary';
  className?: string;
  disabled?: boolean;
  overlayClassName?: string;
  size?: 'standard' | 'mini' | 'medium' | 'jumbo' | 'responsive';
  children?: React.ReactNode | string | null;
  width?: string;
  height?: string;
  padding?: string;
  contentColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  'aria-label'?: string;
  id?: string;
  // eslint-disable-next-line no-unused-vars
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  style?: object;
};

const Button: React.FC<ButtonProps> = ({
  buttonType = 'default',
  className = '',
  children,
  disabled = false,
  overlayClassName = '',
  size = 'standard',
  padding = '0 18px',
  width,
  height,
  contentColor,
  backgroundColor,
  borderColor,
  ...restProps
}) => {
  const prefixCls = 'cpButton';
  const rootClassName = classNames(prefixCls, className, {
    [`${prefixCls}--${size}`]: size,
    [`${prefixCls}--disabled`]: disabled,
    [`${prefixCls}--${buttonType}`]: buttonType,
    [`${prefixCls}--custom-width`]: !!width,
    [`${prefixCls}--custom-height`]: !!height,
    [`${prefixCls}--custom-content-color`]: !!contentColor,
    [`${prefixCls}--custom-background-color`]: !!backgroundColor,
    [`${prefixCls}--custom-border-color`]: !!borderColor,
  });

  return (
    <button {...restProps} className={rootClassName} disabled={disabled}>
      <span className={classNames({ [overlayClassName]: !!overlayClassName })}>{children}</span>

      <style jsx>{`
        .cpButton {
          position: relative;
          padding: ${padding};
          margin: 0;
          cursor: pointer;

          white-space: nowrap;
          font-family: AvenirNextforINTUIT-Medium;
        }

        .cpButton:focus,
        cpButton:active {
          outline: none;
          box-shadow: 0px 0px 0px 1px white, 0px 0px 0px 4px ${colors.intuit_blue};
        }

        .cpButton::-moz-focus-inner {
          border: 0;
        }

        .cpButton--disabled {
          cursor: default;
          pointer-events: none;
        }

        .cpButton--mini {
          height: ${buttonHeight.mini};
          min-width: ${buttonMinWidth.mini};
          border-radius: 4px;
          font-size: ${fontSize.xxs};
        }
        .cpButton--medium {
          height: ${buttonHeight.medium};
          min-width: ${buttonMinWidth.medium};
          border-radius: 4px;
          font-size: ${fontSize.xs};
        }
        .cpButton--standard {
          height: ${buttonHeight.standard};
          min-width: ${buttonMinWidth.standard};
          border-radius: 4px;
          font-size: ${fontSize.sm};
        }
        .cpButton--responsive {
          height: ${buttonHeight.responsive};
          min-width: ${buttonMinWidth.standard};
          border-radius: 4px;
          font-size: ${fontSize.sm};
        }
        .cpButton--jumbo {
          height: ${buttonHeight.jumbo};
          min-width: ${buttonMinWidth.jumbo};
          border-radius: 4px;
          font-size: 19px;
          line-height: 24px;
          justify-content: center;
          align-content: center;
        }
        .cpButton--primary {
          border: none;
          background-color: ${colors.green03};
          color: ${colors.white};
          &:hover {
            background-color: ${colors.green01};
          }
          &:focus:enabled {
            border: solid 2px ${colors.gray05};
          }
          &:disabled {
            background-color: ${colors.green03};
            opacity: 0.4;
          }
        }

        .cpButton--paypal {
          border: none;
          background-color: ${colors.yellow01};
          color: ${colors.white};
          &:hover {
            background-color: ${colors.yellow02};
          }
          &:focus:enabled {
            border: solid 2px ${colors.gray05};
          }

          &:disabled {
            opacity: 0.4;
          }
        }

        .cpButton--applepay {
          border: none;
          background-color: ${colors.white};
          border: 1px solid ${colors.gray02};
          color: ${colors.white};
          &:hover {
            background-color: ${colors.offWhite};
          }
          &:focus:enabled {
            background-color: ${colors.ghostGray};
            border: solid 2px ${colors.gray05};
          }
          &:disabled {
            background-color: ${colors.lightGray};
          }
          :global(svg) {
            vertical-align: middle;
          }
        }

        .cpButton--dark {
          border: none;
          background-color: ${colors.gray01};
          color: ${colors.white};
          &:hover {
            background-color: ${colors.lightGray};
          }
          &:focus:enabled {
            background-color: ${colors.ghostGray};
            border: solid 2px ${colors.gray05};
          }
          &:disabled {
            opacity: 0.4;
          }
        }

        .cpButton--invert {
          color: ${colors.white};
          background-color: transparent;
          border: solid 2px ${colors.white};
          &:hover {
            background-color: ${colors.lightGray};
          }
          &:focus:enabled {
            background-color: ${colors.ghostGray};
          }
        }

        .cpButton--default {
          color: ${colors.gray};
          border: solid 2px ${colors.gray02};
          background: none;
          &:hover {
            background-color: ${colors.gray10};
          }
          &:focus:active:enabled {
            background-color: ${colors.gray12};
            box-shadow: none;
          }
          &:focus:enabled {
            background: none;
            border: solid 2px ${colors.gray02};
            box-shadow: 0px 0px 0px 1px white, 0px 0px 0px 4px ${colors.intuit_blue};
          }
          &:disabled {
            opacity: 0.4;
          }
        }

        .cpButton--custom-width {
          width: ${width};
        }

        .cpButton--custom-height {
          height: ${height};
        }

        .cpButton--tertiary {
          color: ${colors.intuit_blue};
          background-color: transparent;
          border: none;
          &:hover {
            color: ${colors.blue04};
            background-color: ${colors.gray11};
          }
          &:focus:active:enabled {
            color: ${colors.blue04};
            background-color: ${colors.gray12};
          }
          &:focus:enabled {
            background: none;
            border: solid 3px ${colors.intuit_blue};
            color: ${colors.gray};
          }
          &:disabled {
            opacity: 0.4;
          }
        }

        .cpButton--custom-content-color {
          color: ${contentColor};
          &:hover {
            color: ${contentColor};
          }
          &:focus:enabled {
            color: ${contentColor};
          }
          &:focus:active:enabled {
            color: ${contentColor};
          }
        }

        .cpButton--custom-background-color {
          background-color: ${backgroundColor};
          &:hover {
            background-color: ${colors.gray11};
          }
          &:focus:enabled {
            background-color: ${colors.gray12};
          }
        }

        .cpButton--custom-border-color {
          border: ${borderColor} solid;
          &:hover {
            border: ${borderColor};
          }
          &:focus:enabled {
            border: ${borderColor};
          }
        }
      `}</style>
    </button>
  );
};

export default Button;
