import {
  ScsWalletType,
  ScsPaymentType,
  isCreditCardPaymentMethod,
  isBankAccountPaymentMethod,
} from 'shared/PaymentHelpers';
import { saleSelectors } from 'store/sale/selectors';
import { isWalletTypeOfBank, isWalletTypeOfCreditCard } from 'businessLogic/Wallet/helpers';
import { getSourceClient } from 'store/utils';

const getSchedulePaymentPayload = ({ state, bankDetails, ccDetails }) => {
  const {
    sale: {
      type,
      freight,
      lines,
      discount,
      id,
      status,
      receivable: { dueDate },
      saleTerm,
      scheduleInfo = {},
      isGpu,
    },
    companyInfo: { companyName, language, region },
    insight: { offeringId },
    auth: { entityId, username },
    wallet: { selectedWalletId, userWallets },
    payment: { paymentMethodType, scheduledDate, isSavePaymentMethodChecked },
  } = state;

  const { autoPayEligible, schedulePayEligible } = scheduleInfo;
  let paymentInfo,
    postalCode = '',
    customerName;
  const timeStamp = new Date().getTime();
  if (isCreditCardPaymentMethod(paymentMethodType) && ccDetails && isSavePaymentMethodChecked) {
    const { name, address } = ccDetails;

    postalCode = address.postalCode;
    customerName = name;
    paymentInfo = getPaymentInfo('card', state, bankDetails, ccDetails);
  } else if (
    isBankAccountPaymentMethod(paymentMethodType) &&
    bankDetails &&
    isSavePaymentMethodChecked
  ) {
    const { name } = bankDetails;

    customerName = name;
    paymentInfo = getPaymentInfo('bank', state, bankDetails, ccDetails);
  } else {
    const wallet = getSelectedWallet(userWallets, selectedWalletId);
    customerName = getCustomerNameFromWallet(wallet, username);
    postalCode = wallet.postalCode || '';
    paymentInfo = getPaymentInfo('wallet', state, bankDetails, ccDetails);
  }

  let templateId = null;
  if (autoPayEligible && state.sale.recurringInfo && state.sale.recurringInfo.recurringId) {
    templateId = state.sale.recurringInfo.recurringId;
  }

  const payload = {
    companyName,
    saleDueDate: dueDate,
    recurringInfo: {
      templateId,
    },
    saleL3Info: {
      id,
      status,
      type,
      freightAmount: freight,
      discountAmount: discount,
      postalCode,
      lines,
    },
    scheduleInfo: {
      startDate: autoPayEligible ? timeStamp : new Date(scheduledDate).getTime(),
      endDate: autoPayEligible ? timeStamp : new Date(scheduledDate).getTime(),
      cancelDate: '',
      frequency: 'ONCE',
      type: getScheduleType(schedulePayEligible, autoPayEligible),
      term: getScsTermValue(saleTerm),
    },
    paymentInfo,
    consent: {
      consentDate: timeStamp,
    },
    customerInfo: {
      customerId: entityId,
      customerEmail: username,
      customerName,
      customerExternalId: '1',
    },
    locale: language + '_' + region,
    offeringId,
    sourceClient: getSourceClient(offeringId),
    zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    isGPU: isGpu,
  };

  return payload;
};

export const getScheduleType = (schedulePayEligible, autoPayEligible) => {
  if (schedulePayEligible) {
    return ScsScheduleType.SCHEDULE_PAY;
  } else if (autoPayEligible) {
    return ScsScheduleType.AUTO_PAY;
  }
  return null;
};

export const getMaskedPaymentNumber = (wallet) => {
  let type = '',
    account = '';
  if (isWalletTypeOfBank(wallet.walletType)) {
    type = wallet.accountType;
    type = type && type[0].toUpperCase() + type.slice(1);
    account = wallet.accountNumber;
  } else if (isWalletTypeOfCreditCard(wallet.walletType)) {
    type = wallet.cardType.toUpperCase();
    account = wallet.cardNumber;
  }
  if (account.length > 6) {
    account = account.slice(-7);
  }
  return `${type} ${account}`;
};

export const getCustomerNameFromWallet = (wallet, username) => {
  if (isWalletTypeOfBank(wallet.walletType)) {
    return `${wallet.payorFirstName} ${wallet.payorLastName}`.trim();
  } else if (isWalletTypeOfCreditCard(wallet.walletType)) {
    return wallet.payorFirstName;
  } else {
    return username;
  }
};

export const getSelectedWallet = (list = [], id) => {
  if (list.length === 0 || !id) {
    return null;
  }

  return list.find((wallet) => wallet.id === id);
};

export const getWalletInfo = (wallet, entityId) => {
  return {
    id: entityId,
    token: wallet.id,
    type: getScsWalletType(wallet.walletType),
  };
};

export const PAYMENT_REJECT_TYPES = {
  MISSING_CONSENT: 'MISSING_CONSENT',
};

const ScsScheduleType = {
  SCHEDULE_PAY: 'SCHEDULE_PAY',
  AUTO_PAY: 'AUTO_PAY',
};

const ScsTermType = {
  DUE_ON_RECEIPT: 'DUE_ON_RECEIPT',
  NET_15: 'NET_15',
  NET_30: 'NET_30',
  NET_60: 'NET_60',
  OTHER: 'OTHER',
};

export const getScsPaymentType = (walletType) => {
  if (isWalletTypeOfBank(walletType)) {
    return ScsPaymentType.BANK_ACCOUNT_WALLET;
  } else if (isWalletTypeOfCreditCard(walletType)) {
    return ScsPaymentType.CREDIT_CARD_WALLET;
  } else {
    return walletType;
  }
};

export const getScsTermValue = (term) => {
  if (!term) {
    return ScsTermType.OTHER;
  }
  switch (term.name) {
    case 'Due on receipt':
      return ScsTermType.DUE_ON_RECEIPT;
    case 'Net 15':
      return ScsTermType.NET_15;
    case 'Net 30':
      return ScsTermType.NET_30;
    case 'Net 60':
      return ScsTermType.NET_60;
    default:
      return ScsTermType.OTHER;
  }
};

export const getScsWalletType = (walletType) => {
  if (isWalletTypeOfBank(walletType)) {
    return ScsWalletType.BANK;
  } else if (isWalletTypeOfCreditCard(walletType)) {
    return ScsWalletType.CARD;
  } else {
    return walletType;
  }
};

export const isAmexAccountEnabled = (selectedWalletId, userWallets, enabledCCTypes) => {
  const card = userWallets && userWallets.find((cc) => cc.id === selectedWalletId);
  if (card && card.cardType === 'amex' && enabledCCTypes.indexOf('amex') === -1) {
    return false;
  }
  return true;
};

export const schedulePaymentHelpers = {
  getSchedulePaymentPayload,
};

const getPaymentInfo = (paymentType, state, bankDetails, ccDetails) => {
  const {
    sale: {
      currencyInfo: { currency },
    },
    auth: { entityId },
    wallet: { userWallets, selectedWalletId },
    payment: { amount, isSavePaymentMethodChecked },
  } = state;

  let paymentInfo;
  if (paymentType === 'bank') {
    const { phone: phoneNumber, name, accountNumber, bankCode, accountType } = bankDetails;

    paymentInfo = {
      paymentMethod: 'bank',
      paymentCurrency: currency,
      amount,
      accountType,
      phoneNumber,
      accountNumber,
      bankCode,
      name,
      isSavePaymentMethodChecked,
    };
  } else if (paymentType === 'card') {
    const { token } = ccDetails;

    paymentInfo = {
      paymentMethod: ScsPaymentType.CREDIT_CARD_WALLET,
      paymentCurrency: currency,
      creditCardToken: token,
      amount,
      isSavePaymentMethodChecked,
    };
  } else if (paymentType === 'wallet') {
    const wallet = getSelectedWallet(userWallets, selectedWalletId);
    const walletInfo = getWalletInfo(wallet, entityId);
    paymentInfo = {
      paymentMethod: getScsPaymentType(wallet.walletType),
      amount,
      paymentCurrency: currency,
      paymentMaskedCard: getMaskedPaymentNumber(wallet),
      walletInfo,
    };
  }

  return paymentInfo;
};

const getSubscriptionPaymentPayload = ({ state, bankDetails, ccDetails }) => {
  const {
    auth: { entityId },
    payment: { paymentMethodType, isSavePaymentMethodChecked },
  } = state;

  let paymentInfo;
  if (isCreditCardPaymentMethod(paymentMethodType) && ccDetails && isSavePaymentMethodChecked) {
    paymentInfo = getPaymentInfo('card', state, bankDetails, ccDetails);
  } else if (
    isBankAccountPaymentMethod(paymentMethodType) &&
    bankDetails &&
    isSavePaymentMethodChecked
  ) {
    paymentInfo = getPaymentInfo('bank', state, bankDetails, ccDetails);
  } else {
    paymentInfo = getPaymentInfo('wallet', state, bankDetails, ccDetails);
  }

  const { scheduleId, consentId } = saleSelectors.subscriptionInfo(state.sale);

  return {
    customerInfo: {
      customerId: entityId,
    },
    paymentInfo,
    subscriptionInfo: {
      scheduleId,
      consentId,
    },
  };
};

export const subscriptionPaymentHelpers = {
  getSubscriptionPaymentPayload,
};

export const getMonthlyPaymentReturn = (amount, apr, months) => {
  const monthlyRate = apr / 1200;
  const numerator = amount * monthlyRate;
  const denominator = 1 - Math.pow(1 + monthlyRate, -months);
  const monthlyPayment = numerator / denominator;
  return monthlyPayment.toFixed(0);
};

export const isPaymentError = ({ prevPayment, currentPayment }) => {
  return (
    prevPayment?.payment?.paymentStatus !== 'error' &&
    currentPayment?.payment?.paymentStatus === 'error'
  );
};
