import * as React from 'react';

import { colors } from 'styles/cp';

export type Props = {
  borderRadius?: string;
  children?: React.ReactNode | string | null;
  redesign?: boolean;
  isMobileUser?: boolean;
  margin?: string;
  padding?: string;
};

const Card: React.FC<Props> = ({
  isMobileUser,
  redesign,
  borderRadius,
  margin = '',
  padding = '',
  children,
}) => {
  let height = '';
  if (redesign) {
    isMobileUser ? '400px' : '425px';
  }

  return (
    <React.Fragment>
      <style jsx>{`
        .card-wrapper {
          background-color: ${colors.white};
          border-radius: ${borderRadius ? borderRadius : '16px'};
          width: 100%;
          height: ${height};
          position: ${redesign ? 'relative' : 'unset'};
          z-index: ${redesign ? '2' : 'unset'};
          margin: ${margin};
          padding: ${padding};
        }
      `}</style>

      <div className="card-wrapper" data-testid="test-card-component">
        {children}
      </div>
    </React.Fragment>
  );
};

export default Card;
