import { PayloadAction } from '@reduxjs/toolkit';

import { getDefaultWalletFromList, getUserWalletByEntryId } from './helpers';

import { isWalletTypeOfBank, isWalletTypeOfCreditCard } from 'businessLogic/Wallet/helpers';
import SegmentIO from 'reporting/SegmentIO';
import { insightSelectors } from 'store/insight/selectors';
import { modalActions } from 'store/modal/slice';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { UserWallet, UserWalletBank, UserWalletCard, UserWallets, Wallet } from 'types/Wallet';
import { TXN_MAP, WALLET_MAP } from 'types/constants';

const WalletFetchStatus = WALLET_MAP.FETCH_STATUS;

export const REMOTE_BUTTON_ACCESS_FUNCTIONALITY = {
  BANK_CREATE_PAYMENT: 'bankCreatePayment',
  BANK_CREATE_PAYMENT_TRIGGER: 'bankCreatePaymentTrigger',
  CARD_CREATE_PAYMENT: 'cardCreatePayment',
  CARD_CREATE_PAYMENT_TRIGGER: 'cardCreatePaymentTrigger',
} as const;

const initialState: Partial<Wallet> = {
  isDetectingApplePayStatus: false,
  isDetectingPayPalStatus: false,
  selectedCardCvv: '',
};

const { reducer, actions } = sliceFactory({
  name: 'wallet',
  initialState,
  reducers: {
    fetchWallets(state) {
      state.fetchWalletStatus = WalletFetchStatus.FETCHING;
    },
    fetchWalletSuccess(
      state,
      action: PayloadAction<{ walletAccountsList: UserWallets; token: string; ssrtid: string }>
    ) {
      const { walletAccountsList } = action.payload;
      if (!walletAccountsList) {
        return {
          ...state,
          fetchWalletStatus: WalletFetchStatus.FETCH_SUCCESS,
        };
      }

      let selectedWalletId;
      const defaultWallet = getDefaultWalletFromList(walletAccountsList);

      if (defaultWallet) {
        SegmentIO.transactionEngaged({
          activity_type: 'wallet',
          ui_action: 'enabled',
          ui_object: 'button',
          ui_object_detail: 'saved_wallet_selected',
          ui_access_point: 'transaction_flow',
        });
      }
      selectedWalletId = defaultWallet ? defaultWallet.id : null;

      return {
        ...state,
        fetchWalletStatus: WalletFetchStatus.FETCH_SUCCESS,
        userWallets: walletAccountsList,
        selectedWalletId,
      };
    },
    fetchWalletError(state, action: PayloadAction<Partial<{ error: Wallet['walletError'] }>>) {
      state.fetchWalletStatus = WalletFetchStatus.FETCH_FAILED;
      state.walletError = action.payload && action.payload.error;
    },
    changeSelectedWallet(state, action: PayloadAction<{ id: Wallet['selectedWalletId'] }>) {
      const {
        payload: { id },
      } = action;

      let hasChanged = false,
        newSelectedWalletId = state.selectedWalletId;
      if (id !== state.selectedWalletId) {
        hasChanged = true;
        newSelectedWalletId = id;
      }

      if (hasChanged) {
        state.selectedWalletId = newSelectedWalletId;
        state.selectedCardCvv = '';
      }
    },
    updateWallet(state) {
      state.updatedWallet = null;
      state.updateWalletStatus = TXN_MAP.STATUS.IN_PROGRESS;
    },
    updateWalletSuccess(state, action: PayloadAction<{ updatedWallet: UserWallet }>) {
      const updatedWallet = action.payload && action.payload.updatedWallet;
      if (isWalletTypeOfBank(updatedWallet.walletType)) {
        // @ts-ignore
        updatedWallet.accountNumber = state.userWallets.find(
          (wallet) => wallet.id === updatedWallet.id
          // @ts-ignore
        ).accountNumber;
      }

      const updatedWalletList =
        state.userWallets &&
        (state.userWallets.map((wallet) => {
          if (wallet.id === updatedWallet.id) {
            return updatedWallet;
          }
          // If the updated wallet is now the default payment method,
          // then others aren't.
          if (updatedWallet.default) {
            return {
              ...wallet,
              default: false,
            };
          }
          return wallet;
        }) as UserWallets);

      state.currentlyUpdatingWalletId = null;
      state.updateWalletStatus = TXN_MAP.STATUS.SUCCESS;
      state.updatedWallet = updatedWallet;

      if (isWalletTypeOfBank(updatedWallet.walletType)) {
        state.selectedWalletId = updatedWallet.id;
        state.userWallets = updatedWalletList;
      } else {
        state.selectedWalletId = updatedWallet.id;
        state.userWallets = updatedWalletList;
      }
    },
    updateWalletError(state, action: PayloadAction<{ error: Wallet['walletError'] }>) {
      state.updateWalletStatus = TXN_MAP.STATUS.ERROR;
      state.walletError = action.payload && action.payload.error;
      state.updatedWallet = null;
    },
    updateWalletCancel(state) {
      state.currentlyUpdatingWalletId = null;
    },
    deleteWallet(state) {
      let { userWallets, currentlyDeletingWalletId } = state as Required<Wallet>;

      if (typeof currentlyDeletingWalletId === 'string') {
        let pendingDeleteWallet, indexOfWalletToDelete;

        indexOfWalletToDelete = userWallets.findIndex((w) => w.id === currentlyDeletingWalletId);
        if (indexOfWalletToDelete > -1) {
          pendingDeleteWallet = userWallets[indexOfWalletToDelete];
          userWallets = userWallets.filter((w) => w.id !== currentlyDeletingWalletId);

          // Remove the item from the list and make sure that we have a selected payment method.
          // If the deleted wallet was our selected wallet or default, we need to choose selected wallets again.
          const defaultWallet = getDefaultWalletFromList(userWallets);
          const defaultWalletId = defaultWallet === null ? null : defaultWallet.id;

          return {
            ...state,
            deleteWalletStatus: TXN_MAP.STATUS.IN_PROGRESS,
            userWallets: [...userWallets],
            selectedWalletId: defaultWalletId,
            pendingDeleteWallet: pendingDeleteWallet,
            indexOfWalletToDelete: indexOfWalletToDelete,
          };
        }
      }

      state.currentlyDeletingWalletId = null;
      return state;
    },
    deleteWalletSuccess(state) {
      state.deleteWalletStatus = TXN_MAP.STATUS.SUCCESS;
    },
    deleteWalletError(state, action) {
      const updatedState: Wallet = {
        ...state,
        deleteWalletStatus: TXN_MAP.STATUS.ERROR,
        // @ts-ignore
        walletError: {
          error: action.payload && action.payload.error,
          walletIdToRemove: state.currentlyDeletingWalletId,
          walletToRemove: state.pendingDeleteWallet,
        },
      };

      if (state.pendingDeleteWallet) {
        const { walletType } = state.pendingDeleteWallet;
        const indexToInsert =
          state.indexOfWalletToDelete && state.indexOfWalletToDelete > -1
            ? state.indexOfWalletToDelete
            : null;

        state.userWallets = state.userWallets || [];
        if (isWalletTypeOfBank(walletType)) {
          updatedState.userWallets = [...state.userWallets];
          if (indexToInsert === null) {
            updatedState.userWallets.push(state.pendingDeleteWallet as UserWalletBank);
          } else {
            updatedState.userWallets.splice(
              indexToInsert,
              0,
              state.pendingDeleteWallet as UserWalletBank
            );
          }
        } else if (isWalletTypeOfCreditCard(walletType)) {
          updatedState.userWallets = [...state.userWallets];
          if (indexToInsert === null) {
            updatedState.userWallets.push(state.pendingDeleteWallet as UserWalletCard);
          } else {
            updatedState.userWallets.splice(
              indexToInsert,
              0,
              state.pendingDeleteWallet as UserWalletCard
            );
          }
        }
      }

      return updatedState;
    },
    deleteWalletCancel(state) {
      state.currentlyDeletingWalletId = null;
      state.deleteWalletStatus = null;
    },
    bindFormSubmission(state, action: PayloadAction<{ fn: any; functionality: any }>) {
      const { fn, functionality } = action.payload;

      let fnKey;
      switch (functionality) {
        case REMOTE_BUTTON_ACCESS_FUNCTIONALITY.BANK_CREATE_PAYMENT:
          fnKey = REMOTE_BUTTON_ACCESS_FUNCTIONALITY.BANK_CREATE_PAYMENT_TRIGGER;
          break;

        case REMOTE_BUTTON_ACCESS_FUNCTIONALITY.CARD_CREATE_PAYMENT:
          fnKey = REMOTE_BUTTON_ACCESS_FUNCTIONALITY.CARD_CREATE_PAYMENT_TRIGGER;
          break;

        default:
          throw new Error('Invalid form submission functionality');
      }

      // @ts-ignore
      if (state[fnKey] === fn) {
        return state;
      }

      return {
        ...state,
        [fnKey]: fn,
      };
    },
    onDeletePaymentMethodClicked(
      state,
      action: PayloadAction<{ id: Wallet['currentlyDeletingWalletId'] }>
    ) {
      state.currentlyDeletingWalletId = action.payload.id;
      state.updateWalletStatus = null;
    },
    onUpdatePaymentMethodClicked(
      state,
      action: PayloadAction<{ id: Wallet['currentlyUpdatingWalletId'] }>
    ) {
      state.currentlyUpdatingWalletId = action.payload.id;
      state.updateWalletStatus = null;
    },
    addApplePayEnabledPaymentMethod(state) {
      state.enabledPaymentMethods = [...(state.enabledPaymentMethods || []), 'ap'];
    },
    updateIsPPAAMInstallmentsEnabled(state) {
      state.isPPAAMInstallmentsEnabled = true;
    },
    changeEnabledPaymentMethods(
      state,
      action: PayloadAction<{ allowedPaymentMethods: Wallet['enabledPaymentMethods'] }>
    ) {
      state.enabledPaymentMethods = action.payload.allowedPaymentMethods;
    },
    mergeCCAndDCIfMoreThen6PMs(state) {
      if (state.enabledPaymentMethods && state.enabledPaymentMethods.length > 6) {
        if (
          state.enabledPaymentMethods.includes('dc') &&
          state.enabledPaymentMethods.includes('cc')
        ) {
          state.enabledPaymentMethods = state.enabledPaymentMethods.filter(
            (paymentMethod) => !['cc', 'dc'].includes(paymentMethod)
          );
          state.enabledPaymentMethods.unshift('dc,cc');
        }
      }
    },
    detectApplePayEnabledStatusFinished(state) {
      state.isDetectingApplePayStatus = false;
    },
    detectPayPalEnabledStatusFinished(state) {
      state.isDetectingPayPalStatus = false;
    },
    onCvvInputValueChange(state, action: PayloadAction<Wallet['selectedCardCvv']>) {
      state.selectedCardCvv = action.payload;
    },
    setIsNewWalletUI(state, action: PayloadAction<boolean>) {
      state.isNewWalletUI = action.payload;
    },
    shouldShowPaypalAndVenmo(
      state,
      action: PayloadAction<
        | {
            enabledPaymentMethods: Wallet['enabledPaymentMethods'];
          }
        | undefined
      >
    ) {
      const { enabledPaymentMethods } = (action && action.payload) || {};
      state.enabledPaymentMethods = enabledPaymentMethods || state.enabledPaymentMethods;
    },
  },
});

export const walletReducer = reducer;
export const walletActions = actions;

export const fetchWallets = thunkActionFactory(async ({ dispatch, state, businessLogic }) => {
  dispatch(walletActions.fetchWallets());

  const {
    insight,
    config: { ssrtid },
    wallet: { enabledPaymentMethods },
  } = state;

  const token = insightSelectors.tokenSelector(insight);

  let payload: any = {
    token,
    ssrtid,
  };
  let walletAccountsList: UserWallets;
  try {
    walletAccountsList = await businessLogic.wallet.fetchUserWallets(state);

    if (!enabledPaymentMethods.includes('cc' || 'dc')) {
      walletAccountsList = walletAccountsList.filter(
        (wallet) => !isWalletTypeOfCreditCard(wallet.walletType)
      );
    }
    if (!(enabledPaymentMethods.includes('bank') || enabledPaymentMethods.includes('eft'))) {
      walletAccountsList = walletAccountsList.filter(
        (wallet) => !isWalletTypeOfBank(wallet.walletType)
      );
    }

    payload = {
      ...payload,
      walletAccountsList,
    };

    dispatch(walletActions.fetchWalletSuccess(payload));
  } catch (error: any) {
    dispatch(
      walletActions.fetchWalletError({
        error,
      })
    );
  }
});

export const changeSelectedWallet = thunkActionFactory<Wallet['selectedWalletId']>(
  ({ payload, dispatch, state }) => {
    // When do we not let selected bank to be changed?
    // 1. If there is a payment in progress.
    const {
      payment: { paymentStatus },
    } = state;
    if (!(paymentStatus === TXN_MAP.STATUS.IN_PROGRESS)) {
      dispatch(walletActions.changeSelectedWallet({ id: payload }));
    }
  }
);

export const updateWallet = thunkActionFactory<{ bankDetails: any; ccDetails: any }>(
  async ({ payload, dispatch, state, businessLogic }) => {
    const { bankDetails, ccDetails } = payload;
    dispatch(walletActions.updateWallet());
    const { wallet } = state;

    try {
      const updateAccountInfo = bankDetails || ccDetails;
      const { id } = updateAccountInfo;
      const userWallet = getUserWalletByEntryId(wallet.userWallets, id);
      if (userWallet) {
        delete updateAccountInfo.accountNumber;
        const updateResp = await businessLogic.wallet.updateUserWallet(state, {
          id,
          ...updateAccountInfo,
        });

        dispatch(modalActions.hide());
        dispatch(walletActions.updateWalletSuccess({ updatedWallet: updateResp }));
      } else {
        throw new Error('Invalid parameter!');
      }
    } catch (error: any) {
      dispatch(walletActions.updateWalletError({ error }));
    }
  }
);

export const deleteWallet = thunkActionFactory<{
  id: UserWallet['id'];
  bankOrCard: UserWallet['walletType'];
}>(async ({ payload, dispatch, state, businessLogic }) => {
  const { id, bankOrCard } = payload;
  dispatch(walletActions.deleteWallet());

  try {
    await businessLogic.wallet.deleteUserWallet(state, {
      id,
      walletType: bankOrCard,
    });

    dispatch(walletActions.deleteWalletSuccess());
    dispatch(modalActions.hide());
  } catch (error: any) {
    dispatch(walletActions.deleteWalletError({ error }));
  }
});

export const updateWalletCancel = thunkActionFactory(({ dispatch }) => {
  dispatch(walletActions.updateWalletCancel());
  dispatch(modalActions.updateWalletCancel());
});
