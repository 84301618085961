import { sliceFactory } from 'store/utils';
import { CompanyInfo } from 'types/CompanyInfo';

const initialState: Partial<CompanyInfo> = {};

const { reducer } = sliceFactory({
  name: 'companyInfo',
  initialState,
  reducers: {},
});

export const companyInfoReducer = reducer;
