type SubmitNanopayPaymentPros = {
  config: {
    portal: String;
    ssrtid: String;
  };
  auth: {
    ticket: String;
    realmId: String;
    isUserSignedIn: Boolean;
    authToken: String;
    syncToken: String;
    entityId: String;
  };
  insight: {
    riskProfileToken: String;
    paymentProcessor: String;
    domainId: String;
    token: String;
    companyId: String;
  };
  sale: {
    saleId: String;
    freight: String;
    tax: String;
    lines: String;
    type: String;
    currency: String;
    customerName: String;
  };
  nanopayDetails: {
    nanopayToken: String;
    nanopaySourceAccount: String;
    maskedBankAccountNumber: String;
  };
  amountPaid: String;
  timeout: Number;
  getRequestBody: Function;
  getBaseRequestBody: Function;
  submitPayment: Function;
};
export const handleNanopayPayment = ({
  config,
  auth,
  insight,
  sale,
  nanopayDetails,
  amountPaid,
  timeout,
  getBaseRequestBody,
  getRequestBody,
  submitPayment,
}: SubmitNanopayPaymentPros) => {
  const { portal, ssrtid } = config;
  const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = auth;
  const {
    riskProfileToken: riskProfileToken,
    paymentProcessor,
    domainId,
    token,
    companyId,
  } = insight;
  const { saleId, freight, tax, lines, type: saleType, currency, customerName } = sale;
  const {
    nanopayToken,
    nanopaySourceAccount,
    maskedBankAccountNumber: maskedPayment,
  } = nanopayDetails;
  const paymentMethod = 'BANKTRANSFER_CA';

  const basicRequestBody = getBaseRequestBody({
    paymentMethod,
    maskedPayment,
    amountPaid,
    riskProfileToken,
    paymentProcessor,
    saleId,
    freight,
    tax,
    lines,
    saleType,
    currency,
    customerName,
  });

  const data = {
    ...getRequestBody({ paymentMethod, amountPaid, maskedPayment }),
    saleInfo: { ...basicRequestBody.saleInfo, saleType, companyId },
    nanopayInfo: { nanopayToken, nanopaySourceAccount },
  };

  return submitPayment({
    data,
    portal,
    ssrtid,
    domainId,
    token,
    ticket,
    realmId,
    isUserSignedIn,
    authToken,
    syncToken,
    entityId,
    timeout,
  });
};
