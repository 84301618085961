import type { Method } from 'axios';

import { getHeadersForSSRRequest } from 'businessLogic/utils/utils';
import HttpClient from 'server/helpers/HttpClient';
import { insightSelectors } from 'store/insight/selectors';
import { initialState } from 'store/payorHistory/slice';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';
import {
  PayorHistoryReceiptsList,
  PayorHistorySales,
  PayorHistorySaleStatus,
  PayorHistorySaleType,
  PayorHistory as PayorHistoryState,
} from 'types/PayorHistory';
import { ScheduleIdType } from 'types/constants';
import { Currency } from 'types/utils';

const httpClient = HttpClient.getInstance();

type PayorHistoryHttpRequestOptions = {
  reqType: string;
  endpoint: string;
  method: Method;
  data?: Record<string, any>;
  params?: Record<string, any>;
  store: RootState;
};

interface PayorHistorySalesSCSApiResponse {
  invoices: Array<{
    status: PayorHistorySaleStatus;
    saleId: string;
    docNumber: string;
    currency: string;
    invoiceLink: string;
    amount: number;
    balance: number;
    dueDate?: string;
    isVoid?: boolean;
    isPayEnable?: boolean;
    scheduleHistory?: {
      scheduleStatusId: number;
      scheduleTypeId: ScheduleIdType;
    };
    payments?: Array<PayorHistorySalePayment>;
  }>;
}

interface PayorHistoryPaymentsSCSApiResponse {
  payments: Array<PayorHistorySalePayment>;
}

export type PayorHistorySalePayment = {
  amountPaid: number;
  paidBy: string;
  paymentDate: string;
  paymentMethod: string;
  paymentStatus: string;
  paymentTxnId: string;
  isReceiptExist: boolean;
};

export default class PayorHistory {
  constructor() {}
  private async payorHistorySSRRequest<T>({
    endpoint,
    method,
    data = {},
    params = {},
    store,
  }: PayorHistoryHttpRequestOptions) {
    const headers = getHeadersForSSRRequest(store, {
      Accept: 'application/json, text/javascript, */*; q=0.01',
    });
    const {
      insight,
      config: { portal, ssrtid },
    } = store;
    const token = insightSelectors.tokenSelector(insight) as string;
    const ssrPayorHistoryEndpoint = `/${portal}/payor-history/${endpoint}`;

    const response: { data: T } = await httpClient({
      url: `${ssrPayorHistoryEndpoint}/`,
      method,
      headers,
      endpoint: ssrPayorHistoryEndpoint,
      ssrtid,
      token,
      data,
      params,
    });

    return response.data;
  }

  private getActiveSaleFromHistory(
    saleId: string,
    payorHistorySalesSCSApiResponse: PayorHistorySalesSCSApiResponse
  ): PayorHistorySalesSCSApiResponse['invoices'][number] | void {
    try {
      const { invoices } = payorHistorySalesSCSApiResponse;
      const invoice = invoices.find((invoice) => invoice.docNumber === saleId);

      if (invoice) {
        return invoice;
      } else {
        throw new Error('active sale not found');
      }
    } catch (error) {
      return;
    }
  }

  private mapReceiptsOfActiveSale(
    payments: Array<PayorHistorySalePayment>
  ): PayorHistoryReceiptsList | [] {
    const receipts = (payments || []).map(({ paymentDate, isReceiptExist, paymentTxnId }) => {
      return {
        paymentDate,
        id: paymentTxnId,
        isReceiptExist,
      };
    });

    return receipts;
  }

  // private calcSaleStatus(
  //   // TODO: complete logic! & move calculation to server (because of overdue calculation)
  //   sale: PayorHistorySalesSCSApiResponse['invoices'][number]
  // ): PayorHistorySaleStatus | undefined {
  //   const { balance, dueDate, scheduleHistory, amount, isVoid } = sale;
  //   let status: any;
  //
  //   if (scheduleHistory) {
  //     status = scheduleTypeMap[scheduleHistory.scheduleTypeId];
  //   } else if (getPaymentDueStatus(dueDate) === 'overdue') {
  //     status = 'OVERDUE';
  //   } else if (balance === amount) {
  //     status = 'UNPAID';
  //   } else if (balance < amount && balance > 0) {
  //     status = 'PARTIALLY_PAID';
  //   } else if (balance === 0 && !isVoid) {
  //     status = 'PAID';
  //   }
  //
  //   return status;
  // }

  private mapSaleHistory(
    saleId: string,
    payorHistorySalesSCSApiResponse: PayorHistorySalesSCSApiResponse
  ): PayorHistorySales {
    const history: Required<PayorHistorySales> = {
      open: [],
      paid: [],
    };
    const openStatuses: Array<PayorHistorySaleStatus> = [
      'SCHEDULED',
      'AUTOPAY',
      'PASTDUE',
      'UNPAID',
      'PARTIALLY_PAID',
    ];
    const paidStatuses: Array<PayorHistorySaleStatus> = ['PAID'];

    const { invoices = [] } = payorHistorySalesSCSApiResponse || {};
    invoices.forEach(({ balance, currency, invoiceLink, docNumber, status, dueDate = '' }) => {
      try {
        const isTheActiveSale = docNumber === saleId;
        const sale = {
          balance,
          currency: currency as Currency,
          link: invoiceLink,
          docNumber,
          type: 'INVOICE' as PayorHistorySaleType,
          status,
          dueDate,
          isTheActiveSale,
        };
        if (openStatuses.includes(status)) {
          history.open.push(sale);
        } else if (paidStatuses.includes(status)) {
          history.paid.push(sale);
        }
      } catch (error) {
        return;
      }
    });

    return history;
  }

  async fetchSalesAndPaymentsHistory(store: RootState): Promise<PayorHistoryState> {
    let payorHistoryState: PayorHistoryState = initialState;
    try {
      const { sale, insight } = store;
      const localId = saleSelectors.localIdSelector(sale);
      const id = saleSelectors.idSelector(sale);
      const merchantId = insightSelectors.merchantIdSelector(insight);

      const data = await this.payorHistorySSRRequest<PayorHistorySalesSCSApiResponse>({
        store,
        reqType: 'fetch_history_sales',
        endpoint: 'sales',
        method: 'GET',
        params: { merchantId, customerId: localId },
      });

      const activeInvoice = this.getActiveSaleFromHistory(id, data);
      let receiptList: PayorHistoryReceiptsList = [];
      const { payments } = activeInvoice || {};
      if (payments) {
        receiptList = this.mapReceiptsOfActiveSale(payments);
      }
      const sales = this.mapSaleHistory(id, data);
      payorHistoryState = {
        receipts: { list: receiptList, pdf: undefined },
        sales,
        isPayorHistoryEnabled: true,
      };
    } catch (error) {
      return payorHistoryState;
    }
    return payorHistoryState;
  }

  async fetchPaymentsHistory(store: RootState): Promise<PayorHistoryState> {
    let payorHistoryState: PayorHistoryState = initialState;
    try {
      const { sale, insight } = store;
      const id = saleSelectors.idSelector(sale);
      const merchantId = insightSelectors.merchantIdSelector(insight);

      const data = await this.payorHistorySSRRequest<PayorHistoryPaymentsSCSApiResponse>({
        store,
        reqType: 'fetch_history_payments',
        endpoint: 'payments',
        method: 'GET',
        params: { merchantId, saleId: id },
      });

      payorHistoryState = {
        ...payorHistoryState,
        receipts: {
          list: this.mapReceiptsOfActiveSale(data.payments),
          pdf: undefined,
        },
        isPayorHistoryEnabled: true,
      };
    } catch (error) {
      return payorHistoryState;
    }
    return payorHistoryState;
  }

  async fetchPayorHistoryReceiptPDF(
    store: RootState,
    paymentId: string
  ): Promise<Blob | undefined> {
    try {
      const {
        sale: { id },
        auth: { realmId },
      } = store;

      const data = await this.payorHistorySSRRequest<{ pdf: Blob }>({
        store,
        reqType: 'fetchPayorHistoryReceiptPDF',
        endpoint: 'receipt',
        method: 'GET',
        params: { saleId: id, realmId, paymentId },
      });

      return data && data.pdf;
    } catch (error) {
      return undefined;
    }
  }
}
