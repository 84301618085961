export type ContactInfoType = 'Email' | 'Phone number';

export enum TrackingSurveyTypes {
  RepeatBusiness = 'repeat_business',
  CustomerFeedback = 'customer_feedback',
  Referral = 'referral',
  OpenFeedback = 'open_feedback',
}

/* SCS contract */
export enum ViewType {
  WEB = 'WEB',
  EMAIL = 'EMAIL',
}
