import { RawAxiosRequestHeaders } from 'axios';

import onboardingRequestStatus from 'components/Core/Nanopay/onboardingRequestStatus.json';
import Logger from 'reporting/Logger';
import HttpClient from 'server/helpers/HttpClient';
const httpClient = HttpClient.getInstance();

const getHeaders = (config: any, insight: any, auth: any): RawAxiosRequestHeaders => {
  const { ssrtid } = config;
  const { domainId, token } = insight;
  const { entityId, realmId, ticket, isUserSignedIn, syncToken, authToken } = auth;
  return {
    Accept: 'application/json, text/javascript, */*; q=0.01',
    intuit_tid: ssrtid || '',
    'Intuit-DomainId': domainId,
    'Intuit-IntuitId': entityId,
    'Intuit-RealmId': realmId,
    'Intuit-ACSToken': token,
    sessionTicket: ticket,
    'user-signed-in': typeof isUserSignedIn === 'boolean' ? isUserSignedIn.toString() : 'false',
    syncToken: syncToken,
    Authorization: `Bearer ${authToken}`,
    'ssr-session-id': ssrtid,
  };
};

export const initializeNanopayOnboarding = async (
  auth: any,
  config: any,
  insight: any,
  sale: any,
  nanopay: any,
  userEmail: string
) => {
  const { portal } = config;
  const { loginId, accountId, institution, isBusiness, ssrtid } = nanopay;
  const { amount } = sale; // check the feature flag
  const amountInCents = amount && amount > 0 ? Math.round(amount * 100) : 0;
  const payerType = isBusiness ? '2' : '1'; // 1 = Paying with personal bank account, 2 = Paying with business bank account

  if (!amountInCents || amountInCents <= 0)
    throw new Error('Amount cannot be zero or less than zero');

  const requestHeaders = getHeaders(config, insight, auth);
  const payload = {
    amount: amountInCents,
    flinksLoginId: loginId,
    flinksAccountId: accountId,
    institution,
    userEmail,
    payerType,
  };

  try {
    const nanopayEndPoint = `/${portal}/rest/nanopay/onboarding`;

    const nanopayOnboardingResult = await httpClient({
      url: nanopayEndPoint,
      method: 'POST',
      data: payload,
      endpoint: nanopayEndPoint,
      headers: requestHeaders,
    });
    logInfoNanopayFlow({
      ssrtid,
      action: 'initializeNanopayOnboarding',
      opts: {
        status: nanopayOnboardingResult.status,
        requestHeaders,
        payload,
        response: { ...nanopayOnboardingResult.data },
      },
    });
    return nanopayOnboardingResult.data;
  } catch (error: any) {
    logInfoNanopayFlow({
      ssrtid,
      action: 'initializeNanopayOnboarding',
      isError: true,
      opts: {
        amount,
        amountInCents,
        requestHeaders,
        payload,
        error: { errorMessage: error.message, errorStack: error.stack },
      },
    });
    throw error;
  }
};

export const nanopayOnboardingRequestStatus = async (
  auth: any,
  config: any,
  insight: any,
  nanopay: any
) => {
  const { portal } = config;
  const { onboardingRequestId, ssrtid } = nanopay;

  const requestHeaders = getHeaders(config, insight, auth);
  const payload = { onboardingRequestId };

  if (onboardingRequestId === 'test_cypress') {
    return onboardingRequestStatus;
  }
  try {
    const nanopayEndPoint = `/${portal}/rest/nanopay/onboardingRequestStatus`;

    const nanopayOnboardingResult = await httpClient({
      url: nanopayEndPoint,
      method: 'POST',
      data: payload,
      endpoint: nanopayEndPoint,
      headers: requestHeaders,
    });
    logInfoNanopayFlow({
      ssrtid,
      action: 'nanopayOnboardingRequestStatus',
      opts: {
        status: nanopayOnboardingResult.status,
        requestHeaders,
        payload,
        response: { ...nanopayOnboardingResult.data },
      },
    });
    return nanopayOnboardingResult.data;
  } catch (error: any) {
    logInfoNanopayFlow({
      ssrtid,
      action: 'nanopayOnboardingRequestStatus',
      isError: true,
      opts: {
        requestHeaders,
        payload,
        error: { errorMessage: error.message, errorStack: error.stack },
      },
    });
    throw error;
  }
};

export const doSendCapabilityPayload = async (
  auth: any,
  config: any,
  insight: any,
  {
    firstName,
    lastName,
    birthday,
    phoneNumber,
    jobTitle,
    businessName,
    businessSectorId,
    holderName,
    accountNumber,
    transitNumber,
    institutionNumber,
    userAddress,
    businessAddress,
    businessMailingAddress,
    pepHIORelated,
    businessId,
    userId,
    capabilityId,
    ssrtid,
  }: any
) => {
  const {
    portal,
    nanopay: { capabilityReqTimeout },
  } = config;
  const requestHeaders = getHeaders(config, insight, auth);
  const payload = {
    firstName,
    lastName,
    birthday,
    phoneNumber,
    jobTitle,
    businessName,
    businessSectorId,
    holderName,
    accountNumber,
    transitNumber,
    institutionNumber,
    userAddress,
    businessAddress,
    businessMailingAddress,
    pepHIORelated,
    businessId,
    userId,
    capabilityId,
    ssrtid,
  };

  if (userId === 1) {
    return {
      class: 'foam.nanos.crunch.connection.CapabilityPayload',
      id: 'crunch.onboarding.api.individual-counterparty-payments',
      capabilityDataObjects: {},
      capabilityValidationErrors: {},
      bearerToken: '2ff467b7-bcde-4346-8996-08d69b30c',
    };
  }
  try {
    const nanopayEndPoint = `/${portal}/rest/nanopay/sendCapabilityPayload`;
    const nanopayCapabilityResponse = await httpClient({
      url: nanopayEndPoint,
      method: 'POST',
      data: payload,
      endpoint: nanopayEndPoint,
      headers: requestHeaders,
      timeout: capabilityReqTimeout,
    });
    logInfoNanopayFlow({
      ssrtid,
      action: 'doSendCapabilityPayload',
      opts: {
        status: nanopayCapabilityResponse.status,
        requestHeaders,
        payload,
        response: { ...nanopayCapabilityResponse.data },
      },
    });
    return nanopayCapabilityResponse.data;
  } catch (error: any) {
    logInfoNanopayFlow({
      ssrtid,
      action: 'doSendCapabilityPayload',
      isError: true,
      opts: {
        requestHeaders,
        payload,
        error: { errorMessage: error.message, errorStack: error.stack },
      },
    });
    throw error;
  }
};

export const getBankAccounts = async (
  auth: any,
  config: any,
  insight: any,
  accountId: string,
  userBearerToken: string,
  ssrtid: string
) => {
  const {
    portal,
    nanopay: { findBankReqTimeout },
  } = config;
  const requestHeaders = getHeaders(config, insight, auth);
  if (accountId === 'test_nanopay_cypress') {
    return {
      id: 'test_nanopay_cypress',
      name: 'NanoPay Bank',
      accountNumber: '012345',
    };
  } else {
    try {
      const nanopayEndPoint = `/${portal}/rest/nanopay/bank`;

      const nanopayResult = await httpClient({
        url: nanopayEndPoint,
        method: 'GET',
        params: {
          accountId,
          userBearerToken,
        },
        endpoint: nanopayEndPoint,
        headers: requestHeaders,
        timeout: findBankReqTimeout,
      });
      logInfoNanopayFlow({
        ssrtid,
        action: 'getBankAccounts',
        opts: {
          status: nanopayResult.status,
          findBankReqTimeout,
          requestHeaders,
          accountId,
          response: { ...nanopayResult.data },
        },
      });
      return nanopayResult.data;
    } catch (error: any) {
      logInfoNanopayFlow({
        ssrtid,
        action: 'getBankAccounts',
        isError: true,
        opts: {
          findBankReqTimeout,
          requestHeaders,
          accountId,
          error: { errorMessage: error.message, errorStack: error.stack },
        },
      });
      throw error;
    }
  }
};

export const getToken = async (
  auth: any,
  config: any,
  insight: any,
  payload: any,
  ssrtid: string
) => {
  const { portal } = config;
  const requestHeaders = getHeaders(config, insight, auth);

  try {
    const nanopayEndPoint = `/${portal}/app/CommerceNetwork/view/rest/nanopay/token`;

    const result = await httpClient({
      url: nanopayEndPoint,
      method: 'POST',
      data: payload,
      params: {
        ssrtid,
      },
      endpoint: nanopayEndPoint,
      headers: requestHeaders,
    });
    logInfoNanopayFlow({
      ssrtid,
      action: 'getBankAccounts',
      opts: { status: result.status, requestHeaders, ...payload, response: { ...result.data } },
    });
    return result.data;
  } catch (error: any) {
    logInfoNanopayFlow({
      ssrtid,
      action: 'getBankAccounts',
      isError: true,
      opts: {
        requestHeaders,
        ...payload,
        error: { errorMessage: error.message, errorStack: error.stack },
      },
    });
    throw error;
  }
};

type LoggerType = {
  ssrtid: string;
  action: string;
  state?: string;
  message?: string;
  isError?: boolean;
  opts?: Object;
};
const propsToMask = [
  'requestheaders',
  'authorization',
  'intuit-acstoken',
  'sessionticket',
  'synctoken',
  'birthday',
  'iban',
  'accountnumber',
  'accountnumbermask',
  'nanopayaccount',
  'bearertoken',
];
export const logInfoNanopayFlow = ({
  ssrtid,
  action,
  state,
  message,
  isError = false,
  opts = {},
}: LoggerType) => {
  const payload = { ssrtid, eventName: 'nanopay_payor_logging', action, state, message, ...opts };
  Logger.info({ ...getMaskedPayload(payload, propsToMask), isError });
};

/**
 * Only return masked payload with object having max depth of @param maxObjectIterations
 */
const getMaskedPayload = (
  payload: object = {},
  propsToMask: string[] = [],
  currentObjectIteration = 0,
  maxObjectIterations = 3
) => {
  if (Object.keys(payload).length === 0 || propsToMask.length === 0) return payload;

  let maskedPayload: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(payload)) {
    if (value && typeof value === 'object' && currentObjectIteration <= maxObjectIterations) {
      maskedPayload = {
        ...maskedPayload,
        ...getMaskedPayload(value, propsToMask, ++currentObjectIteration),
      };
    }
    if (propsToMask.includes(key.toLowerCase())) {
      maskedPayload[key] = 'xxxx-value-removed-from-log-xxxx';
    } else {
      maskedPayload[key] = value;
    }
  }
  return maskedPayload;
};
