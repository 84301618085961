import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const ErrorAlertIcon: React.FC<IconBasePropsWithColor> = ({
  width = 10,
  height = 10,
  color = colors.darkError,
}) => {
  return (
    <svg
      data-testid="error-alert-icon"
      width={width}
      height={height}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="5" cy="5" r="5" fill={color} />
        <text
          fill="#FFF"
          fontFamily="AvenirNextforINTUIT-Demi, AvenirNext forINTUIT"
          fontSize="6.923"
          fontWeight="500"
          letterSpacing="0"
        >
          <tspan x="3.846" y="7.692">
            !
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default ErrorAlertIcon;
