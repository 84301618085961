import { PayloadAction } from '@reduxjs/toolkit';

import { insightSelectors } from 'store/insight/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { PayorHistory } from 'types/PayorHistory';

export const initialState: PayorHistory = { receipts: { list: [] }, sales: {} };

const { actions, reducer } = sliceFactory({
  name: 'payorHistory',
  initialState,
  reducers: {
    initPayorHistoryState(state, action: PayloadAction<PayorHistory>) {
      const { sales, receipts, isPayorHistoryEnabled } = action.payload;
      state.sales = sales;
      state.receipts = receipts;
      state.isPayorHistoryEnabled = isPayorHistoryEnabled;
    },
    fetchPayorHistoryReceiptPDF(state, action: PayloadAction<Blob>) {
      state.receipts.pdf = action.payload;
    },
  },
});
export const payorHistoryReducer = reducer;
export const payorHistoryActions = actions;

export const initPayorHistoryState = thunkActionFactory(
  async ({ dispatch, state, businessLogic }) => {
    const { insight, sale, featureFlags } = state;
    const isQBO = insightSelectors.offeringIdSelector(insight) === 'QBO';
    const isInvoice = saleSelectors.typeSelector(sale) === 'INVOICE';
    const isFFOn = featureFlags['is-payor-history'];
    // @ts-ignore
    const isIXPOn = /* TEMP isPayorHistoryEnabled({ ixp: state.ixp, featureFlags }) */ true;
    const isEligibleForPayerHistory = isQBO && isInvoice && isFFOn && isIXPOn;

    if (isEligibleForPayerHistory) {
      let data;
      const {
        auth: { isUserSignedIn },
      } = state;

      if (isUserSignedIn) {
        data = await businessLogic.payorHistory.fetchSalesAndPaymentsHistory(state);
      } else {
        data = await businessLogic.payorHistory.fetchPaymentsHistory(state);
      }

      dispatch(actions.initPayorHistoryState(data));
    }
  }
);

export const fetchPayorHistoryReceiptPDF = thunkActionFactory<string>(
  async ({ payload, dispatch, state, businessLogic }) => {
    const pdf = await businessLogic.payorHistory.fetchPayorHistoryReceiptPDF(state, payload);
    if (pdf) {
      dispatch(actions.fetchPayorHistoryReceiptPDF(pdf));
    }
  }
);
