import React from 'react';

const onControlledMenuClick = (e: any) => {
  e.stopPropagation();
};

export interface Props {
  children: any;
}

const PopoverMenuWrapper: React.FC<Props> = ({ children, ...rest }) => (
  <div {...rest} onClick={onControlledMenuClick}>
    {children}
  </div>
);

export default PopoverMenuWrapper;
