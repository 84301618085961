import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedMessageHTML from 'components/Core/FormattedMessageHTML';
import Card from 'components/Shared/Card/Card';
import ErrorAlertIcon from 'components/Shared/Icons/ErrorAlertIcon';
import { colors, fontSize } from 'styles/cp.js';

export type Props = {
  title: string;
  subtitle?: string;
};
export interface State {}

const InfoCard = ({ title, subtitle }: Props) => {
  const [closeCard, setCloseCard] = React.useState(false);

  if (closeCard) return null;

  return (
    <div className="info-message-root">
      <Card>
        <div className="info-message-wrapper">
          <div className="info-message-icon">
            <ErrorAlertIcon color={colors.intuit_blue} height={23} width={23} />
          </div>
          <div className="info-message-content">
            <span className="info-message-title">
              <FormattedMessageHTML id={title} defaultMessage="Info" />
            </span>
            {subtitle && (
              <span className="info-message-subtitle">
                <FormattedMessage
                  id={subtitle}
                  defaultMessage="Payment wasn’t successful. Try again or contact your seller."
                />
              </span>
            )}
          </div>
          <div role="button" className="close" onClick={() => setCloseCard(true)}>
            X
          </div>
        </div>
      </Card>
      {/*language=SCSS*/}
      <style jsx>{`
        .info-message-root {
          margin-bottom: 20px;
        }
        .info-message-wrapper {
          display: flex;
          flex-direction: row;
          border: solid 1px ${colors.intuit_blue};
          border-radius: 14px;
          padding: 20px;
        }

        .info-message-icon {
          display: flex;
          align-self: flex-start;
        }

        .info-message-icon > svg {
          height: 23px;
          width: 23px;
        }

        .info-message-content {
          flex: 1;
          text-align: left;
          margin-left: 10px;
        }
        .info-message-title {
          font-size: ${fontSize.sm};
        }

        .info-message-subtitle {
          flex: 1;
          font-size: ${fontSize.xxs};

          font-style: normal;
          font-size: 14px;
          line-height: 20px;
          margin-left: 5px;
        }

        .close {
          color: ${colors.gray02};
          font-size: ${fontSize.sm};
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default InfoCard;
