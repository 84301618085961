import * as React from 'react';
import { Popover, PopoverProps } from 'reactstrap';

import { colors } from 'styles/cp';
import { popoverStyles } from 'styles/popover';

const CpPopover: React.FC<PopoverProps> = (props) => {
  const { target } = props;

  const shouldRenderPopover =
    typeof window !== 'undefined' && document?.getElementById(target as string);

  return !shouldRenderPopover ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      <Popover {...props}>{props.children}</Popover>

      <style jsx global>{`
        .popover {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1002;
          display: block;
          word-wrap: break-word;
          background-color: ${colors.white};
          background-clip: padding-box;
          border-radius: 4px;
          box-shadow: 0 3px 3px 0 ${popoverStyles.menuItemDividerBorderColor};

          .arrow {
            position: absolute;
            display: block;
            width: ${popoverStyles.popoverArrowWidth};
            height: ${popoverStyles.popoverArrowHeight};
            margin: 0 ${popoverStyles.borderRadiusLg};

            &::before,
            &::after {
              position: absolute;
              display: block;
              content: '';
              border-color: transparent;
              border-style: solid;
            }
          }
        }

        .bs-popover-top {
          margin-bottom: ${popoverStyles.popoverArrowHeight};

          .arrow {
            bottom: calc(
              calc(${popoverStyles.popoverArrowHeight} + ${popoverStyles.popoverBorderWidth}) * -1
            );
          }

          .arrow::before,
          .arrow::after {
            border-width: ${popoverStyles.popoverArrowHeight}
              calc(${popoverStyles.popoverArrowWidth} / 2) 0;
          }

          .arrow::before {
            bottom: 1px;
            border-top-color: ${popoverStyles.popoverArrowOuterColor};
          }

          .arrow::after {
            bottom: ${popoverStyles.popoverBorderWidth};
          }
        }

        .bs-popover-right {
          margin-left: ${popoverStyles.popoverArrowHeight};

          .arrow {
            left: calc(
              calc(${popoverStyles.popoverArrowHeight} + ${popoverStyles.popoverBorderWidth}) * -1
            );
            width: ${popoverStyles.popoverArrowHeight};
            height: ${popoverStyles.popoverArrowWidth};
            margin: ${popoverStyles.borderRadiusLg} 0;
          }

          .arrow::before,
          .arrow::after {
            border-width: calc(${popoverStyles.popoverArrowWidth} / 2)
              ${popoverStyles.popoverArrowHeight} calc(${popoverStyles.popoverArrowWidth} / 2) 1px;
          }

          .arrow::before {
            left: 0;
            border-right-color: ${popoverStyles.popoverArrowOuterColor};
          }

          .arrow::after {
            left: ${popoverStyles.popoverBorderWidth};
            border-right-color: ${popoverStyles.popoverArrowColor};
          }
        }

        .bs-popover-bottom {
          margin-top: ${popoverStyles.popoverArrowHeight};

          .arrow {
            top: calc(
              (${popoverStyles.popoverArrowHeight} + ${popoverStyles.popoverBorderWidth}) * -1
            );
          }

          .arrow::before,
          .arrow::after {
            border-width: 0 calc(${popoverStyles.popoverArrowWidth} / 2)
              ${popoverStyles.popoverArrowHeight} calc(${popoverStyles.popoverArrowWidth} / 2);
          }

          .arrow::before {
            top: 0;
          }

          .arrow::after {
            top: ${popoverStyles.popoverBorderWidth};
            border-bottom-color: ${popoverStyles.popoverArrowColor};
          }

          .popover-header::before {
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: ${popoverStyles.popoverArrowWidth};
            margin-left: calc(${popoverStyles.popoverArrowWidth} / -2);
            content: '';
            border-bottom: ${popoverStyles.popoverBorderWidth} solid
              ${popoverStyles.popoverHeaderBg};
          }
        }

        .bs-popover-left {
          margin-right: ${popoverStyles.popoverArrowHeight};

          .arrow {
            right: calc(
              (${popoverStyles.popoverArrowHeight} + ${popoverStyles.popoverBorderWidth}) * -1
            );
            width: ${popoverStyles.popoverArrowHeight};
            height: ${popoverStyles.popoverArrowWidth};
            margin: ${popoverStyles.borderRadiusLg} 0;
          }

          .arrow::before,
          .arrow::after {
            border-width: calc(${popoverStyles.popoverArrowWidth} / 2) 1px
              calc(${popoverStyles.popoverArrowWidth} / 2) ${popoverStyles.popoverArrowHeight};
          }

          .arrow::before {
            right: 0;
            border-left-color: ${popoverStyles.popoverArrowOuterColor};
          }

          .arrow::after {
            right: ${popoverStyles.popoverBorderWidth};
            border-left-color: ${popoverStyles.popoverArrowColor};
          }
        }

        .bs-popover-auto {
          &[x-placement^='top'] {
            @extend .bs-popover-top;
          }

          &[x-placement^='right'] {
            @extend .bs-popover-right;
          }

          &[x-placement^='bottom'] {
            @extend .bs-popover-bottom;
          }

          &[x-placement^='left'] {
            @extend .bs-popover-left;
          }
        }

        .cp-menu-wrapper {
          border: ${popoverStyles.popoverBorderWidth} solid
            ${popoverStyles.popoverUserMenuBorderColor};
          .arrow {
            text-shadow: 0 0 20px black;
          }

          &.bs-popover-top {
            .arrow::before {
              bottom: 0;
              border-top-color: ${popoverStyles.whitePopoverArrowOuterColor};
            }

            .arrow::after {
              bottom: ${popoverStyles.popoverBorderWidth};
              border-top-color: ${popoverStyles.whitePopoverArrowColor};
            }
          }

          &.bs-popover-right {
            .arrow::before {
              left: 0;
              border-right-color: ${popoverStyles.whitePopoverArrowOuterColor};
            }

            .arrow::after {
              left: ${popoverStyles.popoverBorderWidth};
              border-right-color: ${popoverStyles.whitePopoverArrowColor};
            }
          }

          &.bs-popover-bottom {
            .arrow::before {
              top: 0;
              border-bottom-color: ${popoverStyles.whitePopoverArrowOuterColor};
            }

            .arrow::after {
              top: ${popoverStyles.popoverBorderWidth};
              border-bottom-color: ${popoverStyles.whitePopoverArrowColor};
            }

            .popover-header::before {
              position: absolute;
              top: 0;
              left: 50%;
              display: block;
              width: ${popoverStyles.popoverArrowWidth};
              margin-left: calc(${popoverStyles.popoverArrowWidth} / -2);
              content: '';
              border-bottom: ${popoverStyles.popoverBorderWidth} solid
                ${popoverStyles.popoverHeaderBg};
            }
          }

          &.bs-popover-left {
            .arrow::before {
              right: 0;
              border-left-color: ${popoverStyles.whitePopoverArrowOuterColor};
            }

            .arrow::after {
              right: ${popoverStyles.popoverBorderWidth};
              border-left-color: ${popoverStyles.whitePopoverArrowColor};
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default CpPopover;
