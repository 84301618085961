import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import NanopayTooltipContent from 'components/Core/Nanopay/TooltipContent/NanopayTooltipContent';
import CpPopover from 'components/Shared/Popover/CpPopover';

const BankTransferCAFooter = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id="nanopayTooltip" className="ca-bank-footer-container">
      <div
        id="nanopay-tooltip-title"
        className="title dotted"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="open popover"
      >
        <FormattedMessage id="CA_BANK_FOOTER_TITLE" defaultMessage="Powered by nanopay" />
      </div>

      <CpPopover
        style={{ top: -30 }}
        // @ts-ignore
        trigger="hover"
        placement="top"
        target="nanopayTooltip"
        isOpen={isOpen}
      >
        <span>
          <NanopayTooltipContent open={setIsOpen} />
        </span>
      </CpPopover>

      <style jsx>{`
        .ca-bank-footer-container {
          display: flex;
          justify-content: center;
          background: #393a3d;

          .title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin: 24px 0;
            color: #ffffff;
            cursor: pointer;
          }

          .dotted {
            border-bottom: 1px dotted #ffffff;
            text-decoration: none;
          }
        }
      `}</style>
    </div>
  );
};

export default BankTransferCAFooter;
