import React from 'react';

type InvoicePDFObjectProps = {
  title?: string;
  className?: string;
  width?: string;
  height?: string;
  pdfUrl?: string;
  hidden?: boolean;
};

const InvoicePDFObject: React.FC<InvoicePDFObjectProps> = (props) => {
  let { pdfUrl = '', hidden = false, ...restOfProps } = props;
  let style = hidden ? { display: 'none' } : {};
  return (
    <object
      data={pdfUrl}
      type="application/pdf"
      style={style}
      {...restOfProps}
      aria-label="PDF Invoice"
    />
  );
};

export default InvoicePDFObject;
