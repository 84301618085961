import React from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';

type Props = {
  values?: Record<string, string>;
} & MessageDescriptor;

/** This component should be used in rear cases, when translation string contains HTML tags */
const FormattedMessageHTML: React.FC<Props> = ({ id, defaultMessage, description, values }) => {
  const intl = useIntl();
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: intl.formatMessage(
          {
            id,
            defaultMessage,
            description,
          },
          values,
          { ignoreTag: true }
        ),
      }}
    />
  );
};

export default FormattedMessageHTML;
