import React from 'react';

import BaseInput from 'components/Shared/Inputs/BaseInput';
import { colors } from 'styles/cp';

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  isInError?: boolean;
  height?: number;
  fontSize?: string;
  disabled?: boolean;
  pattern?: string;
  inputCustomStyling?: string;
  children?: React.ReactNode | string | null;
  className?: string;
  type?: string;
  maxLength?: number;
  placeholder?: string;
  value?: string;
  required?: boolean;
  theme?: 'qbo';
  ariaLabel?: string;
}

const Input: React.FC<InputProps> = ({
  isInError = false,
  children,
  height = 36,
  inputCustomStyling,
  theme,
  ariaLabel,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="input-wrapper">
          <BaseInput isInError={isInError} theme={theme} {...rest} ariaLabel={ariaLabel} />
        </div>
        {children}
      </div>
      {/* language=scss */}
      <style jsx>{`
        .wrapper {
          display: flex;
          border: 1px solid ${isInError ? colors.error : colors.gray05};
          border-radius: 4px;
          ${inputCustomStyling || ''};
          min-height: ${height}px;
          transition: border 0.3s;

          &:hover {
            border: ${isInError ? `1px solid ${colors.error}` : `1px solid ${colors.green}`};
          }
          &:focus-within {
            border: ${isInError ? `1px solid ${colors.error}` : `2px solid ${colors.green}`};
          }
        }

        .input-wrapper {
          width: 100%;
        }
      `}</style>
    </React.Fragment>
  );
};

export default Input;
