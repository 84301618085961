const debugCreator = require('debug');
const debug = debugCreator('reporting::Logger');

const util = require('util');

const normalizeRawMessage = (_raw) => {
  // While normalizing, create a new object reference.
  // Otherwise the test assertions are incorrect.

  let raw;
  if (typeof _raw === 'string') {
    raw = {
      message: _raw,
    };
  } else {
    raw = {
      ..._raw,
    };
  }
  const isNewSyntax = raw.newSyntax === true;

  if (typeof raw.timestamp !== 'number' && !isNewSyntax) {
    raw.timestamp = new Date().toISOString();
  }

  return raw;
};

class Logger {
  constructor() {
    // Enum
    this.LOG_LEVEL = {};
    this.LOG_LEVEL[(this.LOG_LEVEL['debug'] = 0)] = 'debug';
    this.LOG_LEVEL[(this.LOG_LEVEL['info'] = 1)] = 'info';
    this.LOG_LEVEL[(this.LOG_LEVEL['warn'] = 2)] = 'warn';
    this.LOG_LEVEL[(this.LOG_LEVEL['error'] = 3)] = 'error';

    this.info = this.info.bind(this);
    this.error = this.error.bind(this);
    this.warn = this.warn.bind(this);
    this.debug = this.debug.bind(this);

    this.namespaces = {};
    this._logLevel = this.LOG_LEVEL.info;
  }

  get logLevel() {
    return this._logLevel;
  }

  set logLevel(value) {
    this._logLevel = value;
    debug(`setting log level to=${value}`);
  }

  error(raw, opts = {}) {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const { userLogLevel } = opts;
    const logLevel = userLogLevel !== undefined ? userLogLevel : this._logLevel;
    if (logLevel <= this.LOG_LEVEL.error) {
      Object.keys(this.namespaces).map((namespace) => {
        try {
          this.namespaces[namespace].report(normalizeRawMessage(raw), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.error],
            logLevel: this.LOG_LEVEL.error,
            ...opts,
          });
        } catch (e) {
          const errorObject = {
            EventName: 'LoggerError',
            ErrorMessage: e && e.message,
            safeStringified: util.inspect(raw),
          };

          this.namespaces[namespace].report(normalizeRawMessage(errorObject), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.error],
            logLevel: this.LOG_LEVEL.error,
            ...opts,
          });
        }
      });
    }
  }

  warn(raw, opts = {}) {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const { userLogLevel } = opts;
    const logLevel = userLogLevel !== undefined ? userLogLevel : this._logLevel;
    if (logLevel <= this.LOG_LEVEL.warn) {
      Object.keys(this.namespaces).map((namespace) => {
        try {
          this.namespaces[namespace].report(normalizeRawMessage(raw), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.warn],
            logLevel: this.LOG_LEVEL.warn,
            ...opts,
          });
        } catch (e) {
          const errorObject = {
            EventName: 'LoggerError',
            ErrorMessage: e && e.message,
            safeStringified: util.inspect(raw),
          };

          this.namespaces[namespace].report(normalizeRawMessage(errorObject), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.error],
            logLevel: this.LOG_LEVEL.error,
            ...opts,
          });
        }
      });
    }
  }

  info(raw, opts = {}) {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const { userLogLevel } = opts;
    const logLevel = userLogLevel !== undefined ? userLogLevel : this._logLevel;
    if (logLevel <= this.LOG_LEVEL.info) {
      Object.keys(this.namespaces).map((namespace) => {
        try {
          this.namespaces[namespace].report(normalizeRawMessage(raw), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.info],
            logLevel: this.LOG_LEVEL.info,
            ...opts,
          });
        } catch (e) {
          const errorObject = {
            EventName: 'LoggerError',
            ErrorMessage: e && e.message,
            safeStringified: util.inspect(raw),
          };

          this.namespaces[namespace].report(normalizeRawMessage(errorObject), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.error],
            logLevel: this.LOG_LEVEL.error,
            ...opts,
          });
        }
      });
    }
  }

  debug(raw, opts = {}) {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const { userLogLevel } = opts;
    const logLevel = userLogLevel !== undefined ? userLogLevel : this._logLevel;
    if (logLevel <= this.LOG_LEVEL.debug) {
      Object.keys(this.namespaces).map((namespace) => {
        try {
          this.namespaces[namespace].report(normalizeRawMessage(raw), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.debug],
            logLevel: this.LOG_LEVEL.debug,
            ...opts,
          });
        } catch (e) {
          const errorObject = {
            EventName: 'LoggerError',
            ErrorMessage: e && e.message,
            safeStringified: util.inspect(raw),
          };

          this.namespaces[namespace].report(normalizeRawMessage(errorObject), {
            logType: this.LOG_LEVEL[this.LOG_LEVEL.error],
            logLevel: this.LOG_LEVEL.error,
            ...opts,
          });
        }
      });
    }
  }

  /**
   * Logging dependency injection
   * @param {{NAME: string, report: (msg: string)=>void}} namespace
   */
  addNamespace(namespace) {
    if (
      !(
        typeof namespace === 'object' &&
        typeof namespace.NAME === 'string' &&
        typeof namespace.report === 'function'
      )
    ) {
      throw new Error('Namespace is not valid');
    }

    this.namespaces[namespace.NAME] = namespace;

    debug(`${namespace.NAME} namespace added`);
  }
}

module.exports = new Logger();
