import type { Auth } from 'types/Auth';
import type { Config } from 'types/Config';
import type { Insight } from 'types/Insight';

import HttpClient from 'server/helpers/HttpClient';
import { getWeakTicket } from 'shared/clientUtils';
import { IXPExperiment } from 'types/IXP';
const httpClient = HttpClient.getInstance();

export type ExperimentsApiResponseType = {
  data: {
    [key: string]: IXPExperiment;
  };
};

export default class IXP {
  constructor() {}

  async fetchExperiments(
    {
      auth,
      config,
      insight,
    }: {
      auth: Pick<
        Auth,
        | 'ticket'
        | 'realmId'
        | 'isUserSignedIn'
        | 'entityId'
        | 'syncToken'
        | 'authToken'
        | 'recipientEmail'
      >;
      config: Pick<Config, 'ssrtid' | 'portal' | 'env'>;
      insight: Pick<Insight, 'domainId' | 'token'>;
    },
    {
      experimentIdsProd,
      experimentIdsPreprod,
    }: {
      experimentIdsProd: number[];
      experimentIdsPreprod: number[];
    }
  ) {
    try {
      const { ticket, realmId, isUserSignedIn, entityId, syncToken, authToken, recipientEmail } =
        auth;
      const { ssrtid, portal, env } = config;
      const { domainId, token } = insight;
      let experimentIds: number[];
      switch (env) {
        case 'prod':
        case 'stg':
          experimentIds = experimentIdsProd;
          break;
        default:
          experimentIds = experimentIdsPreprod;
      }
      const data = {
        domainId,
        realmId,
        token,
        recipientEmail,
        contextMap: {
          isBankOnlyPM: true,
        },
        experimentIds,
      };

      const headers = getWeakTicket({
        domainId,
        entityId,
        realmId,
        token,
        ticket,
        isUserSignedIn,
        syncToken,
        authToken,
        ssrtid,
      });
      const res: ExperimentsApiResponseType = await httpClient({
        url: `/${portal}/rest/experiments`,
        method: 'POST',
        endpoint: `/rest/experiments`,
        headers,
        data,
      });
      const { data: experiments } = res;
      return experiments;
    } catch (e) {
      return {};
    }
  }
}
