import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';
import {
  HTTP_STATUS_CODES,
  NODE_NETWORK_ERRORS,
  PAYMENT_ERROR_TYPES,
  TXN_MAP,
  CP_ERROR_CODES,
} from 'types/constants';

/**
 * Translates ValidationErrorEnum type in to a string that belongs in
 * our i18n keys.
 * @param err
 */
export const formErrorTranslator = () => (err) => {
  switch (err) {
    case ValidationErrorEnum.REQUIRED_ERROR_MESSAGE: {
      return 'PAYFLOW_BLANK_FIELD';
    }

    case ValidationErrorEnum.INVALID_ACCOUNT_NUMBER: {
      return 'PAYFLOW_VALIDATION_BANK_ACCOUNT_NUMBER_LENGTH';
    }

    case ValidationErrorEnum.ACCOUNT_CONFIRMATION_MISMATCH: {
      return 'PAYFLOW_VALIDATION_BANK_CONFIRMACCOUNTNUMBER_MATCH';
    }

    case ValidationErrorEnum.INVALID_FIRST_AND_LAST_NAME: {
      return 'PAYFLOW_VALIDATION_CARD_INVALID_FIRST_AND_LAST_NAME';
    }

    case ValidationErrorEnum.INVALID_ROUTING_NUMBER: {
      return 'PAYFLOW_VALIDATION_BANK_ROUTINGNUMBER_LENGTH';
    }

    case ValidationErrorEnum.INVALID_CARD_NUMBER: {
      return 'PAYFLOW_VALIDATION_CARD_CARDNUMBER_LENGTH';
    }

    case ValidationErrorEnum.INVALID_CVC: {
      return 'PAYFORM_CARD_CVV_CODE';
    }

    case ValidationErrorEnum.INVALID_CVC_AMEX: {
      return 'PAYFLOW_VALIDATION_CARD_CVV_AMEX_LENGTH';
    }

    case ValidationErrorEnum.INVALID_CVC_NON_AMEX: {
      return 'PAYFLOW_VALIDATION_CARD_CVV_NONAMEX_LENGTH';
    }

    case ValidationErrorEnum.INVALID_EXP_DATE: {
      return 'PAYFLOW_VALIDATION_CARD_EXPDATE_INVALID';
    }

    case ValidationErrorEnum.INVALID_ZIP_CODE: {
      return 'PAYFLOW_VALIDATION_CARD_ZIP_INVALID';
    }

    case ValidationErrorEnum.INVALID_CARD_TYPE: {
      return 'PAYFLOW_VALIDATION_CARD_TYPE_INVALID';
    }

    case ValidationErrorEnum.INVALID_TRANSIT_NUMBER: {
      return 'PAYFLOW_VALIDATION_BANK_TRANSIT_NUMBER_LENGTH';
    }

    case ValidationErrorEnum.INVALID_INSTITUTION_NUMBER: {
      return 'PAYFLOW_VALIDATION_BANK_INSTITUTION_NUMBER_LENGTH';
    }

    default: {
      return 'PAYFLOW_BLANK_FIELD';
    }
  }
};

/**
 *
 * @param status
 */
export const networkPaymentErrorTranslator = ({ status, data, featureFlags = {} } = {}) => {
  if (data && data.message) {
    return data;
  }
  if (
    status === HTTP_STATUS_CODES.Ok &&
    data &&
    data.paymentStatus === PAYMENT_ERROR_TYPES.DECLINED
  ) {
    return { message: PAYMENT_ERROR_TYPES.DECLINED };
  }
  if (
    featureFlags['payment-error-refresh-experience'] &&
    (status === NODE_NETWORK_ERRORS.ECONNABORTED || status === HTTP_STATUS_CODES.BadGateway)
  ) {
    return { message: PAYMENT_ERROR_TYPES.TIMEOUT };
  }
  if (
    featureFlags['appconnect-error-refresh-experience'] &&
    (data.paymentStatus === TXN_MAP.STATUS.DECLINED || data.paymentStatus === TXN_MAP.STATUS.ERROR)
  ) {
    return { message: PAYMENT_ERROR_TYPES.APPCONNECT };
  }
  if (status >= HTTP_STATUS_CODES.InternalServerError) {
    return { message: PAYMENT_ERROR_TYPES.GENERAL_PAYMENT_ERROR };
  }

  if (status === HTTP_STATUS_CODES.BadRequest) {
    const { errorCode } = data;
    if (errorCode === CP_ERROR_CODES.RISK_PAYMENT_ERROR) {
      return { message: PAYMENT_ERROR_TYPES.RISK_PAYMENT_ERROR };
    }
    if (errorCode === CP_ERROR_CODES.SALE_VALIDATION) {
      return { message: PAYMENT_ERROR_TYPES.SALE_VALIDATION };
    }
    if (errorCode === CP_ERROR_CODES.MISSING_ZIPCODE_ERROR) {
      return { message: PAYMENT_ERROR_TYPES.MISSING_ZIPCODE_ERROR };
    }
  }

  return { message: PAYMENT_ERROR_TYPES.GENERAL_PAYMENT_ERROR };
};
