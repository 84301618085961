import { sliceFactory } from 'store/utils';
import { FeatureFlags } from 'types/FeatureFlags';

const initialState: FeatureFlags = {};

const { reducer } = sliceFactory({
  name: 'featureFlags',
  initialState,
  reducers: {},
});

export const featureFlagsReducer = reducer;
