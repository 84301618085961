import debugCreator from 'debug';

import {
  calculateDiscountAmount,
  calculateFreightAmount,
  processLineItems,
} from 'businessLogic/server/scsToIcnAdapter.js';
import { BankAccountTypeEnumInput } from 'components/Core/WalletForms/validations/src/types';
import convertLocaleToCountry from 'server/helpers/convertLocaleToCountry';
import { formatMaskedAccountNumber } from 'shared/PaymentHelpers';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { accountTypeTranslator, CpServerWalletHelpers } from 'store/wallet/helpers';

export interface PaymentPayload {
  tokenNumber: string;
  backupTokenNumber?: string;
  paymentMethod: string;

  [propName: string]: any;
}

const debug = debugCreator('store:payments:actions');

const generateSharedPayload = (
  { companyInfo, insight, payment, sale }: any,
  isL3Included = false
) => {
  const invoiceAmount = saleSelectors.amountSelector(sale);
  const balanceAmount = saleSelectors.balanceSelector(sale);
  const companyName = companyInfoSelectors.nameSelector(companyInfo);
  const companyLocale = companyInfoSelectors.localeSelector(companyInfo);
  const paymentProcessor = insightSelectors.paymentProcessorSelector(insight);
  const offerId = insightSelectors.offerIdSelector(insight);
  const currency = saleSelectors.currencySelector(sale);
  const transactionType = saleSelectors.typeSelector(sale);
  const customerName = saleSelectors.customerNameSelector(sale);
  const dutyAmount = saleSelectors.dutyAmountSelector(sale);
  const customerJobId = saleSelectors.customerJobIdSelector(sale);
  const customerJobName = saleSelectors.customerJobNameSelector(sale);

  const { id: invoiceId, lines, freight, tax = {} } = sale;
  const { subscriptionStatus } = companyInfo;
  const { appSourceOffering, isAvailableBalance, depositDisabled } = insight;

  const lineItems = processLineItems(lines);
  const discountAmount = calculateDiscountAmount(lines);
  const freightAmount = calculateFreightAmount(freight);
  const taxAmount = (tax && tax.totalTaxAmount) || 0;
  const shippingAmount = freightAmount;

  const { amount } = payment;
  const { isGpu: isGPU } = sale;

  let common: any = {
    companyLocale,
    paymentProcessor,
    amount,
    subscriptionStatus,
    offerId,
    currency,
    appSourceOffering,
    invoiceId,
    customerJobId,
    customerJobName,
    invoiceTotal: invoiceAmount,
    invoiceBalance: balanceAmount,
    isAvailableBalance,
    depositDisabled,
    transactionType,
    companyName,
    isGPU,
    customerName,
  };

  if (isL3Included) {
    common = {
      ...common,
      lineItems,
      freightAmount,
      discountAmount,
      shippingAmount,
      taxAmount,
      customerJobReferenceNumber: undefined,
      dutyAmount,
    };
  }

  return common;
};

export const getZipCode = (ccDetails: Record<string, any>) => {
  if (
    ccDetails &&
    ccDetails.address &&
    ccDetails.address.addressComponents &&
    ccDetails.address.addressComponents[0] &&
    ccDetails.address.addressComponents[0].name === 'postalCode'
  ) {
    return ccDetails.address.addressComponents[0].value;
  }
  return '';
};

/**
 * Translates Apple Pay details into CPServer format
 * @param {*} payment
 * @param {*} state
 * @returns {import('./cpServerTranslators').PaymentPayload}
 */
export const getApplePayPaymentPayloadForCpServer = (payment: Record<string, any>, state: any) => {
  const { sale, companyInfo } = state;
  const companyLocale = companyInfoSelectors.localeSelector(companyInfo);
  const customerName = saleSelectors.customerNameSelector(sale);
  const isGpu = saleSelectors.isGpu(sale);

  let body = {
    ...generateSharedPayload(state, true),
    cardType: 'Credit',
    name: customerName,
    maskedCC: payment.paymentMethod.displayName,
    tokenNumber: JSON.stringify({ token: payment }),
    paymentMethod: 'applePay',
    countryCode: convertLocaleToCountry(companyLocale),
    savePaymentMethod: false,
    isGpu,
  };

  validatePaymentPayload(body);
  return body;
};

/**
 * Translates Credit Card details into CPServer format
 * @param {*} ccDetails
 * @param {*} state
 * @returns {import('./cpServerTranslators').PaymentPayload}
 */
export const getCCPaymentPayloadForCpServer = (ccDetails: any, state: any) => {
  debug(ccDetails, state);

  const {
    wallet: { selectedWalletId, userWallets },
    payment: { isSavePaymentMethodChecked },
  } = state;

  let body: Record<string, any> = {
    ...generateSharedPayload(state, true),
  };

  if (userWallets && userWallets.length && selectedWalletId !== 'AddCard') {
    const card = userWallets.find((card: any) => card.id === selectedWalletId);

    const { cardNumber, cardType, payorFirstName, postalCode } = card;

    body = {
      ...body,
      name: payorFirstName,
      cardType: CpServerWalletHelpers.translateCardTypeToCpServer(cardType),
      maskedCC: cardNumber,
      tokenNumber: cardNumber,
      paymentMethod: 'credit card wallet',
      walletEntryId: selectedWalletId,
      zipCode: postalCode,
    };
  } else {
    const { number, cardType, name, token, backUpToken } = ccDetails;

    // For success screen, add some fields
    ccDetails.cardNumber = number;
    ccDetails.cardType = cardType;

    body = {
      ...body,
      tokenNumber: token,
      backupTokenNumber: backUpToken,
      paymentMethod: 'credit card',
      savePaymentMethod: isSavePaymentMethodChecked,
      maskedCC: formatMaskedAccountNumber(number),
      cardType: CpServerWalletHelpers.translateCardTypeToCpServer(cardType),
      name,
      zipCode: getZipCode(ccDetails),
    };
  }

  validatePaymentPayload(body);
  return body;
};

/**
 * Translates nanopay Bank payment details into CPServer format
 * @param {*} bankPaymentDetails
 * @param {*} state
 * @returns {import('./cpServerTranslators').PaymentPayload}
 */
export const getNanopayBankPaymentPayloadForCpServer = (
  nanopayDetails: Record<string, any>,
  state: any
) => {
  debug(nanopayDetails, state);

  let body = {
    ...generateSharedPayload(state, true),
  };

  body = {
    ...body,
    ...nanopayDetails,
    tokenNumber: `${nanopayDetails.nanopayToken}`,
    paymentMethod: 'BANKTRANSFER_CA',
  };

  validatePaymentPayload(body);
  return body;
};

export const getBankPaymentPayloadForCpServer = (bankDetails: Record<string, any>, state: any) => {
  const {
    wallet: { selectedWalletId, userWallets },
    payment: { isSavePaymentMethodChecked },
  } = state;

  let body: any = {
    ...generateSharedPayload(state),
  };

  if (userWallets && userWallets.length && selectedWalletId !== 'AddBank') {
    const bankAccount = userWallets.find((bank: any) => bank.id === selectedWalletId);

    const { accountNumber, accountType, payorFirstName, payorLastName } = bankAccount;

    body = {
      ...body,
      payorFirstName,
      payorLastName,
      accountNumber,
      accountType,
      displayAccountType: accountType,
      paymentMethod: 'bank wallet',
      walletEntryId: selectedWalletId,
    };
  } else {
    const { phone: phoneNumber, name, bankCode, ...restOfBankAccountInfo } = bankDetails;

    body = {
      ...body,
      ...restOfBankAccountInfo,
      paymentMethod: 'bank',
      routingNumber: bankCode,
      payorFirstName: name.split(' ')[0],
      payorLastName: !name.split(' ')[1] ? ' ' : name.split(' ')[1], //TODO trim the space in cp server
      phoneNumber,
      savePaymentMethod: isSavePaymentMethodChecked,
      displayAccountType: accountTypeTranslator.fromWalletToCpServer(
        restOfBankAccountInfo.accountType
      ),
      businessAccount:
        restOfBankAccountInfo.accountType === BankAccountTypeEnumInput.BUSINESS_CHECKING ||
        restOfBankAccountInfo.accountType === BankAccountTypeEnumInput.BUSINESS_SAVINGS,
    };
  }

  return body;
};

/**
 * Validator which throws if the payment payload is invalid
 * Meant to be used for credit card, debit card, and apple pay transactions
 * @param {import("./cpServerTranslators").PaymentPayload} body
 * @returns void
 */
export const validatePaymentPayload = (body: any) => {
  // this validation checks for walletTokenization tokenNumber used as a placeholder for the real credit card number. It should always be a string, and never null
  if (typeof body.tokenNumber !== 'string') {
    throw Error('invalid payment payload: tokenNumber should be a string');
  }
};
