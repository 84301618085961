import { PayloadAction } from '@reduxjs/toolkit';

import { sliceFactory } from 'store/utils';
import { Modal } from 'types/Modal';

const initialState: Partial<Modal> = {
  isOpen: false,
};

const resetState = (state: Partial<Modal>) => ({
  ...state,
  isOpen: false,
  Component: undefined,
  componentProps: undefined,
});

const { reducer, actions } = sliceFactory({
  name: 'modal',
  initialState,
  reducers: {
    show(state, action: PayloadAction<Pick<Modal, 'component' | 'componentProps' | 'background'>>) {
      const { component, componentProps, background } = action.payload;
      return {
        ...state,
        background,
        isOpen: true,
        Component: component,
        componentProps: componentProps || {},
      };
    },
    updateWalletCancel(state) {
      return resetState(state);
    },
    hide(state) {
      return resetState(state);
    },
  },
});

export const modalReducer = reducer;
export const modalActions = actions;
