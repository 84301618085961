import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { isOutdatedBrowser, isMobile } from 'server/helpers/deviceInfo';
const splunkReporter = SplunkReporter.getInstance();
interface perfNavigator extends Navigator {
  userAgent: string;
  connection?: {
    supported: boolean;
  };
}
interface perfWindow extends Window {
  SSR_VISIBLE?: number;
  navigator: perfNavigator;
}
export const onDocumentStateComplete = async ({
  companyLocale,
  pageName,
  usedCache,
}: {
  companyLocale: string;
  pageName: string;
  usedCache: boolean;
}): Promise<void> => {
  try {
    const navigationEntries: PerformanceEntry[] = window.performance.getEntriesByType('navigation');
    const navigation: PerformanceEntry | { navigation: string } =
      navigationEntries.length > 0 ? navigationEntries[0] : { navigation: 'no navigation entries' };
    const perfWindow: perfWindow = global.window;
    const { navigator: { userAgent = 'unknown', connection = { supported: false } } = {} } =
      perfWindow;

    const isUnsupportedBrowser: boolean = isOutdatedBrowser(userAgent);
    const performanceReportMessage = {
      EventName: 'PerformanceReport',
      navigation,
      performance: window.performance,
      userAgent,
      isUnsupportedBrowser,
      isMobileDevice: isMobile(userAgent),
      connection,
      Locale: companyLocale,
      PageName: pageName,
      usedCache,
      ssrV2Visible: 0,
    };

    if (perfWindow.SSR_VISIBLE) {
      performanceReportMessage.ssrV2Visible = perfWindow.SSR_VISIBLE;
    }

    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger: 'businessLogic/utils/performanceReporting' },
      event: 'viewSale',
      action: 'performanceReport',
      activityInfo: {
        pageName,
      },
      rum: {
        start: 0,
        end: 0,
        executionTimeMs: perfWindow.SSR_VISIBLE ? perfWindow.SSR_VISIBLE : 0,
      },
    });
  } catch (e) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger: 'businessLogic/utils/performanceReporting' },
      event: 'viewSale',
      action: 'performanceReport',
      activityInfo: {
        error: (e as Error).message,
        pageName,
      },
    });
  }
};
