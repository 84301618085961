import { TXN_MAP } from 'types/constants';

export function getPaymentRequestBody({ basicRequestBody, sale, payment }) {
  return sale.subType === TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE
    ? oneToManyBody({ basicRequestBody, sale, payment })
    : oneToOneBody({ basicRequestBody, sale });
}

function oneToManyBody({ basicRequestBody, sale, payment }) {
  const { subType, createdByApp } = sale;

  return {
    ...basicRequestBody,
    paymentBasicSaleInfo: {
      ...basicRequestBody.paymentBasicSaleInfo,
      customerName: payment.contactDetailsName,
      subType,
      createdByApp,
    },
    contactInfo: {
      name: payment.contactDetailsName,
      emails: [payment.contactDetailsEmail],
    },
  };
}

function oneToOneBody({ basicRequestBody, sale }) {
  const { subType, createdByApp } = sale;

  return {
    ...basicRequestBody,
    paymentBasicSaleInfo: {
      ...basicRequestBody.paymentBasicSaleInfo,
      subType,
      createdByApp,
    },
  };
}
