import { ValidationErrorEnum } from './ValidationErrorEnum';

import {
  Payments_Definitions_Payments_BankAccountTypeEnum as BankAccountTypeEnumResponse,
  Payments_Definitions_Payments_BankAccountTypeEnumInput as BankAccountTypeEnumInput,
} from 'components/Core/WalletForms/queries/transformed/lib/schema';

export type BankAccountType = BankAccountTypeEnumInput | BankAccountTypeEnumResponse;
export { BankAccountTypeEnumInput };

export enum CardType {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMERICAN_EXPRESS = 'american-express',
  DINERS_CLUB = 'diners-club',
  DISCOVER = 'discover',
  JCB = 'jcb',
  UNIONPAY = 'unionpay',
  MAESTRO = 'maestro',
  ELO = 'elo',
  MIR = 'mir',
  HIPER = 'hiper',
  HIPERCARD = 'hipercard',
}

export type WalletValidatorFunction = (...args: any[]) => ValidationResult;

export type ValidationResult = [boolean, ValidationError[]];

export type ValidationError = ValidationErrorEnum;
