import {
  createSlice,
  SliceCaseReducers,
  ThunkAction,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
  connect as reduxConnect,
} from 'react-redux';
import { Dispatch } from 'redux';

import { RootState } from 'store/store';
import { IBusinessLogic } from 'types/BusinessLogic';

export const sliceFactory = <T, Reducers extends SliceCaseReducers<T>>({
  name,
  initialState,
  reducers,
}: {
  name: string;
  initialState: T;
  reducers: ValidateSliceCaseReducers<T, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers,
  });
};
// Invoice + QBSE= STS
// Invoice + QBMoney = QBO
// Invoice + QBO= QBO
export const getSourceClient = (offeringId: String) => {
  switch (offeringId) {
    case 'QBMoney':
    case 'QBO':
      return 'QBO';
    default:
      break;
  }
  return;
};

export const thunkActionFactory = <T = undefined>(
  callback: ({
    payload,
    dispatch,
    state,
    businessLogic,
  }: {
    payload: T;
    dispatch: Dispatch<any>;
    state: RootState;
    businessLogic: IBusinessLogic;
  }) => void
): ActionCreatorReturnType<T> => {
  // @ts-ignore
  return (payload) => async (dispatch, getState, businessLogic) => {
    return await callback({ payload, dispatch, state: getState(), businessLogic });
  };
};

type ActionCreatorReturnType<T> = T extends undefined
  ? () => ThunkAction<void, RootState, IBusinessLogic, any>
  : (payload: T) => ThunkAction<void, RootState, IBusinessLogic, any>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const connect = (
  mapStateToProps: (state: RootState) => Record<string, any>,
  mapDispatchToProps?: Record<string, any>,
  mergeProps?: (...arg: any) => void
) => {
  // @ts-ignore
  return reduxConnect(mapStateToProps, mapDispatchToProps, mergeProps);
};

// instead of export connect
declare module 'react-redux' {
  export interface DefaultRootState extends RootState {}
}
