import { PayloadAction } from '@reduxjs/toolkit';

import { signInBL, signOutBL, signUpBL } from 'businessLogic/Auth/Auth';
import { insightSelectors } from 'store/insight/selectors';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { Auth, AuthSignedIn } from 'types/Auth';

const initialState: Auth = {} as Auth;

const { actions, reducer } = sliceFactory({
  name: 'auth',
  initialState,
  reducers: {
    signOutFailed(state, action: PayloadAction<{ error?: Auth['authError'] }>) {
      const { error } = action.payload || {};
      if (error) {
        state.authError = error;
      }
    },
    refreshAuthenticationSuccessful(state, action: PayloadAction<{ auth: AuthSignedIn }>) {
      const {
        ticket,
        authLevel,
        realmId,
        recipientEmail,
        isUserSignedIn,
        isEntityPromoted,
        entityId,
        syncToken,
        username,
        authError,
      } = action.payload.auth;

      return {
        ...state,
        ticket,
        authLevel,
        realmId,
        recipientEmail,
        isUserSignedIn,
        isEntityPromoted,
        entityId,
        syncToken,
        username,
        authError,
      };
    },
  },
});

export const authReducers = reducer;
export const authActions = actions;

export const signIn = thunkActionFactory(({ state }) => {
  const {
    auth: { entityId },
    config: { endpoints },
  } = state;
  state.auth.entityId;
  signInBL({ entityId, endpoints });
});

export const signUp = thunkActionFactory(({ state }) => {
  const {
    auth: { entityId },
    config: { endpoints },
  } = state;
  signUpBL({ entityId, endpoints });
});

export const signOut = thunkActionFactory(async ({ dispatch, state }) => {
  let token;
  const {
    insight,
    config: { ssrtid, portal },
  } = state;

  token = insightSelectors.tokenSelector(insight);

  try {
    await signOutBL({ ssrtid, portal, token });
  } catch (error: any) {
    dispatch(authActions.signOutFailed({ error }));
  }
});
