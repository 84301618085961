import { Method } from 'axios';

import { walletEntryDTO } from './helpers';

import { getHeadersForSSRRequest } from 'businessLogic/utils';
import HttpClient from 'server/helpers/HttpClient';
import { insightSelectors } from 'store/insight/selectors';
import { RootState } from 'store/store';
import { UserWallet, WalletItemType } from 'types/Wallet';
import { CardType } from 'types/constants';

const httpClient = HttpClient.getInstance();
type RequestOptions =
  | {
      card: {
        address: { addressComponents: Array<{ name: string; value: string }> };
        cvc: string;
        default: false;
        expMonth: string;
        expYear: string;
        isSavePaymentMethodChecked: false;
        name: string;
        number: number;
        cardType: CardType;
        savePaymentMethod?: boolean;
      };
      itemType: WalletItemType;
    }
  | {
      bankAccount: {
        address: { addressComponents: Array<{ name: string; value: string }> };
        accountNumber: string;
        accountNumberConfirm: string;
        accountType: string;
        bankCode: string;
        branchCode?: string;
        country?: string;
        name: string;
        phone: string;
      };
      itemType: WalletItemType;
      multiUse?: boolean;
    }
  | {};
type walletHttpRequestOptions = {
  reqType: string;
  endpoint: string;
  method: Method;
  data?: RequestOptions;
  store: RootState;
};
const baseUrl = 'rest/wallets';

export default class Wallet {
  constructor() {}

  private async walletSSRRequest({ endpoint, method, data = {}, store }: walletHttpRequestOptions) {
    const headers = getHeadersForSSRRequest(store, {
      Accept: 'application/json, text/javascript, */*; q=0.01',
    });
    const {
      insight,
      config: { portal, ssrtid },
    } = store;
    const token = insightSelectors.tokenSelector(insight) as string;

    const ssrWalletsEndpoint = `/${portal}/${endpoint}`;

    const response = await httpClient({
      url: `${ssrWalletsEndpoint}/`,
      method,
      headers,
      endpoint: ssrWalletsEndpoint,
      ssrtid,
      token,
      data,
      event: 'wallet',
    });
    return response.data;
  }

  private async walletApiRequest({ method, data, store, endpoint }: walletHttpRequestOptions) {
    const {
      insight,
      config: { ssrtid, endpoints, credentials },
    } = store;
    const token = insightSelectors.tokenSelector(insight) as string;
    const walletApiEndpoint = `${endpoints.wallet.rest2}/${endpoint}`;

    const response = await httpClient({
      url: walletApiEndpoint,
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        intuit_apikey: credentials.browserApiKey,
      },
      endpoint: walletApiEndpoint,
      ssrtid,
      token,
      data,
      withCredentials: false, // To prevent a 401 error for a non logged-in user that has some user cookies (was logged-in but then logged-out)
      event: 'wallet',
    });
    return response.data;
  }

  async fetchUserWallets(store: RootState) {
    return await this.walletSSRRequest({
      reqType: 'fetchUserWallets',
      endpoint: baseUrl,
      method: 'GET',
      store,
    });
  }

  async updateUserWallet(store: RootState, userWallet: UserWallet) {
    const { id, walletType } = userWallet;

    const walletUpdateRes = await this.walletSSRRequest({
      reqType: 'updateUserWallet',
      endpoint: `${baseUrl}/${walletType}/${id}`,
      method: 'PATCH',
      data: {
        walletUpdatedProps: userWallet,
      },
      store,
    });

    return walletUpdateRes;
  }

  async deleteUserWallet(store: RootState, userWallet: Partial<UserWallet>) {
    const { id, walletType } = userWallet;

    const walletDeleteRes = await this.walletSSRRequest({
      reqType: 'deleteUserWallet',
      endpoint: `${baseUrl}/${walletType}/${id}`,
      method: 'DELETE',
      store,
    });

    return walletDeleteRes;
  }

  async createShortLivedToken(
    store: RootState,
    paymentData: {
      ccDetails?: {
        number: string;
        cardType: string;
        name: string;
        expYear: string;
        expMonth: string;
        address: {
          postalCode: string;
        };
        cvc: string;
        default: boolean;
      };
      bankDetails?: {
        accountNumber: string;
        accountNumberConfirm?: string;
        accountType: string;
        bankCode?: string;
        name: string;
        phone: string;
        itemType: string;
        institutionNumber?: string;
        transitNumber?: string;
      };
      paymentMethodType?: string;
    } = {}
  ) {
    const { ccDetails, paymentMethodType } = paymentData;
    let { bankDetails } = paymentData;
    if (paymentMethodType === 'eft') {
      bankDetails = walletEntryDTO.convertFromCPFormat.covertToEftWalletRequestFormat(bankDetails);
    }
    let tokenizationPayload;
    tokenizationPayload = {
      itemType: ccDetails ? 'CARD' : 'BANK_ACCOUNT',
      ...(ccDetails && { card: ccDetails }),
      ...(bankDetails && { bankAccount: bankDetails }),
      ...(paymentMethodType === 'eft' && { multiUse: true }),
    };

    tokenizationPayload =
      walletEntryDTO.convertFromCPFormat.addAddressComponentForRest2Tokenization(
        tokenizationPayload
      );

    const { id } = await this.walletApiRequest({
      reqType: `token_${tokenizationPayload.itemType}`,
      endpoint: `tokens`,
      method: 'POST',
      store,
      data: tokenizationPayload,
    });

    return { token: id };
  }
}
