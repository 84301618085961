import React, { Fragment } from 'react';

import Spinner, { Props } from 'components/Shared/Spinner/Spinner';

const SpinnerViewer = (props?: Props) => {
  return (
    <Fragment>
      <style jsx>{`
        .w {
          width: 100%;
          height: 100%;
          position: relative;
          text-align: center;
        }
      `}</style>

      <div className="w">
        <Spinner {...props} />
      </div>
    </Fragment>
  );
};

export default SpinnerViewer;
