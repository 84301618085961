import { sliceFactory } from 'store/utils';
import { Config } from 'types/Config';

const initialState: Partial<Config> = {};

const { reducer } = sliceFactory({
  name: 'config',
  initialState,
  reducers: {},
});

export const configReducer = reducer;
