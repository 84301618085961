import { PayloadAction } from '@reduxjs/toolkit';

import { sliceFactory, thunkActionFactory } from 'store/utils';
import { IXP, IXPExperiment } from 'types/IXP';

const initialState: Partial<IXP> = {};

const { reducer, actions } = sliceFactory({
  name: 'ixp',
  initialState,
  reducers: {
    fetchExperiments(
      state,
      action: PayloadAction<{ experiments: { [key: string]: IXPExperiment } }>
    ) {
      const { experiments } = action.payload;
      for (const experimentKey in experiments) {
        state[experimentKey] = experiments[experimentKey];
      }
    },
  },
});

export const ixpReducer = reducer;
export const ixpActions = actions;

export const fetchExperiments = thunkActionFactory<{
  experimentIdsPreprod: number[];
  experimentIdsProd: number[];
}>(async ({ payload, dispatch, state, businessLogic }) => {
  let experiments = await businessLogic.ixp.fetchExperiments(state, payload);

  if (experiments) {
    dispatch(ixpActions.fetchExperiments({ experiments }));
  }
});
