import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import InvoicePDFObject from 'components/Core/Invoice/PdfObject/InvoicePDFObject';
import { ErrorInfo } from 'components/Shared/Icons/Icons';
import Spinner from 'components/Shared/Spinner/Spinner';
import { fontSize, colors } from 'styles/cp';
import { InvoiceDocument } from 'types/InvoiceDocument';
import { Sale } from 'types/Sale';
import { TXN_MAP } from 'types/constants';

export type Props = {
  invoiceDocument: Pick<InvoiceDocument, 'fetchPDFStatus' | 'PDFDocumentBlobUrl'>;
  sale: Pick<Sale, 'type'>;
};

export class InvoiceViewer extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {
      invoiceDocument: { fetchPDFStatus, PDFDocumentBlobUrl },
      sale: { type },
    } = this.props;
    let documentType: typeof TXN_MAP.TYPES.INVOICE | typeof TXN_MAP.TYPES.ESTIMATE =
      TXN_MAP.TYPES.INVOICE;
    if (type === TXN_MAP.TYPES.ESTIMATE) {
      documentType = TXN_MAP.TYPES.ESTIMATE;
    }
    return (
      <Fragment>
        <style jsx>{`
          .w {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-direction: column;

            .p {
              margin: 0 auto;
              display: block;
              ${!(fetchPDFStatus === TXN_MAP.STATUS.SUCCESS) && 'position: absolute;'};
              visibility: ${fetchPDFStatus === TXN_MAP.STATUS.SUCCESS ? 'visible;' : 'hidden;'};
              border: none;
            }
          }

          .error-container {
            .error-icon {
              text-align: center;
            }
            .error-message {
              color: ${colors.white};
              font-size: ${fontSize.lg};
            }
          }

          .actions {
            display: flex;
            justify-content: space-between;
            margin-top: 35px;
            max-width: 440px;
            padding: 0 60px;
            margin-left: 220px;

            button {
              background: none;
              border: none;
              padding: 0;
              font-size: ${fontSize.xs};
              color: white;
              cursor: pointer;

              span {
                margin-left: 5px;
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
        `}</style>

        <div className="w">
          {fetchPDFStatus === TXN_MAP.STATUS.IN_PROGRESS && (
            <Spinner
              label="Processing"
              strokeWidth={1.5}
              bgColor={colors.transparent}
              aria-label="Processing"
            />
          )}

          {fetchPDFStatus === TXN_MAP.STATUS.SUCCESS && PDFDocumentBlobUrl && (
            <InvoicePDFObject
              title="Invoice PDF"
              className="p fade-in"
              pdfUrl={PDFDocumentBlobUrl}
              width="850"
              height="1150"
            />
          )}

          {fetchPDFStatus === TXN_MAP.STATUS.ERROR && (
            <div className="error-container">
              <div className="error-icon">
                <ErrorInfo />
              </div>
              <div className="error-message">
                {documentType === TXN_MAP.TYPES.INVOICE ? (
                  <FormattedMessage
                    id="ERROR_INVOICE_UNAVAILABLE"
                    defaultMessage="We couldn't load the invoice"
                  />
                ) : (
                  <FormattedMessage
                    id="ERROR_ESTIMATE_UNAVAILABLE"
                    defaultMessage="We couldn't load the estimate"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state: Props) {
  return {
    invoiceDocument: state.invoiceDocument,
    sale: state.sale,
  };
}

export default connect(mapStateToProps)(InvoiceViewer);
