import { RawAxiosRequestHeaders } from 'axios';

import {
  ApplePayInfo,
  ApplePayRequest,
  getAppPayloadValue,
  applePayConfig,
  paymentBasicSaleInfoType,
  saleInfo,
  paymentRequest,
} from './ApplePayTypes';

import SegmentIO from 'reporting/SegmentIO';
import HttpClient from 'server/helpers/HttpClient';
import convertLocaleToCountry from 'server/helpers/convertLocaleToCountry';
import { ReportParams, ILogInfo } from 'types/SplunkLogs';
import { SALE_MAP } from 'types/constants';

const httpClient = HttpClient.getInstance();
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Payment/ApplePay/ApplePayPayment';
const logError = ({
  logLevel,
  action,
  error,
  message,
}: {
  logLevel: ILogInfo['logLevel'];
  action: ReportParams['action'];
  error?: any;
  message?: string;
}) => {
  const data: ReportParams = {
    logInfo: { logLevel, logger },
    event: 'pay',
    action,
    activityInfo: {
      message,
    },
  };
  if (error) {
    data.error = {
      stack: error.stack,
      message: error.message,
    };
  }
  splunkReporter.contextual(data);
};
export const isApplePayEnabled = async ({
  enabledPaymentMethods,
  locale,
  applePayConfig,
}: {
  enabledPaymentMethods: string[];
  locale: string;
  applePayConfig: applePayConfig;
}): Promise<boolean> => {
  try {
    const { countryConfig = [] } = applePayConfig;
    if (!window.ApplePaySession) {
      return false;
    }

    if (
      !enabledPaymentMethods ||
      !enabledPaymentMethods.find((enabledPaymentMethod) => enabledPaymentMethod === 'cc')
    ) {
      logError({ logLevel: 'warn', action: 'checkIsEnabled', message: 'cc is not enabled' });
      return false;
    }
    if (
      !(await window.ApplePaySession.canMakePaymentsWithActiveCard(
        applePayConfig.applePayPaymentProcessorId
      ))
    ) {
      logError({
        logLevel: 'warn',
        action: 'checkIsEnabled',
        message: 'canMakePaymentsWithActiveCard returned false',
      });
      return false;
    }

    const convertedLocaleToCountry = convertLocaleToCountry(locale);
    const foundCountryConfig = countryConfig.find(
      (countryObj) => countryObj.country === convertedLocaleToCountry
    );
    if (!foundCountryConfig) {
      logError({
        logLevel: 'warn',
        action: 'checkIsEnabled',
        message: 'countryConfig not found',
      });
      return false;
    }
    return true;
  } catch (error) {
    logError({ logLevel: 'error', action: 'checkIsEnabled', error });
    return false;
  }
};
export const makeApplePayPayment = ({
  paymentRequest,
  applePaySupportedCardsInLocale,
  portal,
  headers,
}: {
  paymentRequest: paymentRequest;
  applePaySupportedCardsInLocale?: string[];
  portal: string | undefined;
  headers: RawAxiosRequestHeaders;
}): Promise<ApplePayRequest> => {
  SegmentIO.transactionSubmitted();
  return new Promise((resolve, reject): void => {
    try {
      const APPLE_PAY_API_VERSION = 1;
      const lineItem: ApplePayJS.ApplePayLineItem = {
        label: paymentRequest.companyName,
        amount: paymentRequest.amount.toString(),
      };
      const paymentReq: ApplePayRequest = { status: SALE_MAP.STATUS.PENDING, payment: {} };
      const request: ApplePayJS.ApplePayPaymentRequest = {
        countryCode: paymentRequest.countryCode,
        currencyCode: paymentRequest.currencyCode,
        supportedNetworks: intersection(
          paymentRequest.supportedNetworks,
          applePaySupportedCardsInLocale
        ),
        merchantCapabilities: ['supports3DS'],
        total: lineItem,
      };
      const applePaySession: ApplePaySession = new ApplePaySession(APPLE_PAY_API_VERSION, request);
      applePaySession.onvalidatemerchant = async (
        event: ApplePayJS.ApplePayValidateMerchantEvent
      ) => {
        try {
          const response = await validateMerchant(event.validationURL, portal, headers);
          const { data } = response;
          const merchantSession: string = data.merchantSessionJSON
            ? JSON.parse(data.merchantSessionJSON)
            : data;
          applePaySession.completeMerchantValidation(merchantSession);
        } catch (e) {
          try {
            applePaySession.abort();
          } catch (applePaySessionError) {
            logError({ logLevel: 'error', action: 'abort', error: applePaySessionError });
          }
          reject(e);
        }
      };
      applePaySession.onpaymentauthorized = (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
        try {
          applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
          paymentReq.payment = event.payment;
          paymentReq.status = 'SUCCESS';
          resolve(paymentReq);
        } catch (error) {
          logError({ logLevel: 'error', action: 'onpaymentauthorized', error });
          reject(error);
        }
      };

      applePaySession.oncancel = () => {
        try {
          paymentReq.status = 'CANCELED';
          resolve(paymentReq);
        } catch (error) {
          logError({ logLevel: 'error', action: 'oncancel', error });
          reject(error);
        }
      };
      try {
        applePaySession.begin();
      } catch (error) {
        logError({ logLevel: 'error', action: 'begin', error });
        reject(error);
      }
    } catch (error) {
      logError({ logLevel: 'error', action: 'submit', error });
      reject(error);
    }
  });
};

export const getAPPayload = (
  baseRequestBody: { paymentBasicSaleInfo: paymentBasicSaleInfoType; saleInfo: saleInfo },
  applePayInfo: ApplePayInfo,
  isGpu?: boolean
): getAppPayloadValue => {
  if (typeof isGpu !== 'undefined') {
    baseRequestBody.paymentBasicSaleInfo = {
      ...baseRequestBody.paymentBasicSaleInfo,
      isGpu,
    };
  }
  const data: getAppPayloadValue = {
    ...baseRequestBody,
    applePayInfo,
  };
  return data;
};

export const getMaskedAPPayment = (applePayInfo: ApplePayInfo): string => {
  const {
    token: {
      paymentMethod: { displayName },
    },
  } = applePayInfo;
  return `Apple Pay ${displayName}`;
};
export const validateMerchant = async (
  validationURL: string,
  portal: string | undefined,
  headers: RawAxiosRequestHeaders
) => {
  const endpoint = `/${portal}/rest/applepay/validateMerchant`;
  return await httpClient({
    url: endpoint,
    method: 'POST',
    headers,
    endpoint,
    data: { validationURL },
  });
};
export const intersection = (arrayA: string[], arrayB?: string[]): string[] => {
  const setA = new Set(arrayA);
  const setB = new Set(arrayB);
  return [...setA].filter((item) => setB.has(item));
};
export const getCommonErrorDetails = (
  window: Window
): { EventName: string; Origin: string; Protocol: string } => ({
  EventName: 'CPV2ApplePayErrorBL',
  Origin: window.location && window.location.origin,
  Protocol: window.location && window.location.protocol,
});
