import React from 'react';

type Props = {};

/**
 * Add watchers for your redux store domains here
 *
 * If module A is dependant on module B's redux properties
 * You can add a special watcher to call module A's action when module B property is changed
 */
const ReduxSubscribers: React.FC<Props> = () => {
  return <></>;
};

export default ReduxSubscribers;
