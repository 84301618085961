import { FeatureFlags } from 'types/FeatureFlags';
import { LoggerFunction } from 'types/Logger';
// Used to log missing riskProfileTokens for a fraction of requests.

export const logIfMissingriskProfileToken = ({
  riskProfileToken,
  featureFlags = {
    'log-missing-bfs-assessment-token': true,
  },
  ssrtid,
  token,
  where,
  Logger,
}: {
  riskProfileToken?: string;
  ssrtid: string;
  featureFlags?: FeatureFlags;
  token: string;
  where: string;
  Logger: LoggerFunction;
}) => {
  if (featureFlags && featureFlags['log-missing-bfs-assessment-token'] && !riskProfileToken) {
    Logger({
      EventName: `missing riskProfileToken`,
      EventType: 'bfs',
      where,
      ssrtid,
      token,
    });
  }
};
