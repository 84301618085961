import React, { Fragment } from 'react';

import { breakpoints } from 'styles/cp';

export interface ModalProps {
  children: any;
  removeDefaultBackground?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, removeDefaultBackground }) => {
  return (
    <Fragment>
      <div className="overlay" role="dialog" data-testid="test-modal-dialog">
        <div className="modal">{children}</div>
      </div>
      {/* language=scss */}
      <style jsx>{`
        .overlay {
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${removeDefaultBackground ? 'unset' : 'rgba(0, 0, 0, 0.5)'};
          z-index: 2;
          @media screen and (max-width: ${breakpoints.sm}) {
            padding: 12px 2px 32px;
          }

          .modal {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-direction: column;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default Modal;
