import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const Chevron: React.FC<IconBasePropsWithColor> = ({
  width = 15,
  height = 8,
  color = '#8D9096',
  verticalAlign = 'middle',
  rotate = -180,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign }}
      aria-label="Chevron"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="icons-20-2-icon-schevron-down"
          transform={`translate(7.500000, 4.000000) rotate(${rotate}) translate(-7.500000, -4.000000) translate(-0.000000, 0.000000)`}
          fill={color}
        >
          <path
            d="M13.927,0.595 C14.2256709,0.9383763 14.1898753,1.45875414 13.847,1.758 L8.055,6.825 C7.92484569,7.12400763 7.61904913,7.30724446 7.294,7.281 C7.04580227,7.29824097 6.80488138,7.19290812 6.649,6.999 C6.60606998,6.94632168 6.56979652,6.88855284 6.541,6.827 L0.747,1.757 C0.413317458,1.46506564 0.37003711,0.96160037 0.649,0.617 C0.779724845,0.455576452 0.970541729,0.354338944 1.17750079,0.336604986 C1.38445985,0.318871028 1.58971829,0.386169538 1.746,0.523 L7.297,5.377 L12.849,0.523 C13.1665715,0.245223748 13.64919,0.277458009 13.927,0.595 L13.927,0.595 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Chevron;
