import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const FaceNeutral: React.FC<IconBasePropsWithColor> = ({
  width = 40,
  height = 40,
  color = colors.gray,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36ZM40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 27C11.5 26.1716 12.1716 25.5 13 25.5H27.4C28.2284 25.5 28.9 26.1716 28.9 27C28.9 27.8284 28.2284 28.5 27.4 28.5H13C12.1716 28.5 11.5 27.8284 11.5 27Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13.5C14.3807 13.5 15.5 14.6193 15.5 16V16.2C15.5 17.5807 14.3807 18.7 13 18.7C11.6193 18.7 10.5 17.5807 10.5 16.2V16C10.5 14.6193 11.6193 13.5 13 13.5ZM27 13.5C28.3807 13.5 29.5 14.6193 29.5 16V16.2C29.5 17.5807 28.3807 18.7 27 18.7C25.6193 18.7 24.5 17.5807 24.5 16.2V16C24.5 14.6193 25.6193 13.5 27 13.5Z"
        fill={color}
      />
    </svg>
  );
};

export default FaceNeutral;
