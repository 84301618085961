import { sliceFactory } from 'store/utils';
import { I18n } from 'types/i18n';

const initialState: Partial<I18n> = {};

const { reducer } = sliceFactory({
  name: 'i18n',
  initialState,
  reducers: {},
});

export const i18nReducer = reducer;
