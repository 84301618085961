import {
  BankAccountTypeEnumInput,
  CardType,
} from 'components/Core/WalletForms/validations/src/types';
import debugCreator from 'debug';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'store/wallet/helpers';
const debug = debugCreator('wallet/helpers');

const logError = ({ e, action }) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'error', logger },
    event: 'wallet',
    action,
    activityInfo: {
      status: 'error',
    },
    error: {
      message: e?.message,
      stack: e?.stack,
    },
  });
};
export const accountTypeTranslator = {
  fromWalletToCpServer: (accountType) => {
    switch (accountType) {
      case BankAccountTypeEnumInput.PERSONAL_CHECKING:
      case 'personal checking':
        return 'personal checking';

      case BankAccountTypeEnumInput.PERSONAL_SAVINGS:
      case 'personal savings':
        return 'personal savings';

      case BankAccountTypeEnumInput.BUSINESS_CHECKING:
      case 'business checking':
        return 'business checking';

      case BankAccountTypeEnumInput.BUSINESS_SAVINGS:
      case 'business savings':
        return 'business savings';

      default:
        throw Error('Unknown account type');
    }
  },
  fromCpServerToWallet: (accountType) => {
    switch (accountType) {
      case 'personal checking':
      case BankAccountTypeEnumInput.PERSONAL_CHECKING:
        return BankAccountTypeEnumInput.PERSONAL_CHECKING;

      case 'personal savings':
      case BankAccountTypeEnumInput.PERSONAL_SAVINGS:
        return BankAccountTypeEnumInput.PERSONAL_SAVINGS;

      case 'business checking':
      case BankAccountTypeEnumInput.BUSINESS_CHECKING:
        return BankAccountTypeEnumInput.BUSINESS_CHECKING;

      case 'business savings':
      case BankAccountTypeEnumInput.BUSINESS_SAVINGS:
        return BankAccountTypeEnumInput.BUSINESS_SAVINGS;

      default:
        throw Error('Unknown account type');
    }
  },
};

export const CpServerWalletHelpers = {
  translateCardTypeToCpServer: (cardType) => {
    if (typeof cardType !== 'string') {
      throw Error('Invalid cardType type');
    }

    switch (cardType.toLowerCase()) {
      case CardType.AMERICAN_EXPRESS:
      case 'amex':
      case 'american express':
      case 'american-express':
        return 'American Express';

      case CardType.VISA:
      case 'visa':
        return 'Visa';

      case CardType.MASTERCARD:
      case 'mastercard':
        return 'MasterCard';

      case CardType.DISCOVER:
      case 'discover':
        return 'Discover';

      case CardType.JCB:
      case 'jcb':
        return 'JCB';

      case CardType.DINERS_CLUB:
      case 'diners':
        return 'Diners';

      default:
        throw Error('Unknown Card Type');
    }
  },
};

export const cardHelpers = {
  formatExpirationDateFromEvent(e) {
    debug('formatExpirationDateFromEvent');
    if (typeof e !== 'object' || e === null) {
      throw Error('Expected type of event');
    }
    let value = e.target.value;
    if (e.nativeEvent.type !== 'deleteContentBackward') {
      value = this.formatExpirationDate(value);
    }
    return value;
  },

  formatExpirationDate(str) {
    debug('formatExpirationDate');
    if (typeof str !== 'string') {
      throw Error('Expected str type of string');
    }
    const numbers = str
      .split('/')
      .filter((x) => x !== '/')
      .join('');
    const hasSlash = numbers.length !== str.length;
    if (numbers.length < 3) {
      str = numbers;
      if (hasSlash) {
        str += '/';
      }
    } else {
      const month = numbers.slice(0, 2);
      let year = numbers.slice(2, 6);
      str = `${month}/${year}`;
    }
    return str;
  },

  formatCardNumber(str, isAmex = false) {
    const _str = this.getRawCardNumber(str);
    let res = '';
    for (let i = 0; i < str.length; i++) {
      let _putSpaceForAmex = isAmex && (i === 4 || i === 10);
      let _putSpaceForNonAmex = !isAmex && i % 4 === 0 && i !== 0;
      if (_putSpaceForAmex || _putSpaceForNonAmex) {
        res += ' ';
      }
      res += str[i];
    }
    return res;
  },

  getRawCardNumber(str) {
    return str.split(' ').join('');
  },
};

export const creditCardHelpers = {
  //initialize data variable
  initializing() {
    let data = {
      tmpCursurLocation: 0,
      isKeyPressed: false,
      isFormated: false,
      isChanged: false,
    };

    return data;
  },

  onKeyDown: (_field, data = {}) => {
    return (e) => {
      try {
        //handling the case when no onSelect calling - when cursor stays in the same location after deletion
        if (e.key === 'Backspace' && e.target.selectionStart === e.target.value.length - 1) {
          e.preventDefault();
          data['tmpCursurLocation'] = e.target.selectionStart;
          e.target.value =
            e.target.value.slice(0, data['tmpCursurLocation'] - 1) +
            e.target.value.slice(data['tmpCursurLocation']);
          data['tmpCursurLocation']--;
          e.target.selectionStart = data['tmpCursurLocation'];
          e.target.selectionEnd = data['tmpCursurLocation'];
        }

        //reset isKeyPressed variable
        data['isKeyPressed'] = false;
      } catch (error) {
        logError({ e: error, action: 'onKeyDown' });
        return false;
      }
    };
  },

  onKeyPress: (_field, data = {}) => {
    return (e) => {
      try {
        //if key pressed is valid key (digit value)
        if (e.charCode >= 48 && e.charCode <= 57 && e.target.value.length < e.target.maxLength) {
          data['isKeyPressed'] = true;
        }
      } catch (error) {
        logError({ e, action: 'onKeyPress' });
        return false;
      }
    };
  },

  onSelect: (_field, data = {}) => {
    return (e) => {
      try {
        //unselect space areas
        if (
          e.target.selectionEnd - e.target.selectionStart === 1 &&
          e.target.value[e.target.selectionStart] === ' '
        ) {
          e.target.selectionEnd = e.target.selectionStart;
        }

        //updating value length when recognize amex card.
        if (e.target.maxLength < e.target.value.length) {
          e.target.value = e.target.value.slice(0, e.target.maxLength);
        }

        //updating cursor location after card number format
        if (data['isFormated']) {
          data['isFormated'] = false;
          if (data['isChanged']) {
            e.target.selectionStart = data['tmpCursurLocation'];
            e.target.selectionEnd = data['tmpCursurLocation'];
            data['isChanged'] = false;
          }
        }
      } catch (error) {
        logError({ e, action: 'onSelect' });
        return false;
      }
    };
  },

  onChange: (data = {}, e) => {
    try {
      //save current location before change value to raw.
      data['tmpCursurLocation'] = e.target.selectionStart;
      data['isChanged'] = true;
    } catch (error) {
      return false;
    }
  },

  formatCreditCard: (value, isAmex, data = {}) => {
    try {
      data['isFormated'] = true;
      let formatedCardNumber = cardHelpers.formatCardNumber(value, isAmex);

      //jump when cursor on space
      if (data['isKeyPressed'] && formatedCardNumber[data['tmpCursurLocation'] - 1] === ' ') {
        data['tmpCursurLocation']++;
        data['isKeyPressed'] = false;
      }
      return formatedCardNumber;
    } catch (e) {
      logError({ e, action: 'formatCreditCard' });
      return value;
    }
  },
};

/**
 * Returns default wallet from a list of wallets.
 * @param {Array<Wallet>} list
 * @returns
 */
export const getDefaultWalletFromList = (list = []) => {
  if (list.length === 0) {
    return null;
  }

  let defaultWallet = list[0];

  if (list.length > 1) {
    defaultWallet = list.find((wallet) => wallet.default) || defaultWallet;
  }

  return defaultWallet;
};

export const getUserWalletByEntryId = (userWallets, walletId) => {
  return userWallets && userWallets.find((wallet) => wallet.id === walletId);
};
