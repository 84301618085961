export enum ValidationErrorEnum {
  STRING_ERROR_MESSAGE,
  INVALID_OBJECT,
  INVALID_ACCOUNT_NUMBER,
  ACCOUNT_CONFIRMATION_MISMATCH,
  INVALID_ACCOUNT_TYPE,
  INVALID_ROUTING_NUMBER,
  INVALID_FIRST_AND_LAST_NAME,
  INVALID_PHONE_NUMBER,
  INVALID_PARENT_INFO_OBJECT,
  INVALID_PARENT_ID,
  INVALID_PARENT_TYPE,
  INVALID_BANK_IDENTIFIER,
  REQUIRED_ERROR_MESSAGE,
  INVALID_CARD_NUMBER,
  INVALID_EXP_DATE,
  INVALID_EXP_MONTH,
  WARNING_INVALID_MONTH_IN_CURRENT_YEAR,
  INVALID_EXP_YEAR,
  WARNING_EXPIRATION_IN_CURRENT_YEAR,
  INVALID_ZIP_CODE,
  INVALID_CVC,
  INVALID_CVC_AMEX,
  INVALID_CVC_NON_AMEX,
  INVALID_CARD_TYPE,
  INVALID_TRANSIT_NUMBER,
  INVALID_INSTITUTION_NUMBER,
}
