import { AxiosResponse } from 'axios';

import { GetSurveySettingResponseData } from 'businessLogic/Mailchimp/Messaging';
import { SurveySettingsType } from 'server/services/mailchimp/MessagingService';

export function getSurveySettingsMap(
  resp: AxiosResponse<any, any>
): Record<SurveySettingsType, boolean> {
  return (
    resp?.data &&
    (resp.data as Array<GetSurveySettingResponseData>).reduce((acc, curr) => {
      return {
        ...acc,
        [curr.settingsKey]: curr.settingsValue.toLocaleUpperCase() === 'TRUE' ? true : false,
      };
    }, {})
  );
}
