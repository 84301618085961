import React, { Component, Fragment } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import type { Config } from 'types/Config';

import SpinnerViewer from 'components/Core/Header/SpinnerViewer/SpinnerViewer';
import IconUser from 'components/Shared/Icons/IconUser';
import { SignOut, Gear } from 'components/Shared/Icons/Icons';
import InfoCard from 'components/Shared/InfoCard/InfoCard';
import CpPopover from 'components/Shared/Popover/CpPopover';
import PopoverMenuWrapper from 'components/Shared/PopoverMenuWrapper/PopoverMenuWrapper';
import { isSafari, isMobile, isFireFox, isEdge } from 'server/helpers/deviceInfo';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { popoverStyles } from 'styles/popover';
import { Auth } from 'types/Auth';
import { Modal } from 'types/Modal';

export type UserBarProps = {
  config: Pick<Config, 'endpoints'>;
  auth: Pick<Auth, 'isUserSignedIn' | 'username'>;
  signOut: () => void;
  signIn: () => void;
  showModal: (payload: Pick<Modal, 'component' | 'componentProps'>, state?: Partial<Modal>) => void;
  showToast: ({ text }: { text: string }) => void;
  intl: IntlShape;
};
type userMenuProps = {
  username: string;
  isLinkToDG2Enabled: boolean;
  signOutClicked: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  intuitAccountManagerClicked: (event: React.SyntheticEvent<HTMLDivElement>) => void;
};
type UserBarState = {
  isMenuOpen: boolean;
};
export const UserMenu = ({
  username,
  isLinkToDG2Enabled,
  signOutClicked,
  intuitAccountManagerClicked,
}: userMenuProps): JSX.Element => {
  return (
    <div className="user-menu">
      <PopoverMenuWrapper>
        <div className="user-menu-email">
          <span>
            <div>
              <a>{'Signed as'}</a>
            </div>
            <div>
              <a className="user-menu-email address" id="docViewHeader.header.signedIn">
                {username}
              </a>
            </div>
          </span>
        </div>
        <div className="button-list">
          {isLinkToDG2Enabled && (
            <div
              className="list-item"
              tabIndex={2}
              onTouchStart={intuitAccountManagerClicked}
              onClick={intuitAccountManagerClicked}
              onKeyDown={intuitAccountManagerClicked}
              role="button"
            >
              <span className="content">
                <span className="gear-icon">
                  <Gear />
                </span>
                <FormattedMessage id="INTUIT_ACCOUNT" defaultMessage="Intuit Account" />
              </span>
            </div>
          )}
          <div
            className="list-item"
            tabIndex={3}
            onTouchStart={signOutClicked}
            onClick={signOutClicked}
            onKeyDown={signOutClicked}
            data-testid="sign-out-link"
          >
            <span className="content">
              <span className="sign-out-icon">
                <SignOut />
              </span>
              <FormattedMessage id="HEADER_SIGN_OUT" defaultMessage="Sign out" />
            </span>
          </div>
        </div>
      </PopoverMenuWrapper>
      <style jsx>{`
        .user-menu {
          font-size: ${fontSize.xs};

          .button-list {
            margin: 20px 0;

            .list-item {
              min-width: 200px;
              border-left: 3px solid transparent;
              cursor: pointer;

              .gear-icon {
                padding: 0px 16px 0px 3px;
                :global(path) {
                  stroke: ${colors.gray};
                }
              }

              .sign-out-icon {
                padding: 2px 16px 0px 6px;
                :global(path) {
                  fill: ${colors.gray};
                }
              }

              &:hover,
              &:focus {
                font-family: AvenirNextforINTUIT-Medium;
                border-style: solid;
                border-width: 0 0 0 3px;
                border-image: linear-gradient(262deg, ${colors.blue02}, ${colors.green02}) 1 90%;
                -webkit-border-image: linear-gradient(262deg, ${colors.blue02}, ${colors.green02}) 1
                  90%;

                .gear-icon {
                  :global(path) {
                    stroke: ${colors.gray};
                  }
                }
                .sign-out-icon {
                  :global(path) {
                    fill: ${colors.gray};
                  }
                }
              }
              .content {
                display: flex;
                margin: 9px 17px 0 17px;
              }
            }
          }
        }
        .user-menu-email {
          color: ${colors.gray};
          display: flex;
          align-items: center;
          margin: 20px 20px 10px;
          border-bottom: 1px solid ${popoverStyles.menuItemDividerBorderColor};
          font-family: AvenirNextforINTUIT-Medium;

          .address {
            border-bottom: none;
            color: ${colors.gray};
            font-family: AvenirNextforINTUIT-Regular;
            margin: 4px 0 10px 0;

            @media (max-width: ${breakpoints.sm}) {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: inline-block;
              max-width: 75vw;
            }
          }
        }
      `}</style>
    </div>
  );
};
class UserBar extends Component<UserBarProps, UserBarState> {
  constructor(props: UserBarProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }
  componentDidMount() {
    const { referrer } = window.document;
    // Show successful sign in message if we come from sign in page
    if (
      this.props.auth.isUserSignedIn &&
      referrer.indexOf('intuit.com/signin') > -1 &&
      localStorage.getItem('from_sign_in') === 'true'
    ) {
      const id = 'TOASTER_SIGN_IN_SUCCESS';
      const text = this.props.intl.formatMessage({
        id,
        defaultMessage: "Great! You're now signed in.",
      });
      this.props.showToast({ text });
      localStorage.removeItem('from_sign_in');
    }
  }
  hideMenu = () => {
    this.setState(
      {
        isMenuOpen: false,
      },
      () => {
        window.removeEventListener('click', this.hideMenu);
      }
    );
  };
  toggleMenu = () => {
    this.setState(
      {
        isMenuOpen: !this.state.isMenuOpen,
      },
      () => {
        if (this.state.isMenuOpen) {
          setTimeout(() => window.addEventListener('click', this.hideMenu), 0);
        }
      }
    );
  };
  signInClicked = () => {
    this.props.signIn();
  };
  signOutClicked = (event: React.SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.hideMenu();
    this.props.showModal({
      component: SpinnerViewer,
      componentProps: {},
    });
    this.props.signOut();
  };
  intuitAccountManagerClicked = (event: React.SyntheticEvent<HTMLDivElement>) => {
    const {
      config: {
        endpoints: { iamAccountManagerUrl: intuitAccountManagerUrl },
      },
    } = this.props;
    const {
      navigator: { userAgent },
    } = window;
    event.preventDefault();
    event.stopPropagation();
    if (isMobile(userAgent) && (isSafari(userAgent) || isFireFox(userAgent) || isEdge(userAgent))) {
      window.location.replace(intuitAccountManagerUrl);
    } else {
      window.open(intuitAccountManagerUrl, '_blank');
    }
    this.hideMenu();
    // Maybe log something here.
  };
  render() {
    const {
      auth: { isUserSignedIn, username },
      config: { endpoints },
    } = this.props;
    const { isMenuOpen } = this.state;
    const isLinkToDG2Enabled = isUserSignedIn && !!endpoints.iamAccountManagerUrl;
    const userMenuProps = {
      username,
      isLinkToDG2Enabled,
      signOutClicked: this.signOutClicked,
      intuitAccountManagerClicked: this.intuitAccountManagerClicked,
    };
    return (
      <Fragment>
        <div className="w">
          <div className="flex flex-any">
            {isUserSignedIn ? (
              <div className="s-i">
                <div
                  aria-label="Toggle user menu"
                  className="menu-wrapper"
                  id="user-menu"
                  onClick={this.toggleMenu}
                  onKeyPress={this.toggleMenu}
                >
                  <div className="link-w" tabIndex={0}>
                    <div className="txt-overflow">{username}</div>
                  </div>
                  <span className="desktop" tabIndex={1} id="docViewHeader.header.signedIn">
                    <IconUser isLoggedIn={isUserSignedIn} />
                  </span>
                  <CpPopover
                    className="cp-menu-wrapper"
                    innerClassName="cp-logged-in-menu"
                    placement="bottom"
                    isOpen={isMenuOpen}
                    target="user-menu"
                  >
                    <InfoCard className="cp-menu-card" status="info">
                      <UserMenu {...userMenuProps} />
                    </InfoCard>
                  </CpPopover>
                </div>
              </div>
            ) : (
              <div
                className="link-w"
                tabIndex={0}
                onClick={() => this.signInClicked()}
                onTouchStart={() => this.signInClicked()}
                data-testid="sign-in-link"
              >
                <FormattedMessage id="HEADER_SIGN_IN" defaultMessage="Sign in" />
              </div>
            )}
            {!isUserSignedIn && (
              <a
                className="user-icon"
                aria-label="Sign in"
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => this.signInClicked()}
                onTouchStart={() => this.signInClicked()}
                data-testid="sign-in-icon"
              >
                <IconUser isLoggedIn={isUserSignedIn} />
              </a>
            )}
          </div>
        </div>
        <style jsx>{`
          .menu-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .w {
            white-space: nowrap;

            > div {
              align-items: center;
            }

            .link-w {
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 4px solid transparent;
              padding: 5px 5px 0px 5px;
              color: ${colors.gray02};
              cursor: pointer;

              &:hover,
              &:focus {
                border-bottom: solid 4px ${colors.green};
                color: ${colors.black};
                outline: none;
              }
            }

            .txt-overflow {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 300px;
              @media screen and (max-width: ${breakpoints.sm}) {
                max-width: 100px;
              }
            }

            .s-i {
              &:focus {
                outline: none;
              }
            }

            .user-icon {
              cursor: pointer;
              height: 20px;
              margin-left: 10px;

              :global(path) {
                fill: ${colors.gray};
              }

              &:hover,
              &:focus {
                outline: none;
                :global(circle) {
                  fill: ${colors.black};
                }
                :global(path) {
                  fill: ${colors.gray};
                }
              }
            }

            .menu-wrapper {
              cursor: pointer;
              &:hover,
              &:focus {
                color: ${colors.gray02};
                outline: none;
              }
            }

            .desktop {
              height: 20px;
              margin-left: 10px;
              :global(circle) {
                path: ${colors.gray02};
              }
              :global(path) {
                fill: ${colors.gray};
              }

              &:hover,
              &:focus {
                outline: none;
                :global(circle) {
                  fill: ${colors.green01};
                }
                :global(path) {
                  fill: ${colors.black};
                }
              }
            }
          }
        `}</style>
      </Fragment>
    );
  }
}
export default injectIntl(UserBar);
