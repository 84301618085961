import React, { Fragment } from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const IconUser: React.FC<IconBasePropsWithColor> = ({ isLoggedIn }) => {
  return (
    <Fragment>
      {isLoggedIn && (
        <style jsx>{`
          .userIcon {
            fill: ${colors.green};
          }
        `}</style>
      )}

      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0"
          // eslint-disable-next-line react/no-unknown-property
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" rx="12" fill="#8D9096" />
        </mask>
        <g className="container" mask="url(#mask0)">
          <rect width="24" height="24" rx="12" fill="#D4D7DC" />
          <circle className="userIcon" cx="12" cy="26" r="12" fill="#393A3D" />
          <circle className="userIcon" cx="12" cy="8" r="4" fill="#393A3D" />
        </g>
      </svg>
    </Fragment>
  );
};

export default IconUser;
