import HttpClient from 'server/helpers/HttpClient';
import { formatMaskedAccountNumber, formatMaskedCardNumber } from 'shared/PaymentHelpers';
import { validateMerchant, getAPPayload, getMaskedAPPayment } from './ApplePay';
import { handlePaypalOrder, checkIFPaypalEnabled } from './Paypal';
import { getBaseRequestBody, submitPayment, convertLineItemsToSCSPaymentFormat } from './Common';

const httpClient = HttpClient.getInstance();
import { accountTypeTranslator } from 'store/wallet/helpers';
import {
  getWeakTicket,
  getWalletAccount,
  capitalizeFirstLetter,
  convertToNullIfUndefined,
} from 'shared/clientUtils';
import { isPaymentRequest } from 'shared/utils';
import { getPaymentRequestBody } from './PaymentRequestHelpers';
import { isMAIPasPDF } from './helpers';
import {
  groupLineItems,
  calculateFreightAmount,
  calculateDiscountAmount,
} from 'businessLogic/server/scsToIcnAdapter';
import { handleNanopayPayment } from './Nanopay';
import { CP_ERROR_CODES } from 'types/constants';
import { saleSelectors } from 'store/sale/selectors';

/**
 * @type {import('./index').Payment}
 */
class Payment {
  constructor({ initialState, config, auth, companyInfo, insight, sale }) {
    this.config = config;
    this.auth = auth;
    this.companyInfo = companyInfo;
    this.sale = sale;
    this.insight = insight;
    this._data = initialState.payment;
  }

  /**
   * creates request from Client to SSR to create a scheduled payment
   */
  async schedulePayment({ domainId, token, data }) {
    const { ssrtid, portal } = this.config;

    const url = `/${portal}/rest/sale/schedulePayment/${token}`;
    const endpoint = `/rest/sale/schedulePayment`;

    const headers = this._getScheduleHeaders({ domainId, token });

    data = this._addBiocatchSession(data);

    return await httpClient({
      url,
      method: 'POST',
      headers,
      endpoint,
      token,
      ssrtid,
      data,
    });
  }

  async deleteSchedulePayment({ domainId, token, id }) {
    const { ssrtid, portal } = this.config;

    const url = `/${portal}/rest/sale/schedulePayment/${token}/${id}`;
    const endpoint = `/rest/sale/schedulePayment`;

    const headers = this._getScheduleHeaders({ domainId, token });

    return await httpClient({
      url,
      method: 'DELETE',
      headers,
      endpoint,
      token,
      ssrtid,
    });
  }

  getBaseRequestBody({
    paymentMethod,
    amountPaid,
    maskedPayment,
    gratuityAmount = 0,
    displayGratuityFeature = false,
    isAddL3Fields = false,
    featureFlags = {},
  }) {
    const { riskProfileToken: riskProfileToken, paymentProcessor } = this.insight;
    const { type: saleType, currency, customerName, saleId, isGpu, _data } = this.sale;
    const { entityId: payorAuthId } = this.auth;
    const { bioCatchSessionId } = this.config;
    let basicRequestBody = {
      paymentBasicSaleInfo: {
        saleId,
        saleType,
        amountPaid: amountPaid.toFixed(2),
        ...(displayGratuityFeature &&
          gratuityAmount &&
          typeof gratuityAmount === 'number' && { gratuityAmount: gratuityAmount.toFixed(2) }),
        payorAuthId,
        paymentMethod,
        paymentProcessor,
        customerName,
        currency,
        riskProfileToken,
        maskedPayment,
        isGpu,
        bioCatchSessionId,
      },
    };
    if (saleSelectors.achOnlineConvenienceFeeEnabledSelector(this.sale)) {
      const amountPaid =
        gratuityAmount && typeof gratuityAmount === 'number'
          ? (saleSelectors.amountSelector(this.sale) + gratuityAmount).toFixed(2)
          : saleSelectors.amountSelector(this.sale).toFixed(2);

      basicRequestBody.paymentBasicSaleInfo.feeAmount =
        saleSelectors.achOnlineConvenienceFeeAmountSelector(this.sale);
      basicRequestBody.paymentBasicSaleInfo.amountPaid = amountPaid;
    }
    if (isAddL3Fields && _data) {
      const { amount, receivable } = _data;

      basicRequestBody = {
        ...basicRequestBody,
        paymentBasicSaleInfo: {
          ...basicRequestBody.paymentBasicSaleInfo,
          applyTaxAfterDiscount: convertToNullIfUndefined(
            receivable && receivable.applyTaxAfterDiscount
          ),
          originalSaleAmount: convertToNullIfUndefined(amount),
          saleBalanceBeforePayment: convertToNullIfUndefined(receivable && receivable.balance),
        },
      };
    }

    if (isPaymentRequest(saleType)) {
      return getPaymentRequestBody({ basicRequestBody, sale: this.sale, payment: this });
    } else if (isMAIPasPDF({ sale: this.sale, featureFlags })) {
      return {
        ...basicRequestBody,
        contactInfo: {
          emails: [this.contactDetailsEmail],
        },
      };
    } else {
      return basicRequestBody;
    }
  }

  generateBankWalletPayload({ walletAccount }) {
    const { bankCode, accountType, payorFirstName, payorLastName, id } = walletAccount;
    const additionalInfo = {
      achInfo: {
        bankCode,
        payorName: `${payorFirstName}${payorLastName ? ` ${payorLastName}` : ''}`,
        accountType: accountTypeTranslator.fromCpServerToWallet(accountType),
        phoneNumber: '0000000000',
      },
      walletInfo: {
        id,
      },
    };
    return additionalInfo;
  }

  async submitBankPayment({
    bankDetails,
    wallet,
    amountPaid,
    timeout,
    gratuityAmount = 0,
    displayGratuityFeature = false,
    featureFlags,
  }) {
    const { portal, ssrtid } = this.config;
    const { domainId, token, companyId } = this.insight;
    const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = this.auth;
    const { selectedWalletId, userWallets } = wallet;
    const saleType = this.sale.type;
    let additionalACHInfo, paymentMethod, maskedPayment;
    if (userWallets && userWallets.length && selectedWalletId !== 'AddBank') {
      const walletAccount = getWalletAccount(userWallets, selectedWalletId);
      const { accountNumber, accountType } = walletAccount;
      maskedPayment = `${capitalizeFirstLetter(accountType)} ${formatMaskedAccountNumber(
        accountNumber
      )}`;
      additionalACHInfo = this.generateBankWalletPayload({ walletAccount });
      paymentMethod = 'bank wallet';
    } else {
      paymentMethod = 'bank';
      const {
        accountNumber,
        accountType,
        phone: phoneNumber,
        transitNumber,
        institutionNumber,
        name,
      } = bankDetails;
      let bankCode = bankDetails.bankCode;
      if (transitNumber && institutionNumber) {
        bankCode = `0${institutionNumber}${transitNumber}`;
      }
      const achInfo = {
        payorName: name,
        phoneNumber,
        accountType,
        tokenNumber: bankDetails.token, // If we are using Wallet Service REST2 and wallet-rest2-api-tokenize-bank is true, then in walletTokenizationPaymentCreation we had tokenized the raw data and saved it in bankDetails
      };
      additionalACHInfo = {
        achInfo:
          featureFlags && featureFlags['cp-support-live-short-lived-token-bank']
            ? achInfo
            : { ...achInfo, accountNumber, bankCode },
        savePaymentMethod: this.isSavePaymentMethodChecked ? 'AddBank' : undefined,
      };
      const formattedAccountType = capitalizeFirstLetter(
        accountTypeTranslator.fromWalletToCpServer(accountType)
      );
      maskedPayment = `${formattedAccountType} ${formatMaskedAccountNumber(accountNumber)}`;
    }
    const data = {
      ...this.getBaseRequestBody({
        paymentMethod,
        amountPaid,
        maskedPayment,
        ...(displayGratuityFeature && gratuityAmount && { gratuityAmount, displayGratuityFeature }),
        featureFlags,
      }),
      ...additionalACHInfo,
      saleInfo: {
        saleType,
        companyId,
      },
    };

    const result = await submitPayment({
      data,
      portal,
      ssrtid,
      domainId,
      token,
      ticket,
      realmId,
      isUserSignedIn,
      authToken,
      syncToken,
      entityId,
      timeout,
    });
    return result;
  }

  generateCardWalletPayload({ enabledCCTypes, walletAccount }) {
    // Payment using an existing wallet pm
    if (
      walletAccount &&
      walletAccount.cardType === 'amex' &&
      enabledCCTypes.indexOf('amex') === -1
    ) {
      return {
        data: { message: 'PAYFLOW_VALIDATION_CARD_TYPE_INVALID', cardType: 'american-express' },
      };
    }
    const { payorFirstName: payorName, cardNumber: tokenNumber, id } = walletAccount;
    const additionalInfo = {
      creditCardInfo: {
        tokenNumber,
        payorName,
      },
      walletInfo: {
        id,
      },
    };
    return additionalInfo;
  }

  async submitCardPayment({
    // Payment using a new card (not saved in the wallet)
    ccDetails,
    wallet,
    amountPaid,
    timeout,
    gratuityAmount,
    displayGratuityFeature = false,
    featureFlags = {},
  }) {
    const { selectedWalletId, userWallets, enabledCCTypes } = wallet;
    const { portal, ssrtid } = this.config;
    const { domainId, token, companyId } = this.insight;
    const saleType = this.sale.type;

    const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = this.auth;
    let additionalCCInfo, paymentMethod, maskedPayment;
    if (userWallets && userWallets.length && selectedWalletId !== 'AddCard') {
      paymentMethod = 'credit card wallet';
      const walletAccount = getWalletAccount(userWallets, selectedWalletId);
      const { cardType, cardNumber } = walletAccount;
      maskedPayment = formatMaskedCardNumber(cardType, cardNumber);
      additionalCCInfo = this.generateCardWalletPayload({ enabledCCTypes, walletAccount });
    } else {
      const { cardType, name: payorName, token: tokenNumber, cardNumber } = ccDetails;

      paymentMethod = 'credit card';
      maskedPayment = formatMaskedCardNumber(cardType, cardNumber);
      additionalCCInfo = {
        creditCardInfo: {
          tokenNumber,
          payorName,
        },
        savePaymentMethod: this.isSavePaymentMethodChecked ? 'AddCard' : undefined,
      };
    }

    const isAddL3Fields = featureFlags['add-L3-fields-card-payment'];

    const data = {
      ...this.getBaseRequestBody({
        paymentMethod,
        amountPaid,
        maskedPayment,
        ...(displayGratuityFeature && gratuityAmount && { gratuityAmount, displayGratuityFeature }),
        isAddL3Fields,
        featureFlags,
      }),
      ...additionalCCInfo,
      saleInfo: {
        id: this.sale.saleId,
        freightAmount: calculateFreightAmount(this.sale.freight),
        discountAmount: calculateDiscountAmount(this.sale.lines),
        taxAmount: this.sale.tax || 0,
        lines: convertLineItemsToSCSPaymentFormat(groupLineItems(this.sale.lines), isAddL3Fields),
        saleType,
        companyId,
      },
    };
    const result = await submitPayment({
      data,
      portal,
      ssrtid,
      domainId,
      token,
      ticket,
      realmId,
      isUserSignedIn,
      authToken,
      syncToken,
      entityId,
      timeout,
    });
    return result;
  }

  async submitNanopayPayment({ nanopayDetails, amountPaid, timeout }) {
    return handleNanopayPayment({
      config: this.config,
      auth: this.auth,
      insight: this.insight,
      sale: this.sale,
      nanopayDetails,
      amountPaid,
      timeout,
      getBaseRequestBody,
      getRequestBody: this.getBaseRequestBody.bind(this),
      submitPayment,
    });
  }

  submitApplePayPayment({ amountPaid, timeout, applePayInfo, featureFlags }) {
    let maskedPayment, basicRequestBody, data;
    const {
      saleId,
      freight,
      tax,
      lines,
      type: saleType,
      currency,
      customerName,
      _data = {},
    } = this.sale;
    const { isGpu } = _data;
    const {
      riskProfileToken: riskProfileToken,
      paymentProcessor,
      domainId,
      token,
      companyId,
    } = this.insight;
    const { portal, ssrtid, bioCatchSessionId } = this.config;
    const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = this.auth;

    maskedPayment = getMaskedAPPayment(applePayInfo);
    basicRequestBody = getBaseRequestBody({
      paymentMethod: 'applePay',
      maskedPayment,
      amountPaid,
      riskProfileToken,
      paymentProcessor,
      saleId,
      freight,
      tax,
      lines,
      saleType,
      currency,
      customerName,
      bioCatchSessionId,
    });

    if (isPaymentRequest(saleType)) {
      basicRequestBody = getPaymentRequestBody({
        basicRequestBody,
        sale: this.sale,
        payment: this,
      });
    }
    data = {
      ...getAPPayload(basicRequestBody, applePayInfo, isGpu),
      saleInfo: {
        ...basicRequestBody.saleInfo,
        saleType,
        companyId,
      },
    };
    if (isMAIPasPDF({ sale: this.sale, featureFlags })) {
      data = {
        ...data,
        contactInfo: {
          emails: [this.contactDetailsEmail],
        },
      };
    }

    return submitPayment({
      data,
      portal,
      ssrtid,
      domainId,
      token,
      ticket,
      realmId,
      isUserSignedIn,
      authToken,
      syncToken,
      entityId,
      timeout,
    });
  }

  async submitSCSPayment({
    ccDetails,
    bankDetails,
    nanopayDetails,
    applePayInfo,
    wallet,
    amountPaid,
    paymentMethodType,
    timeout,
    displayGratuityFeature,
    gratuityAmount,
    featureFlags = {},
  }) {
    let result;
    try {
      if (!(wallet || applePayInfo) || !amountPaid || !paymentMethodType) {
        throw Error(`submitSCSPayment: Invalid arguments`);
      }

      switch (paymentMethodType) {
        case 'bank':
        case 'eft':
          result = await this.submitBankPayment({
            bankDetails,
            wallet,
            amountPaid,
            timeout,
            ...(displayGratuityFeature &&
              gratuityAmount && { gratuityAmount, displayGratuityFeature }),
            featureFlags,
          });
          break;
        case 'cc':
        case 'dc':
        case 'dc,cc':
          result = await this.submitCardPayment({
            ccDetails,
            wallet,
            amountPaid,
            timeout,
            ...(displayGratuityFeature &&
              gratuityAmount && { gratuityAmount, displayGratuityFeature }),
            featureFlags,
          });
          break;
        case 'nanopay':
          result = await this.submitNanopayPayment({ nanopayDetails, amountPaid, timeout });
          break;
        case 'ap':
          result = await this.submitApplePayPayment({
            amountPaid,
            timeout,
            applePayInfo,
            featureFlags,
          });
          break;
        default:
          throw Error(`submitSCSPayment: Invalid payment method - ${paymentMethodType}`);
      }
      if (!result || result.status !== 200) {
        result = {
          data: {
            paymentStatus: 'ERROR',
            status: result.status,
            statusText: result.statusText,
          },
        };
      } else {
        result.data.status = result.status;
        result.data.statusText = result.statusText;
      }
    } catch (e) {
      let errorData = {};
      const { response, config } = e;
      if (response) {
        const { status, statusText, data } = response;
        if (data) {
          const { ErrorMessage } = data;
          errorData = {
            ...errorData,
            status,
            statusText,
            errorCode:
              ErrorMessage === 'SCS Sale Validation Error' ? CP_ERROR_CODES.SALE_VALIDATION : '',
          };
        } else {
          errorData = {
            ...errorData,
            status,
            statusText,
          };
        }
      }

      result = {
        data: {
          ...errorData,
          paymentStatus: 'ERROR',
        },
        config,
      };
      if (e.code) {
        result = { ...result, response: { status: e.code, data: errorData } };
      } else if (e.response && e.response.status) {
        result = { ...result, response: { status: e.response.status, data: errorData } };
      }
    }

    return result;
  }

  async handlePaypalOrder({
    action,
    intuitOrderId,
    usedPaymentMethod,
    gratuityAmount,
    paymentAmount,
    contactDetailsName,
    contactDetailsEmail,
    featureFlags,
  }) {
    const { riskProfileToken: profileToken, domainId, token } = this.insight;
    const { portal, ssrtid } = this.config;
    const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = this.auth;
    const {
      currency: currencyCode,
      type,
      contact,
      isGpu,
      subType,
      contact: { displayName, toEmails } = {},
    } = this.sale;
    const headers = getWeakTicket({
      domainId,
      entityId,
      realmId,
      token,
      ticket,
      isUserSignedIn,
      syncToken,
      authToken,
      ssrtid,
    });
    return await handlePaypalOrder({
      action,
      intuitOrderId,
      headers,
      portal,
      authToken,
      amount: paymentAmount,
      currencyCode,
      isGpu,
      subType,
      displayName,
      toEmails,
      contactDetailsName,
      contactDetailsEmail,
      usedPaymentMethod,
      gratuityAmount,
      type,
      profileToken,
      token,
      ssrtid,
      featureFlags,
      contact,
    });
  }

  checkIFPaypalEnabled({
    sourceOffering,
    saleType,
    enabledPaymentMethods,
    featureFlags,
    paymentMethodType,
    userWallets,
  }) {
    return checkIFPaypalEnabled({
      sourceOffering,
      saleType,
      enabledPaymentMethods,
      featureFlags,
      paymentMethodType,
      userWallets,
    });
  }

  async validateApplePayMerchant(validationURL) {
    const { portal, ssrtid } = this.config;
    const { domainId, token } = this.insight;
    const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = this.auth;
    const headers = getWeakTicket({
      domainId,
      entityId,
      realmId,
      token,
      ticket,
      isUserSignedIn,
      syncToken,
      authToken,
      ssrtid,
    });
    const res = await validateMerchant(validationURL, portal, headers);
    return res;
  }

  set contactDetailsName(value) {
    this._data.contactDetailsName = value;
  }

  get contactDetailsName() {
    return this._data.contactDetailsName;
  }

  set contactDetailsEmail(value) {
    this._data.contactDetailsEmail = value;
  }

  get contactDetailsEmail() {
    return this._data.contactDetailsEmail;
  }

  get isContactDetailsNameValid() {
    const name = this.contactDetailsName;

    let isValid = false;
    if (typeof name === 'string') {
      isValid = /[a-zA-Z].+ [a-zA-Z].+/gm.test(name);
    }

    return isValid;
  }

  get isContactDetailsEmailValid() {
    const email = this.contactDetailsEmail;
    let isValid = false;
    if (typeof email === 'boolean') {
      isValid = email;
    } else if (typeof email === 'string') {
      isValid =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
          email
        );
    }

    return isValid;
  }

  set isSavePaymentMethodChecked(value) {
    this._data.isSavePaymentMethodChecked = value;
  }

  get isSavePaymentMethodChecked() {
    return this._data.isSavePaymentMethodChecked;
  }

  async subscriptionPayment({ domainId, token, data }) {
    const { ssrtid, portal } = this.config;

    const url = `/${portal}/rest/sale/subscription/${token}`;
    const endpoint = `/rest/sale/subscription`;

    const headers = this._getScheduleHeaders({ domainId, token });

    data = this._addBiocatchSession(data);

    return await httpClient({
      url,
      method: 'POST',
      headers,
      endpoint,
      token,
      ssrtid,
      data,
      timeout: 15000, // For an Immediate Subscription the request takes much longer, because it reaches for PayApi for an immediate payment as well as activating the subscription for further payments
    });
  }

  _getScheduleHeaders({ domainId, token }) {
    const {
      ticket,
      authLevel,
      realmId,
      isUserSignedIn,
      isEntityPromoted,
      entityId,
      authToken,
      syncToken,
    } = this.auth;

    const headers = {
      'Intuit-ACSToken': token,
      'Intuit-AuthLevel': authLevel,
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      isClientStateSignedIn: isUserSignedIn,
      isEntityPromoted: isEntityPromoted,
      sessionTicket: ticket,
      Authorization: `Bearer ${authToken}`,
    };

    if (syncToken) {
      headers['syncToken'] = syncToken;
    }

    return headers;
  }

  _addBiocatchSession(data) {
    const { biocatchSessionId } = this.insight;

    if (biocatchSessionId) {
      data = Object.assign({}, data, { biocatchSessionId });
    }

    return data;
  }

  async cancelSubscriptionPayment({ domainId, token }) {
    const { ssrtid, portal } = this.config;

    const url = `/${portal}/rest/sale/subscription/${token}`;
    const endpoint = `/rest/sale/subscription`;

    const headers = this._getScheduleHeaders({ domainId, token });

    try {
      return await httpClient({
        url,
        method: 'DELETE',
        headers,
        endpoint,
        token,
        ssrtid,
        data: {},
      });
    } catch (e) {
      return e;
    }
  }
}

export default Payment;
