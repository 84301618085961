import { PayloadAction } from '@reduxjs/toolkit';

import { sliceFactory } from 'store/utils';
import { Error } from 'types/Error';

const initialState: Error = {};

const { actions, reducer } = sliceFactory({
  name: 'error',
  initialState,
  reducers: {
    reset() {
      return {};
    },
    set(__, action: PayloadAction<Error>) {
      return action.payload;
    },
  },
});

export const errorReducer = reducer;
export const errorActions = actions;
