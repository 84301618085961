import classNames from 'classnames';
import React from 'react';

import Card from 'components/Shared/Card/Card';
import Chevron from 'components/Shared/Icons/Chevron';
import { colors } from 'styles/cp';

export interface InfoCardProps {
  status: 'success' | 'info' | 'error';
  className?: string;
  onClick?: () => any;
}

const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { status = 'success', onClick } = props;

  const prefixCls = 'info-card-status';

  const rootClassName = classNames(prefixCls, {
    [`${prefixCls}--${status}`]: status,
  });
  return (
    <div className="info-card-root" onClick={onClick}>
      <Card>
        <div className="info-card-content">
          <span>{props.children}</span>
          {onClick && (
            <span className="info-card-icon">
              <Chevron height={20} width={20} color={colors.gray01} />
            </span>
          )}
        </div>
        <div className={rootClassName} />
      </Card>

      <style jsx>{`
        .info-card-root {
          margin-bottom: 0;
        }

        .info-card-content {
          display: flex;
          padding: 0;
          justify-content: space-between;
          align-items: center;
        }
        .info-card-icon {
          transform: rotate(90deg);
          margin-left: 20px;
        }
        .info-card-status {
          height: 4px;
          width: 100%;
          border-radius: 0px 0px 4px 4px;
        }
        .info-card-status--success {
          background-image: linear-gradient(to left, #00ba00, #00c5c0);
        }
        .info-card-status--info {
          background-image: linear-gradient(to left, ${colors.blue}, ${colors.blue02});
        }
        .info-card-status--error {
          background-image: linear-gradient(to left, ${colors.error}, ${colors.orange});
        }
      `}</style>
    </div>
  );
};

export default InfoCard;
