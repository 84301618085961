import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import {
  getSurveyEngagementBL,
  GetSurveyEngagementResponse,
  getSurveySettingsBL,
} from 'businessLogic/Mailchimp/Messaging';
import { ISurveyEngagementRequestParams } from 'server/services/mailchimp/MessagingService';
import { isRevIntelSurveyReviewsAndSettingsEnabled } from 'store/ixp/selector';
import { getSurveySettingsMap } from 'store/postInvoiceSurvey/helpers';
import { saleSelectors } from 'store/sale/selectors';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { PostInvoiceSurvey, SurveyOpen } from 'types/PostInvoiceSurvey';

const initialState: PostInvoiceSurvey = {
  surveyOpen: SurveyOpen.None,
  loading: true,
  settingsMap: null,
  repeatBusinessEngagements: null,
  feedbackEngagements: null,
  referralEngagements: null,
};

const { actions, reducer } = sliceFactory({
  name: 'postInvoiceSurvey',
  initialState,
  reducers: {
    setSurveyOpen: (state, action: PayloadAction<PostInvoiceSurvey>) => {
      const { surveyOpen } = action.payload;
      state.surveyOpen = surveyOpen;
    },
    setLoading: (state, action: PayloadAction<PostInvoiceSurvey>) => {
      const { loading } = action.payload;
      state.loading = loading;
    },
    setSettingsMap: (state, action: PayloadAction<PostInvoiceSurvey>) => {
      const { settingsMap } = action.payload;
      state.settingsMap = settingsMap;
    },
    setRepeatBusinessEngagements: (state, action: PayloadAction<PostInvoiceSurvey>) => {
      const { repeatBusinessEngagements } = action.payload;
      state.repeatBusinessEngagements = repeatBusinessEngagements;
    },
    setFeedbackEngagements: (state, action: PayloadAction<PostInvoiceSurvey>) => {
      const { feedbackEngagements } = action.payload;
      state.feedbackEngagements = feedbackEngagements;
    },
    setReferralEngagements: (state, action: PayloadAction<PostInvoiceSurvey>) => {
      const { referralEngagements } = action.payload;
      state.referralEngagements = referralEngagements;
    },
  },
});

export const fetchSurveySettings = thunkActionFactory(async ({ state, dispatch }) => {
  const {
    config: { ssrtid, portal },
    insight: { token },
    auth: { authToken, realmId },
  } = state;

  const resp = await getSurveySettingsBL({
    ssrtid,
    portal,
    acsToken: token,
    realmId,
    authToken,
  });

  const settingsMap = getSurveySettingsMap(resp);

  dispatch(
    postInvoiceSurveyActions.setSettingsMap({
      settingsMap,
    })
  );

  return resp;
});

export const fetchSurveyEngagement = thunkActionFactory<
  Omit<ISurveyEngagementRequestParams, 'customerId'>
>(async ({ state, payload, dispatch }) => {
  const {
    config: { ssrtid, portal },
    insight: { token },
    auth: { authToken, realmId },
    ixp,
    featureFlags,
    sale,
  } = state;

  const { fromDate, toDate, limit, engagementType } = payload;
  const customerId = saleSelectors.customerLocalNameIdSelector(sale) ?? '';

  const resp = (await getSurveyEngagementBL({
    ssrtid,
    portal,
    acsToken: token,
    realmId,
    authToken,
    data: {
      engagementType,
      customerId,
      toDate,
      fromDate,
      limit,
    },
  })) as AxiosResponse<GetSurveyEngagementResponse>;

  const revIntelSurveyOptimizationsEnabled = isRevIntelSurveyReviewsAndSettingsEnabled({
    ixp,
    featureFlags,
  });

  // update the associated engagement state -- no-op for disabled settings
  switch (engagementType) {
    case 'REPEAT_BUSINESS': {
      if (
        (revIntelSurveyOptimizationsEnabled && state.postInvoiceSurvey.settingsMap?.WorkRequest) ||
        (!revIntelSurveyOptimizationsEnabled &&
          state.postInvoiceSurvey.settingsMap?.SurveyPostInvoice)
      ) {
        dispatch(
          postInvoiceSurveyActions.setRepeatBusinessEngagements({
            repeatBusinessEngagements: resp.data?.responses,
          })
        );
      }
      break;
    }
    case 'FEEDBACK': {
      if (state.postInvoiceSurvey.settingsMap?.FeedbackTestimonial) {
        dispatch(
          postInvoiceSurveyActions.setFeedbackEngagements({
            feedbackEngagements: resp.data?.responses,
          })
        );
      }

      break;
    }
    case 'REFERRAL': {
      if (state.postInvoiceSurvey.settingsMap?.Referral) {
        dispatch(
          postInvoiceSurveyActions.setReferralEngagements({
            referralEngagements: resp.data?.responses,
          })
        );
      }
      break;
    }
  }

  return resp;
});

export const postInvoiceSurveyReducer = reducer;
export const postInvoiceSurveyActions = actions;
