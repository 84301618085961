import { ixpTreatmentSelector } from 'store/ixp/selector';
import { RootState } from 'store/store';

export const EXPERIMENT_KEY = 'CpFeedbackForm';
export const FEEDBACK_V2_TREATMENT = 'IXP2_T_712035';

export const isInvoiceInFeedbackExperiment = (store: RootState) => {
  const treatmentKey = ixpTreatmentSelector(store, EXPERIMENT_KEY);
  return treatmentKey === FEEDBACK_V2_TREATMENT;
};
