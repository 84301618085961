import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const FaceFrown: React.FC<IconBasePropsWithColor> = ({
  width = 40,
  height = 40,
  color = colors.gray,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7043 28.2442C12.9234 26.1544 15.7673 24.5 20 24.5C24.2327 24.5 27.0766 26.1544 28.2957 28.2442C28.7131 28.9598 28.4714 29.8782 27.7558 30.2957C27.0402 30.7131 26.1217 30.4714 25.7043 29.7558C25.1734 28.8456 23.5023 27.5 20 27.5C16.4977 27.5 14.8266 28.8456 14.2957 29.7558C13.8782 30.4714 12.9598 30.7131 12.2442 30.2957C11.5286 29.8782 11.2869 28.9598 11.7043 28.2442Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13.5C14.3807 13.5 15.5 14.6193 15.5 16V16.2C15.5 17.5807 14.3807 18.7 13 18.7C11.6193 18.7 10.5 17.5807 10.5 16.2V16C10.5 14.6193 11.6193 13.5 13 13.5ZM27 13.5C28.3807 13.5 29.5 14.6193 29.5 16V16.2C29.5 17.5807 28.3807 18.7 27 18.7C25.6193 18.7 24.5 17.5807 24.5 16.2V16C24.5 14.6193 25.6193 13.5 27 13.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36ZM40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
        fill={color}
      />
    </svg>
  );
};

export default FaceFrown;
