const isRetryAllowed = require('is-retry-allowed');
const uuidGenerator = require('./uuidGenerator');
import { HTTP_STATUS_CODES, NODE_NETWORK_ERRORS } from 'types/constants';
const SAFE_HTTP_METHODS = ['get', 'head', 'options'];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(['put', 'delete']);
const retryableEndpointList = [
  '/v1/checkout/${token}/pdf',
  '/v1/checkout/${token}',
  '/ipgeocode/rest/v1/',
  '/v2/accounts/',
];

/**
 * @param  {Error}  error
 * @return {boolean}
 */
const isNotNetworkTimeoutClientError = (error) => {
  return (
    IDEMPOTENT_HTTP_METHODS.indexOf(error?.config?.method) !== -1 &&
    !error.response &&
    Boolean(error.code) && // Prevents retrying cancelled requests
    (error.code !== NODE_NETWORK_ERRORS.ECONNABORTED || isRetryableRequests(error)) &&
    isRetryAllowed(error)
  ); // Prevents retrying unsafe errors0
};

/**
 * @param  {Error}  error
 * @return {boolean}
 */
const isRetryableError = (error) => {
  return (
    !error.response ||
    error.response.status === HTTP_STATUS_CODES.RequestTimeout ||
    (error.response.status >= HTTP_STATUS_CODES.InternalServerError &&
      error.response.status <= HTTP_STATUS_CODES.NetworkAuthenticationRequired)
  );
};

/**
 * @param  {Error}  error
 * @return {boolean}
 */
const isSafeRequestError = (error) => {
  if (!error.config) {
    // Cannot determine if the request can be retried
    return false;
  }

  return isRetryableError(error) && SAFE_HTTP_METHODS.indexOf(error.config.method) !== -1;
};

/**
 * @param  {Error}  error
 * @return {boolean}
 */
const isRetryableRequests = (error) => {
  if (!error.config) {
    // Cannot determine if the request can be retried
    return false;
  }
  return isSafeRequestError(error) && retryableEndpointList.includes(error.config.endpoint);
};

/**
 * @param  {Error}  error
 * @return {boolean}
 */
const isIdempotentRequestError = (error) => {
  if (!error.config) {
    // Cannot determine if the request can be retried
    return false;
  }

  return (
    error.code !== NODE_NETWORK_ERRORS.ECONNABORTED &&
    isRetryableError(error) &&
    IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1
  );
};

/**
 * 429 requests should be retried as of
 * https://docs.newrelic.com/docs/apis/rest-api-v2/basic-functions/api-overload-protection-handling-429-errors/
 * It is safe to retry it even for a POST (non-idempotent) requests
 * @param  {Error}  error
 * @return {boolean}
 */
const is429ResponseStatus = (error) => {
  if (!error.response) {
    return false;
  }
  return error.response.status === HTTP_STATUS_CODES.TooManyRequests;
};

/**
 * @param  {AxiosError}  error
 * @return {boolean}
 */
const isNetworkOrIdempotentRequestError = (error) => {
  return (
    isNotNetworkTimeoutClientError(error) ||
    isIdempotentRequestError(error) ||
    is429ResponseStatus(error)
  );
};

const isNodeNetworkError = (error) => {
  const nodeNetworkErrorsList = Object.values(NODE_NETWORK_ERRORS);
  return error && nodeNetworkErrorsList.includes(error.code);
};

/**
 * @type {import('server/middleware/httpInterceptors').Interceptor}
 */
const requestInterceptor = (config) => {
  config.rumStart = Date.now();

  if (config.headers) {
    Object.keys(config.headers).forEach((key) => {
      if (config.headers[key] === undefined || config.headers[key] === null) {
        delete config.headers[key];
      }
    });
    // if we already have a tid don't create a new one
    if (config.headers.intuit_tid) {
      return config;
    }
    let intuitCpTid = `${uuidGenerator.getUUID()}`;
    intuitCpTid = intuitCpTid.slice(4);

    if (typeof window === 'object') {
      config.headers.intuit_tid = `cp-c${intuitCpTid}`;
    } else {
      config.headers.intuit_tid = `cp-s${intuitCpTid}`;
    }
  }
  return config;
};

/**
 * @type {import('server/middleware/httpInterceptors.d.ts').Interceptor}
 */
const responseRUMInterceptor = (response) => {
  if (response.config && typeof response.config.rumStart !== 'undefined') {
    response.config.rum = Date.now() - response.config.rumStart;
  }
  return response;
};

const responseNetworkErrorInterceptor = (error) => {
  if (!isNodeNetworkError(error)) {
    // Due to Axios update - deleting the error.code of Node network errors to keep the old version behavior ()
    delete error.code;
  }
  return Promise.reject(error);
};
export {
  requestInterceptor,
  responseRUMInterceptor,
  isNetworkOrIdempotentRequestError,
  isRetryableRequests,
  isIdempotentRequestError,
  isSafeRequestError,
  isRetryableError,
  isNotNetworkTimeoutClientError,
  is429ResponseStatus,
  responseNetworkErrorInterceptor,
  isNodeNetworkError,
};
