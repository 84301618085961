import { useEffect, useState } from 'react';

import { breakpointsNumbers } from 'styles/cp';

export function useIsMobileScreen(initialState = 1200) {
  const [width, setWidth] = useState(initialState);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= breakpointsNumbers.md;
}
