import { configureStore, PreloadedState, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { combineReducers, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { authReducers } from './auth/slice';
import { companyInfoReducer } from './companyInfo/slice';
import { configReducer } from './config/slice';
import { errorReducer } from './error/slice';
import { featureFlagsReducer } from './featureFlags/slice';
import { i18nReducer } from './i18n/slice';
import { insightReducer } from './insight/slice';
import { invoiceDocumentReducer } from './invoiceDocument/slice';
import { ixpReducer } from './ixp/slice';
import { modalReducer } from './modal/slice';
import { paymentReducer } from './payment/slice';
import { payorHistoryReducer } from './payorHistory/slice';
import { postInvoiceSurveyReducer } from './postInvoiceSurvey/slice';
import { saleReducer } from './sale/slice';
import { toastReducer } from './toast/slice';
import { walletReducer } from './wallet/slice';

import type { Reducer } from 'redux';
import type { Auth } from 'types/Auth';
import type { CompanyInfo } from 'types/CompanyInfo';
import type { Config } from 'types/Config';
import type { Error } from 'types/Error';
import type { FeatureFlags } from 'types/FeatureFlags';
import type { IXP } from 'types/IXP';
import type { Insight } from 'types/Insight';
import type { InvoiceDocument } from 'types/InvoiceDocument';
import type { Modal } from 'types/Modal';
import type { Nanopay } from 'types/Nanopay';
import type { Payment } from 'types/Payment';
import type { PayorHistory } from 'types/PayorHistory';
import type { PostInvoiceSurvey } from 'types/PostInvoiceSurvey';
import type { Sale } from 'types/Sale';
import type { Toast } from 'types/Toast';
import type { Wallet } from 'types/Wallet';
import type { I18n } from 'types/i18n';

import { IBusinessLogic } from 'businessLogic/initializer';
import { nanopayReducer } from 'store/nanopay/slice';

export interface DynamicStore extends Store {
  asyncReducers: ReducersObject;
  injectReducer: (key: string | number, asyncReducer: any) => void;
}

let store: DynamicStore;

export const getStore = () => store;

const staticReducers: ReducersObject = {
  sale: saleReducer,
  companyInfo: companyInfoReducer,
  insight: insightReducer,
  config: configReducer,
  modal: modalReducer,
  toastr: toastReducer,
  invoiceDocument: invoiceDocumentReducer,
  i18n: i18nReducer,
  auth: authReducers,
  featureFlags: featureFlagsReducer,
  ixp: ixpReducer,
  error: errorReducer,
  payorHistory: payorHistoryReducer,
  postInvoiceSurvey: postInvoiceSurveyReducer,
};

const dynamicReducers = {
  wallet: walletReducer,
  payment: paymentReducer,
  nanopay: nanopayReducer,
};

const createReducer = (asyncReducers: ReducersObject = {}) =>
  // @ts-ignore
  // That ignore is to enable to create reducers that have null in its initial state and the action types,
  // it will be deleted after all reducers are migrated to toolkit & usage of Domain types from /types
  combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });

export type RootState = {
  auth: Auth;
  companyInfo: CompanyInfo;
  config: Config;
  error: Error;
  featureFlags: FeatureFlags;
  i18n: I18n;
  insight: Insight;
  invoiceDocument: InvoiceDocument;
  ixp: IXP;
  modal: Modal;
  payment: Payment;
  payorHistory: PayorHistory;
  nanopay?: Nanopay;
  postInvoiceSurvey: PostInvoiceSurvey;
  sale: Sale;
  toast: Toast;
  wallet: Wallet;
};
type InitialState = PreloadedState<StateFromReducersMapObject<typeof staticReducers>>;

type ReducersObject = Record<string, Reducer<any, any>>;

export function initializeStore(
  initialState: InitialState = {} as InitialState,
  businessLogic: IBusinessLogic
) {
  injectDynamicReducersOnInitialization(initialState);
  const middlewares = [thunkMiddleware.withExtraArgument(businessLogic)];
  store = {
    ...configureStore({
      reducer: createReducer(),
      middleware: middlewares,
      preloadedState: initialState,
    }),
    // Keep track of lazy loaded reducers
    asyncReducers: {},
    // Allow reducers to be dynamically injected
    injectReducer: (key, asyncReducer) => {
      store.asyncReducers[key] = asyncReducer;
      // @ts-ignore
      store.replaceReducer(createReducer(store.asyncReducers));
      // Dispatch an action to add initial state for new reducers
      store.dispatch({ type: '@@REDUCER_INJECTED' });
    },
  };

  return store;
}

const injectDynamicReducersOnInitialization = (initialState: InitialState) => {
  Object.entries(dynamicReducers).forEach(([name, reducer]) => {
    if (initialState[name]) {
      staticReducers[name] = reducer;
    }
  });
};

declare global {
  interface Window {
    __NEXT_REDUX_STORE__: DynamicStore;
  }
}
