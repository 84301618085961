import { CompanyInfo } from 'types/CompanyInfo';

export const companyInfoSelectors = {
  nameSelector(companyInfo: CompanyInfo) {
    return (
      companyInfo.companyName &&
      companyInfo.companyName
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
    );
  },
  emailSelector(companyInfo: CompanyInfo) {
    return (
      companyInfo.contactMethods &&
      companyInfo.contactMethods.length > 0 &&
      companyInfo.contactMethods[0].primaryEmail &&
      companyInfo.contactMethods[0].primaryEmail.emailAddress
    );
  },
  phoneSelector(companyInfo: CompanyInfo) {
    return (
      companyInfo.contactMethods &&
      companyInfo.contactMethods.length > 0 &&
      companyInfo.contactMethods[0].primaryTelephone &&
      companyInfo.contactMethods[0].primaryTelephone.number
    );
  },
  webAddrSelector(companyInfo: CompanyInfo) {
    return (
      companyInfo.contactMethods &&
      companyInfo.contactMethods.length > 0 &&
      companyInfo.contactMethods[0].webSite
    );
  },
  addressSelector(companyInfo: CompanyInfo) {
    let address =
      companyInfo.contactMethods &&
      companyInfo.contactMethods.length > 0 &&
      companyInfo.contactMethods[0].addresses &&
      companyInfo.contactMethods[0].addresses.length > 0 &&
      companyInfo.contactMethods[0].addresses[0].addressComponents;
    if (address) {
      address = { ...address };
      address.stateOrRegion = address.state;
      delete address.state;
    }
    return address;
  },
  localeSelector(companyInfo: CompanyInfo) {
    if (companyInfo.language && companyInfo.region) {
      return `${companyInfo.language}_${companyInfo.region}`;
    }
    return 'en_US';
  },
  logoSelector(companyInfo: CompanyInfo) {
    return companyInfo.logo;
  },

  sourceOfferingSelector(companyInfo: CompanyInfo) {
    return companyInfo.sourceOffering;
  },
  paypalMerchantIdSelector(companyInfo: CompanyInfo) {
    return companyInfo.paypalMerchantId;
  },
  regionSelector(companyInfo: CompanyInfo) {
    return companyInfo.region;
  },
};
