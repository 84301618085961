import { isServer } from 'shared/utils';
import { Logger } from 'types/Logger';
import {
  InboundOutBoundParams,
  ContextualParams,
  ReportParams,
  SessionInfo,
  applicationInfo,
} from 'types/SplunkLogs';

class SplunkReporter {
  private logger: Logger | undefined;
  private applicationInfo: applicationInfo;
  private serverSession: { [sessionId: string]: SessionInfo } = {};
  private clientSession: SessionInfo;
  private isEnabled: boolean;
  static _instance: SplunkReporter;

  constructor() {
    this.applicationInfo = {
      assetId: '5693693421089207390',
      assetAlias: 'Intuit.spi.icn_client.2',
      service: 'cp-client',
    };

    this.serverSession = {};

    this.clientSession = {};

    this.isEnabled = true;
  }

  public setIsEnabled(isEnabled: boolean) {
    this.isEnabled = isEnabled;
  }

  public setLogger(logger: Logger) {
    this.logger = logger;
  }

  public setAppVersion(cpVersion: string) {
    this.applicationInfo.cpVersion = cpVersion;
  }

  public setAppImage(appImage: string) {
    this.applicationInfo.appImage = appImage;
  }

  public setClientSession(args: SessionInfo) {
    const getBrowserLocation = () => {
      if (isServer()) {
        return;
      }
      return window?.location?.href;
    };

    const fullUrl = this.clientSession?.fullUrl || getBrowserLocation();
    this.clientSession = { ...this.clientSession, ...args, fullUrl };
  }

  public setServerSession(sessionId: string, args: SessionInfo) {
    this.serverSession[sessionId] = { ...this.serverSession[sessionId], ...args };
  }

  public deleteServerSession(sessionId: string) {
    delete this.serverSession[sessionId];
  }

  public inbound({
    sessionId,
    logInfo,
    network,
    event,
    action,
    activityInfo,
    rum,
    error,
  }: InboundOutBoundParams) {
    logInfo.logType = 'inbound';
    this.report({ sessionId, logInfo, network, event, action, activityInfo, rum, error });
  }

  public outbound({
    sessionId,
    logInfo,
    network,
    event,
    action,
    activityInfo,
    rum,
    error,
  }: InboundOutBoundParams) {
    logInfo.logType = 'outbound';
    this.report({ sessionId, logInfo, network, event, action, activityInfo, rum, error });
  }

  public contextual({
    sessionId,
    logInfo,
    event,
    action,
    activityInfo,
    rum,
    error,
  }: ContextualParams) {
    logInfo.logType = 'contextual';
    this.report({ sessionId, logInfo, event, action, activityInfo, rum, error });
  }

  private report({
    sessionId = '',
    logInfo,
    event,
    action,
    activityInfo,
    network,
    rum,
    error,
  }: ReportParams) {
    try {
      // this is controlled by a feature flag, to avoid overloading splunk
      if (!this.isEnabled || !this.logger) {
        return;
      }

      const { logLevel } = logInfo;
      const timestamp = new Date().toISOString();
      this.logger![logLevel]({
        timestamp,
        event,
        action,
        activityInfo,
        logInfo,
        network,
        rum,
        error,
        sessionInfo: isServer()
          ? this.serverSession[sessionId] || { sessionId }
          : this.clientSession,
        applicationInfo: this.applicationInfo,
        newSyntax: true,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  static getInstance(): SplunkReporter {
    if (!this._instance) {
      this._instance = new SplunkReporter();
    }
    return this._instance;
  }
}

export { SplunkReporter };
