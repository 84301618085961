import HttpClient from 'server/helpers/HttpClient';
const httpClient = HttpClient.getInstance();
import { getWeakTicket } from 'shared/clientUtils';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { companyInfoSelectors } from '../../store/companyInfo/selectors';
import { encrypt } from 'shared/utils';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Growth/NpeFreeTextEmail';

/**
 * @param err {Error}
 * @returns {'ERROR'}
 **/
const handleGrowthFreeTextError = (err) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'error', logger },
    event: 'growthFreeText',
    action: 'sendNpeFreeTextEmail',
    activityInfo: {
      status: 'error',
    },
    error: {
      stack: err.stack,
      message: err.message,
    },
  });
  return 'ERROR';
};

export const sendEmailNotification = async ({
  state,
  path = 'npe',
  eventName,
  eventsourceObjectType,
}) => {
  try {
    const {
      auth: { ticket, realmId, isUserSignedIn, entityId, syncToken, authToken, recipientEmail },
      config: { ssrtid, portal },
      insight: { payorSegmentation, domainId, token },
      wallet: { enabledPaymentMethods },
      payment: { balanceAmount },
      sale,
      companyInfo,
    } = state;
    const merchantEmail = companyInfoSelectors.emailSelector(companyInfo);
    const isBankOnlyPM = enabledPaymentMethods?.toString() === 'bank';
    const isNOTPaid = balanceAmount === sale?.amount;
    const { payorMXSFirstName, payorMXSFirstNameProbability } = payorSegmentation;
    const data = {
      payorMXSFirstName,
      payorMXSFirstNameProbability,
      recipientEmail,
      sale,
      merchantEmail,
      domainId,
      eventName,
      eventsourceObjectType,
      isBankOnlyPM,
      isNOTPaid,
    };

    const headers = getWeakTicket({
      domainId,
      entityId,
      realmId,
      token,
      ticket,
      isUserSignedIn,
      syncToken,
      authToken,
      ssrtid,
    });
    const res = await httpClient({
      url: `/${portal}/rest/growth/oinp/${path}/${realmId}`,
      method: 'POST',
      endpoint: `/rest/growth/oinp/${path}/{realmId}`,
      headers,
      data,
    });
    const { status, data: resData } = res;
    if (status === 200 && resData) {
      return 'SENT_SUCCESSFULLY';
    }
    return 'ERROR';
  } catch (e) {
    return 'ERROR';
  }
};

export const sendNpeFreeTextEmail = async (
  state,
  encryptedFreeTextFormData,
  encryptedOriginalDataLength
) => {
  try {
    const {
      auth: { ticket, realmId, isUserSignedIn, entityId, syncToken, authToken, recipientEmail },
      config: { ssrtid, portal },
      insight: { domainId, token },
      sale,
    } = state;
    const encryptedData = encrypt(
      '$$R_domainId',
      JSON.stringify({ sale, domainId, recipientEmail })
    );
    const data = {
      aliasId: { a: encryptedFreeTextFormData, b: encryptedOriginalDataLength, c: encryptedData },
    };

    const headers = getWeakTicket({
      domainId,
      entityId,
      realmId,
      token,
      ticket,
      isUserSignedIn,
      syncToken,
      authToken,
      ssrtid,
    });
    const res = await httpClient({
      url: `/${portal}/rest/growth/oinp/npe/freetext/${realmId}`,
      method: 'POST',
      endpoint: `/rest/growth/oinp/npe/freetext/{realmId}`,
      headers,
      data,
    });
    const { status, data: resData } = res;
    if (status === 200 && resData) {
      return 'SENT_SUCCESSFULLY';
    }
    return handleGrowthFreeTextError(new Error('Failed to render and send npe free text email'));
  } catch (e) {
    return handleGrowthFreeTextError(e);
  }
};
