import { RawAxiosRequestHeaders } from 'axios';

import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { RootState } from 'store/store';

const splunkReporter = SplunkReporter.getInstance();

export const getHeadersForSSRRequest = (
  store: Pick<RootState, 'auth' | 'insight'>,
  additionalHeaders?: RawAxiosRequestHeaders
): RawAxiosRequestHeaders => {
  try {
    const {
      auth: {
        ticket,
        realmId,
        isUserSignedIn,
        entityId,
        syncToken,
        authToken,
        authLevel,
        isEntityPromoted,
      },
      insight: { domainId, token },
    } = store;
    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'intuit-authlevel': authLevel,
      'Intuit-RealmId': realmId,
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-ACSToken': token,
      sessionticket: ticket,
      isClientStateSignedIn: isUserSignedIn,
      isEntityPromoted: isEntityPromoted,
      Authorization: `Bearer ${authToken}`,
    };

    if (syncToken) {
      headers['syncToken'] = syncToken;
    }

    if (additionalHeaders) {
      return { ...headers, ...additionalHeaders };
    }
    return headers;
  } catch (e) {
    splunkReporter.contextual({
      logInfo: {
        logLevel: 'error',
        logger: 'businessLogic/utils/utils - getHeadersForSSRRequest',
      },
      event: 'pay',
      activityInfo: {
        EventName: 'SSRRequestHeadersError',
        location: 'BusinessLogic',
        error: (e as Error).message,
      },
    });
    throw e;
  }
};
