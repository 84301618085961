import SegmentIO from 'reporting/SegmentIO';
import HttpClient from 'server/helpers/HttpClient';

const httpClient = HttpClient.getInstance();
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Auth';
interface signOutParam {
  ssrtid: string;
  portal: string;
  token: string;
}
interface signInParams {
  entityId: string;
  endpoints: { signInURL: string };
}
interface signUpParams {
  entityId: string;
  endpoints: { signUpURL: string };
}
export const signInBL = ({ entityId, endpoints }: signInParams) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger },
    event: 'signIn',
    action: 'redirect',
    entityId,
    Refactored: true,
  });
  localStorage.setItem('from_sign_in', 'true');
  SegmentIO.clickSignIn('link', 'header');
  SegmentIO.transactionEngaged({
    activity_type: 'sign_in',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'transaction_flow',
    ui_object_detail: 'wallet_sign_in',
  });

  window.location.href = endpoints.signInURL;
};

export const signUpBL = ({ entityId, endpoints }: signUpParams) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger },
    event: 'signUp',
    action: 'redirect',
    Refactored: true,
    UserEntityId: entityId,
  });
  localStorage.setItem('from_sign_up', 'true');
  SegmentIO.transactionEngaged({
    activity_type: 'sign_up',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'transaction_flow',
    ui_object_detail: 'wallet_sign_up',
  });

  window.location.href = endpoints.signUpURL;
};

export const signOutBL = async ({ ssrtid, portal, token }: signOutParam) => {
  const endpoint = `/${portal}/rest/authenticate/logout`;
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger },
    event: 'signOut',
    action: 'clicked',
    Refactored: 'true',
  });

  const data = { url: endpoint, endpoint, data: { event: 'signOut', ssrtid, token } };
  await httpClient.delete(endpoint, data);

  setTimeout(() => {
    window.location.reload();
  }, 0);
};
