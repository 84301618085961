import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Toastr from 'components/Core/Toast/Toastr';
import { toastActions } from 'store/toast/slice';

export interface Props {
  currentToast: any;
  hide: Function;
  toast: object;
}

const ToastWrapper = (props: Props) => {
  if (props.currentToast) {
    return <Toastr hide={props.hide} {...props.currentToast} />;
  }
  return null;
};

function mapStateToProps(state: any) {
  return { ...state.toastr };
}

ToastWrapper.propTypes = {
  toast: PropTypes.object,
};

export default connect(mapStateToProps, { hide: toastActions.hide })(ToastWrapper);
