import { CPFeedbackFormValues } from 'components/Shared/Feedback/FeedbackForm/FeedbackForm';
import HttpClient from 'server/helpers/HttpClient';
import { insightSelectors } from 'store/insight/selectors';
import { receipts } from 'store/payorHistory/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';

const httpClient = HttpClient.getInstance();

export const handleSubmitFeedback = async (data: CPFeedbackFormValues, state: RootState) => {
  const {
    config: { ssrtid, portal },
    insight,
    auth: { authToken, realmId },
    sale,
  } = state;

  const id = saleSelectors.idSelector(sale);
  const amount = saleSelectors.amountSelector(sale);
  const txnDate = saleSelectors.txnDateSelector(sale);
  const merchantId = insightSelectors.merchantIdSelector(insight);
  const token = insightSelectors.tokenSelector(insight);
  const isPayorBusiness = insightSelectors.isPayorBusiness(state.insight);
  const isFirstTimeInCP = insightSelectors.isFirstTimeInCP(state.insight);
  const currency = saleSelectors.currencySelector(sale);
  const email = saleSelectors.toEmailsSelector(sale);
  const hasEverPaid = receipts(state).length > 0;

  const payorEmail = email ? email[0] : '';

  const feedbackData = {
    ...data,
    saleId: id,
    merchantId,
    amount,
    txnDate,
    currency,
    email: payorEmail,
    hasEverPaid,
    isPayorBusiness,
    isFirstTimeInCP,
  };

  await httpClient({
    url: `/${portal}/rest/feedback`,
    method: 'POST',
    data: feedbackData,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      ssrtid: ssrtid,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': token,
    },
    endpoint: `/${portal}/rest/feedback`,
  });
  return 'SENT_SUCCESSFULLY';
};
