export default class Config {
  constructor(data) {
    this._data = data.config;
  }

  get portal() {
    return this._data.portal;
  }

  get env() {
    return this._data.env;
  }

  get ssrtid() {
    return this._data.ssrtid;
  }

  get bioCatchSessionId() {
    return this._data.bioCatchSessionId;
  }

  get browserApiKey() {
    return this._data.credentials.browserApiKey;
  }

  get biocatchExcludedStates() {
    return this._data.biocatchExcludedStates;
  }
}
