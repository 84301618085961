import { saleSelectors } from 'store/sale/selectors';
import { TXN_MAP } from 'types/constants';

export const displayGratuityFeature = (store) => {
  const {
    sale,
    featureFlags,
    insight: { offeringId, isPartiallyPaid },
    payment: { paymentMethodType, isDateScheduled, isAutoPayOn },
  } = store;
  const saleType = saleSelectors.typeSelector(sale);
  const gratuitySalesTaxEnabled = featureFlags['gratuity-sales-tax-enabled'];
  const taxAmount = saleSelectors.taxAmountSelector(sale);
  const showTipsWithTax = gratuitySalesTaxEnabled || !taxAmount;
  const gratuityEnabled = saleSelectors.gratuityEnabledSelector(sale);
  const gratuityEnabledFF = featureFlags['gratuity-enabled'];
  const blockPartiallyPaid = isPartiallyPaid && featureFlags['gratuity-block-partially-paid-sale'];
  const scsCardPaymentsEnabled = true;
  const cardPaymentType = ['cc', 'dc', 'dc,cc'].find((p) => p === paymentMethodType);

  return (
    saleType === TXN_MAP.TYPES.INVOICE &&
    offeringId === TXN_MAP.OFFERING_TYPES.QBO &&
    !blockPartiallyPaid &&
    showTipsWithTax &&
    !isAutoPayOn &&
    !isDateScheduled &&
    gratuityEnabledFF &&
    gratuityEnabled &&
    !(cardPaymentType && !scsCardPaymentsEnabled)
  );
};

export const eligibleGratuityAmount = (store) => {
  const {
    payment: { gratuityValue = 0 },
  } = store;
  const gratuity = displayGratuityFeature(store) ? gratuityValue : 'Not Eligible';
  return gratuity;
};
