import { RootState } from 'store/store';

export type PayorHistorySelectorState = Pick<RootState, 'payorHistory'>;

export const receipts = (store: PayorHistorySelectorState) => {
  const { list = [] } = store?.payorHistory?.receipts || {};
  return list;
};
export const sales = (store: PayorHistorySelectorState) => {
  return store?.payorHistory?.sales || { open: [], paid: [] };
};
export const isPayorHistoryEnabled = (store: PayorHistorySelectorState) => {
  return store?.payorHistory?.isPayorHistoryEnabled;
};
