export default class CompanyInfo {
  constructor({ initialState, config }) {
    this.config = config;
    this._data = initialState.companyInfo;
  }

  get data() {
    return this._data;
  }

  get currency() {
    return this._data.currency;
  }

  get companyName() {
    return this._data.companyName;
  }

  get region() {
    return this._data.region;
  }
}
