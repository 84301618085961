const debug = require('debug')('ssr:helpers:Profiler');
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const WavefrontReporter = require('./wavefront/WavefrontReporter');
const splunkReporter = SplunkReporter.getInstance();
const wavefrontReporter = WavefrontReporter.getInstance();
const logger = 'reporting/Profiler';
const { filterRequestToken, filterRequestQueryParams } = require('shared/clientUtils');

const REPORT_INTERCEPTING_IGNORED_ENDPOINTS = ['rest/reporting/prometheus'];

const reportProfiling = ({ event = 'profiling', sessionId, action, start, network, logType }) => {
  const end = Date.now();
  const executionTimeMs = end - start;
  let status = 'success',
    logLevel = 'info';
  if (network?.responseCode >= 400) {
    logLevel = status = 'error';
  }
  splunkReporter[logType]({
    sessionId,
    logInfo: { logLevel, logger },
    event,
    action,
    activityInfo: {
      status,
    },
    rum: {
      executionTimeMs,
    },
    network,
  });

  if (isIgnoreWavefrontInterceptForEndpoint(network)) return;
  wavefrontReporter.trackInterceptedNetworkRequests({
    endpoint: network?.requestPathFiltered,
    responseCode: network?.responseCode,
    executionTimeMs: executionTimeMs,
    logType,
  });
};
const routeHandler = ({ req, start }) => {
  setTimeout(() => {
    let path = req.originalUrl;
    const { event, ssrtid: sessionId, method, intuit_tid: intuitTid, token, res } = req;
    const responseCode = res?.statusCode;
    const filteredPath = filterRequestQueryParams(filterRequestToken(path, token));
    const action = `${method}: ${filteredPath === '*' ? 'unmatched' : filteredPath}`;
    const network = {
      intuitTid,
      responseCode,
      method,
      requestPath: path,
      requestPathFiltered: filteredPath,
    };

    reportProfiling({ sessionId, network, event, action, start, logType: 'inbound' });
  }, 0);
};

const isIgnoreWavefrontInterceptForEndpoint = (network) => {
  return (
    true || // temporary forcing not sending the metric
    process.env.NODE_ENV === 'test' ||
    !network?.requestPathFiltered ||
    REPORT_INTERCEPTING_IGNORED_ENDPOINTS.find((endpoint) =>
      network.requestPathFiltered?.includes(endpoint)
    )
  );
};

const middlewareHandler = ({ name, start, end }) => {
  setTimeout(() => {
    const action = `middleware: ${name}`;
    reportProfiling({ action, start, end, logType: 'contextual' });
  }, 0);
};

const networkHandler = (response) => {
  setTimeout(() => {
    const {
      config: {
        rumStart: start,
        ssrtid,
        'axios-retry': axiosRetry,
        endpoint,
        method,
        url,
        headers,
        event,
      },
      status,
    } = response;

    const intuit_tid = headers && headers['intuit_tid'];
    const action = `${method?.toUpperCase()}: ${endpoint}`;
    const network = {
      intuitTid: intuit_tid,
      retryCount: axiosRetry?.retryCount,
      responseCode: status,
      requestPath: url,
      requestPathFiltered: endpoint,
      requestMethod: method,
    };
    reportProfiling({ sessionId: ssrtid, event, action, start, network, logType: 'outbound' });
  }, 0);
};

/**
 * @type {import('./Profiler').Profiler}
 */
class Profiler {
  constructor() {
    this.middleware = this.middleware.bind(this);
    this.route = this.route.bind(this);
    this.network = this.network.bind(this);
    this._instance = undefined;

    debug('Profiler created');
  }

  static getInstance() {
    if (!Profiler._instance) {
      Profiler._instance = new Profiler();
    }
    return Profiler._instance;
  }

  route(req, res, next) {
    const start = Date.now();
    // The 'finish' event will emit once the response is done sending
    res.once('finish', () => {
      // Emit an object that contains the original request and the elapsed time in MS
      routeHandler({ req, start });
    });

    next();
  }

  middleware(fn, name = undefined) {
    return (req, res, next) => {
      const start = Date.now();
      const _next = (error) => {
        middlewareHandler({
          name: name ? name : fn.name,
          start,
        });

        next(error);
      };

      fn(req, res, _next);
    };
  }

  network(response) {
    networkHandler(response);
  }

  assignProfiling(type) {
    switch (type) {
      case 'route':
        return this.route;

      case 'middleware':
        return this.middleware;

      case 'network':
        return this.network;
    }
  }
}

Profiler.TYPES = {
  ROUTE: 'route',
  MIDDLEWARE: 'middleware',
  NETWORK: 'network',
};

module.exports = Profiler;
