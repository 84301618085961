import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

export type Props = {
  bgColor?: string;
  label?: string;
  width?: number;
  strokeWidth?: number;
  bgStrokeWidth?: number;
  color?: string;
  ['aria-label']?: string;
};

const Spinner: React.FC<Props> = ({
  bgColor,
  label,
  width = 200,
  strokeWidth = 1.2,
  bgStrokeWidth = 1.2,
  color,
  'aria-label': ariaLabel,
}) => {
  const darkGreen = '#108000';
  const green = '#2CA01C';
  const gray = '#f4f5f8';
  const intl = useIntl();

  return (
    <Fragment>
      <style jsx>{`
        .w {
          display: inline-flex;
          vertical-align: middle;
          flex-direction: column;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        label {
          color: white;
          font-size: 24px;
        }

        .loader {
          position: relative;
          margin: 0 auto;
          width: ${width}px;
          &:before {
            content: '';
            display: block;
            padding-top: 100%;
          }
        }

        .circular {
          animation: rotate 4s linear infinite;
          height: 100%;
          transform-origin: center center;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        .track {
          stroke: ${bgColor || gray};
        }

        .path {
          stroke-dasharray: 1, 200;
          animation: dash 4s cubic-bezier(0.15, 0, 0, 1) infinite, color 4s linear infinite;
          stroke-linecap: round;
        }

        @keyframes rotate {
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 126, 200;
            stroke-dashoffset: 0;
          }
          100% {
            stroke-dasharray: 126, 200;
            stroke-dashoffset: -126;
          }
        }

        @keyframes color {
          0% {
            stroke: ${color || darkGreen};
          }
          50% {
            stroke: ${color || green};
          }
          100% {
            stroke: ${color || green};
          }
        }
      `}</style>

      <div
        aria-label={
          ariaLabel || intl.formatMessage({ id: 'LOADING', defaultMessage: 'Loading...' })
        }
        className="w"
      >
        <div className="showbox">
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="track"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth={bgStrokeWidth}
                strokeMiterlimit="10"
              />
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth={strokeWidth}
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </div>
        {label && <label>{label}</label>}
      </div>
    </Fragment>
  );
};

export default Spinner;
